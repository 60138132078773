/*!

=========================================================
* Black Dashboard React v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Dashboard from "views/Dashboard.js";
// import UserProfile from "views/UserProfile.js";
// import GenerateNFT from "components/Admin/GenerateNFT/index";
import Gallery from "components/Admin/Gallery/index";
import Categories from "components/Admin/Categories/index";
import Networks from "components/Admin/Networks/index";
// import Collection from "components/Admin/Collections/index";
import UserProfile from "components/Admin/UserProfile/index";
import Dashboard from "components/Admin/Dashboard/index";
import ContactUsQueries from "components/Admin/ContactUs/index";

import PricingPlans from "components/Admin/CMS/PricingPlans/PricingPlan";
import Monthly from "components/Admin/CMS/PricingPlans/Monthly";
//eslint-disable-next-line
import Yearly from "components/Admin/CMS/PricingPlans/Yearly";
import About from "components/Admin/CMS/About";
import Services from "components/Admin/CMS/Services";
import HowItWorks from "components/Admin/CMS/HowItWorks";
import Faqs from "components/Admin/CMS/Faqs/Faq";
import FaqsList from "components/Admin/CMS/Faqs/FaqsList";
// import ContactUs from "components/Admin/CMS/ContactUs";
import FeaturedProjects from "components/Admin/CMS/FeaturedProjects";

import UserDashboard from "components/User/Dashboard/";
import ManageNfts from "components/User/ManageNfts/";
// import GenerateUserNFT from "components/User/GenerateUserNFT/";
//eslint-disable-next-line
import Profile from "components/User/ProfilePage";
import MyWallet from "components/User/MyWallet";
import Security from "components/User/Security";
import MyCollections from "components/User/MyCollections";
import UserCategories from "components/User/Categories";
import Payments from "components/User/Payments";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/gallery",
    name: "View Collections",
    rtlName: "الرموز",
    icon: "tim-icons icon-image-02",
    component: Gallery,
    layout: "/admin",
  },
  // {
  //   path: "/generateNFT",
  //   name: "Generate Collection",
  //   rtlName: "طباعة",
  //   icon: "tim-icons icon-simple-add",
  //   component: GenerateNFT,
  //   layout: "/admin",
  // },
  // {
  //   path: "/collections",
  //   name: "Publish Collection",
  //   rtlName: "خرائط",
  //   icon: "tim-icons icon-bag-16",
  //   component: Collection,
  //   layout: "/admin",
  // },
  {
    path: "/categories",
    name: "Categories",
    rtlName: "خرائط",
    icon: "tim-icons icon-bullet-list-67",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/networks",
    name: "Networks",
    rtlName: "خرائط",
    icon: "tim-icons icon-molecule-40",
    component: Networks,
    layout: "/admin",
  },
  {
    path: "/cms",
    name: "CMS",
    rtlName: "خرائط",
    icon: "tim-icons icon-laptop",
    layout: "/admin",
  },
  {
    path: "/query",
    name: "Contact US Queries",
    rtlName: "خرائط",
    icon: "tim-icons icon-bell-55",
    component: ContactUsQueries,
    layout: "/admin",
  },
  {
    path: "/site",
    name: "Site Settings",
    rtlName: "ملف تعريفي للمستخدم",
    icon: "tim-icons icon-settings-gear-63",
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "PROFILE",
    rtlName: "خرائط",
    icon: "tim-icons icon-single-02",
    layout: "/admin",
  },
];

export const AdminSideBarCMSSubMenu = [
  {
    path: "/banner",
    name: "Banner",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-bold",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/plans",
    name: "PRICING PLANS",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-credit-card",
    layout: "/admin",
  },
  {
    path: "/aboutmulti",
    name: "About Us",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-app",
    component: About,
    layout: "/admin",
  },
  {
    path: "/services",
    name: "Services",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-settings",
    component: Services,
    layout: "/admin",
  },
  {
    path: "/howItWorks",
    name: "How It Works",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-support-17",
    component: HowItWorks,
    layout: "/admin",
  },
  {
    path: "/faqs",
    name: "FAQ",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-paper",
    layout: "/admin",
  },
  // {
  //   path: "/contactus",
  //   name: "Contact Us",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-chat-33",
  //   component: ContactUs,
  //   layout: "/admin",
  // },
  {
    path: "/featuredProjects",
    name: "Featured Projects",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-puzzle-10",
    component: FeaturedProjects,
    layout: "/admin",
  },
];

export const AdminSideBarProfileSubMenu = [
  {
    path: "/profile",
    name: "User Profile",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-single-02",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/password",
    name: "Password",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-lock-circle",
    layout: "/admin",
  },
];

export const AdminSideBarCMSPricingSubMenu = [
  {
    path: "/plans",
    name: "Pricing Plans",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-credit-card",
    component: PricingPlans,
    layout: "/admin",
  },
  {
    // path: "/monthly",
    path: "/plansListing",
    name: "Plans",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-credit-card",
    component: Monthly,
    layout: "/admin",
  },
  // {
  //   path: "/yearly",
  //   name: "Yearly Plan",
  //   rtlName: "لوحة القيادة",
  //   icon: "tim-icons icon-credit-card",
  //   component: Yearly,
  //   layout: "/admin",
  // },
];

export const AdminSideBarCMSFaqsSubMenu = [
  {
    path: "/faqs",
    name: "FAQ",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-paper",
    component: Faqs,
    layout: "/admin",
  },
  {
    path: "/faqsList",
    name: "FAQ List",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-bullet-list-67",
    component: FaqsList,
    layout: "/admin",
  },
];

var userRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
      >
        <path
          id="Path_2058"
          data-name="Path 2058"
          d="M7,18.5H2a2,2,0,0,1-2-2v-4a4,4,0,0,1,4-4H8a2,2,0,0,1,2,2v5a3,3,0,0,1-3,3m16-2v-4a4,4,0,0,0-4-4H15a2,2,0,0,0-2,2v5a3,3,0,0,0,3,3h5a2,2,0,0,0,2-2m-13,13v-5a3,3,0,0,0-3-3H2a2,2,0,0,0-2,2v4a4,4,0,0,0,4,4H8a2,2,0,0,0,2-2m13-2v-4a2,2,0,0,0-2-2H16a3,3,0,0,0-3,3v5a2,2,0,0,0,2,2h4a4,4,0,0,0,4-4"
          transform="translate(0 -8.5)"
          fill="#7E86A8"
        />
      </svg>
    ),
    component: UserDashboard,
    layout: "/user",
  },
  // {
  //   path: "/manageNfts",
  //   name: "Manage NFT's",
  //   svg: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="23"
  //       height="23"
  //       viewBox="0 0 23 23"
  //     >
  //       <path
  //         id="Path_2059"
  //         data-name="Path 2059"
  //         d="M55.2,20A11.5,11.5,0,1,0,43.7,31.5c1.87,0,4.21-.22,5.21-1.24v-.01c1-1.32-3.06-2.92-.87-5.1s6.08-.26,7.16-5.15m-19.57-2.45a1.85,1.85,0,1,1,1.85,1.85,1.852,1.852,0,0,1-1.85-1.85m3.05,8.15a1.85,1.85,0,1,1,1.85-1.85,1.852,1.852,0,0,1-1.85,1.85m4.41-10.83a1.845,1.845,0,1,1,1.85-1.85,1.846,1.846,0,0,1-1.85,1.85m4.15,1.85a1.85,1.85,0,1,1,1.85,1.85,1.852,1.852,0,0,1-1.85-1.85"
  //         transform="translate(-32.201 -8.5)"
  //         fill="#7e86a8"
  //       />
  //     </svg>
  //   ),
  //   component: ManageNfts,
  //   layout: "/user",
  // },





  {
    path: "/nftlisting",
    name: "Manage NFTs",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
      >
        <path
          id="Path_2059"
          data-name="Path 2059"
          d="M55.2,20A11.5,11.5,0,1,0,43.7,31.5c1.87,0,4.21-.22,5.21-1.24v-.01c1-1.32-3.06-2.92-.87-5.1s6.08-.26,7.16-5.15m-19.57-2.45a1.85,1.85,0,1,1,1.85,1.85,1.852,1.852,0,0,1-1.85-1.85m3.05,8.15a1.85,1.85,0,1,1,1.85-1.85,1.852,1.852,0,0,1-1.85,1.85m4.41-10.83a1.845,1.845,0,1,1,1.85-1.85,1.846,1.846,0,0,1-1.85,1.85m4.15,1.85a1.85,1.85,0,1,1,1.85,1.85,1.852,1.852,0,0,1-1.85-1.85"
          transform="translate(-32.201 -8.5)"
          fill="#7e86a8"
        />
      </svg>
    ),
    component: ManageNfts,
    layout: "/user",
  },

  // {
  //   path: "/generateUserNFT",
  //   name: "Generate NFTs",
  //   svg: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="23"
  //       height="23"
  //       viewBox="0 0 23 23"
  //     >
  //       <path
  //         id="Path_2059"
  //         data-name="Path 2059"
  //         d="M55.2,20A11.5,11.5,0,1,0,43.7,31.5c1.87,0,4.21-.22,5.21-1.24v-.01c1-1.32-3.06-2.92-.87-5.1s6.08-.26,7.16-5.15m-19.57-2.45a1.85,1.85,0,1,1,1.85,1.85,1.852,1.852,0,0,1-1.85-1.85m3.05,8.15a1.85,1.85,0,1,1,1.85-1.85,1.852,1.852,0,0,1-1.85,1.85m4.41-10.83a1.845,1.845,0,1,1,1.85-1.85,1.846,1.846,0,0,1-1.85,1.85m4.15,1.85a1.85,1.85,0,1,1,1.85,1.85,1.852,1.852,0,0,1-1.85-1.85"
  //         transform="translate(-32.201 -8.5)"
  //         fill="#7e86a8"
  //       />
  //     </svg>
  //   ),
  //   component: GenerateUserNFT,
  //   layout: "/user",
  // },
  // {
  //   path: "/profile",
  //   name: "My Profile",
  //   svg: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="21"
  //       height="23"
  //       viewBox="0 0 21 23"
  //     >
  //       <path
  //         id="Icon_awesome-users"
  //         data-name="Icon awesome-users"
  //         d="M19.5,13.75A5.943,5.943,0,0,0,25.625,8,5.943,5.943,0,0,0,19.5,2.25,5.943,5.943,0,0,0,13.375,8,5.943,5.943,0,0,0,19.5,13.75Zm4.2,1.643h-.454a8.953,8.953,0,0,1-7.492,0H15.3A6.118,6.118,0,0,0,9,21.307v1.479a2.549,2.549,0,0,0,2.625,2.464h15.75A2.549,2.549,0,0,0,30,22.786V21.307A6.118,6.118,0,0,0,23.7,15.393Z"
  //         transform="translate(-9 -2.25)"
  //         fill="#7e86a8"
  //       />
  //     </svg>
  //   ),
  //   component: Profile,
  //   layout: "/user",
  // },
  {
    path: "/mycollections",
    name: "My Collections",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="23"
        viewBox="0 0 23 23"
      >
        <path
          id="Path_2060"
          data-name="Path 2060"
          d="M81.4,12.62h-4.33a5.651,5.651,0,0,0-5.43-4.12h-1.61a5.625,5.625,0,0,0-5.42,4.12,5.419,5.419,0,0,0-.21,1.52V25.5a5.992,5.992,0,0,0,6,6h11a6,6,0,0,0,6-6V18.62a6,6,0,0,0-6-6M84.622,20a1,1,0,0,1-2,0V18.45a1,1,0,0,0-1-1H70.292a1,1,0,0,0-1,1V20a1,1,0,0,1-2,0V18.45a3,3,0,0,1,3-3h11.33a3,3,0,0,1,3,3Z"
          transform="translate(-64.402 -8.5)"
          fill="#7e86a8"
        />
      </svg>
    ),
    component: MyCollections,
    layout: "/user",
  },
  {
    path: "/categories",
    name: "Categories",
    svg: (
      <svg 
      id="Layer_1" 
      data-name="Layer 1" 
      xmlns="http://www.w3.org/2000/svg" 
      width="23"
      viewBox="0 0 122.88 121.78">
        <path fill="#7e86a8" d="M6.91,0H49.79a6.86,6.86,0,0,1,5.34,2.55A6.37,6.37,0,0,1,56.29,4.6a6.61,6.61,0,0,1,.41,2.31V49.6a7,7,0,0,1-2.13,5,7,7,0,0,1-4.78,1.94H6.91A6.73,6.73,0,0,1,4.37,56,6.78,6.78,0,0,1,.58,52.33,6.62,6.62,0,0,1,0,49.6V6.91A7,7,0,0,1,1.71,2.37L2,2.09A7.1,7.1,0,0,1,4.06.63,7,7,0,0,1,6.91,0ZM73.09,65.26H116a6.87,6.87,0,0,1,3,.67,7,7,0,0,1,2.38,1.88,6.77,6.77,0,0,1,1.16,2,6.66,6.66,0,0,1,.41,2.32v42.69a6.82,6.82,0,0,1-.56,2.69,7,7,0,0,1-1.57,2.28,6.83,6.83,0,0,1-2.22,1.43,6.72,6.72,0,0,1-2.56.51H73.09a7,7,0,0,1-2.55-.5,6.85,6.85,0,0,1-2.2-1.41,7.09,7.09,0,0,1-1.59-2.28,6.76,6.76,0,0,1-.57-2.72V72.18a6.67,6.67,0,0,1,.45-2.43,7,7,0,0,1,1.25-2.12l.28-.28a7,7,0,0,1,2.07-1.45,6.83,6.83,0,0,1,2.86-.64ZM116,72.15H73.09l0,0v42.72c3.68,0,42.89,0,42.93,0,0-3.6,0-42.7,0-42.72ZM6.91,65.26H49.79a6.86,6.86,0,0,1,5.34,2.55,6.37,6.37,0,0,1,1.16,2,6.66,6.66,0,0,1,.41,2.32v42.69a6.82,6.82,0,0,1-.56,2.69,6.69,6.69,0,0,1-1.57,2.28,7,7,0,0,1-4.78,1.94H6.91a6.92,6.92,0,0,1-2.54-.5,6.79,6.79,0,0,1-2.21-1.41,6.91,6.91,0,0,1-1.58-2.28A6.61,6.61,0,0,1,0,114.87V72.18a6.89,6.89,0,0,1,.45-2.43,7.05,7.05,0,0,1,1.26-2.12L2,67.35A6.89,6.89,0,0,1,4.06,65.9a6.82,6.82,0,0,1,2.85-.64Zm42.88,6.89H6.91l0,0,0,42.72c3.67,0,42.89,0,42.92,0,0-3.6,0-42.7,0-42.72ZM73.09,0H116a6.87,6.87,0,0,1,3,.67,7,7,0,0,1,2.38,1.88,6.77,6.77,0,0,1,1.16,2.05,6.61,6.61,0,0,1,.41,2.31V49.6a6.83,6.83,0,0,1-.56,2.7,7.09,7.09,0,0,1-1.57,2.28A7,7,0,0,1,118.53,56a6.91,6.91,0,0,1-2.56.51H73.09A6.78,6.78,0,0,1,70.54,56a7,7,0,0,1-2.2-1.4,7.09,7.09,0,0,1-1.59-2.28,6.78,6.78,0,0,1-.57-2.73V6.91a6.66,6.66,0,0,1,.45-2.42,6.91,6.91,0,0,1,1.25-2.12l.28-.28A7.19,7.19,0,0,1,70.23.63,7,7,0,0,1,73.09,0ZM116,6.89H73.09l0,0V49.63c3.68,0,42.89,0,42.93,0,0-3.6,0-42.71,0-42.72Zm-66.18,0H6.91l0,0,0,42.72c3.67,0,42.89,0,42.92,0,0-3.6,0-42.71,0-42.72Z"/></svg>
    ),
    component: UserCategories,
    layout: "/user",
  },
  {
    path: "/mywallet",
    name: "My Wallet",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="21"
        viewBox="0 0 23 21"
      >
        <path
          id="Path_2061"
          data-name="Path 2061"
          d="M113.864,24.5h5.74a6,6,0,0,1-6,6h-11a6,6,0,0,1-6-6v-9a6,6,0,0,1,6-6h11a6,6,0,0,1,6,6h-5.74a4.5,4.5,0,1,0,0,9m5.74-7v5h-5.22a2.5,2.5,0,1,1,0-5Zm-4.11,2.5a1,1,0,1,0-1,1,1,1,0,0,0,1-1"
          transform="translate(-96.604 -9.5)"
          fill="#7e86a8"
        />
      </svg>
    ),
    component: MyWallet,
    layout: "/user",
  },
  {
    path: "/payments",
    name: "Payments",
    svg: (
      <svg enable-background="new 0 0 48 48" height="48px" id="Layer_1" version="1.1" viewBox="0 0 48 48" width="23px" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M47,40L47,40c0,2.762-2.238,5-5,5l0,0H6l0,0c-2.762,0-5-2.238-5-5V11  c0-2.209,1.791-4,4-4l0,0h20.171l8.099-2.934c0.513-0.187,1.081,0.078,1.268,0.589L35.391,7H39c2.209,0,4,1.791,4,4v2l0,0  c2.209,0,4,1.791,4,4V40z M5,9L5,9c-1.104,0-2,0.896-2,2s0.896,2,2,2h3.445l0,0h0.189c0.013-0.005,0.021-0.016,0.034-0.021L19.65,9  H5z M34.078,9.181l-1.062-2.924l-0.001,0v0L30.964,7h0.003l-5.514,2h-0.01l-11.039,4h21.062L34.078,9.181z M41,11  c0-1.104-0.896-2-2-2h-2.883l1.454,4H41l0,0V11z M43,15H5l0,0c-0.732,0-1.41-0.211-2-0.555V40c0,1.657,1.344,3,3,3h36  c1.657,0,3-1.343,3-3v-7h-4c-2.209,0-4-1.791-4-4s1.791-4,4-4h4v-8C45,15.896,44.104,15,43,15z M45,31v-4h-4c-1.104,0-2,0.896-2,2  s0.896,2,2,2H45z M41,28h2v2h-2V28z" fill-rule="evenodd" fill="#7e86a8" /></svg>
    ),
    component: Payments,
    layout: "/user",
  },
  {
    path: "/profile",
    name: "Profile",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="23"
        viewBox="0 0 21 23"
      >
        <path
          id="Icon_awesome-users"
          data-name="Icon awesome-users"
          d="M19.5,13.75A5.943,5.943,0,0,0,25.625,8,5.943,5.943,0,0,0,19.5,2.25,5.943,5.943,0,0,0,13.375,8,5.943,5.943,0,0,0,19.5,13.75Zm4.2,1.643h-.454a8.953,8.953,0,0,1-7.492,0H15.3A6.118,6.118,0,0,0,9,21.307v1.479a2.549,2.549,0,0,0,2.625,2.464h15.75A2.549,2.549,0,0,0,30,22.786V21.307A6.118,6.118,0,0,0,23.7,15.393Z"
          transform="translate(-9 -2.25)"
          fill="#7e86a8"
        />
      </svg>
    ),
    component: Security,
    layout: "/user",
  },


  // {
  //   path: "/network",
  //   name: "Network",
  //   svg: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="21"
  //       height="23"
  //       viewBox="0 0 21 23"
  //     >
  //       <path
  //         id="Icon_ionic-md-git-network"
  //         data-name="Icon ionic-md-git-network"
  //         d="M25.5,5.536a3.507,3.507,0,0,0-7,0A3.262,3.262,0,0,0,20.25,8.38v2.711L15,13.555,9.75,11.091V8.38A3.262,3.262,0,0,0,11.5,5.536,3.4,3.4,0,0,0,8,2.25,3.4,3.4,0,0,0,4.5,5.536,3.262,3.262,0,0,0,6.25,8.38v4.744l7,3.286V19.12a3.262,3.262,0,0,0-1.75,2.844A3.4,3.4,0,0,0,15,25.25a3.4,3.4,0,0,0,3.5-3.286,3.262,3.262,0,0,0-1.75-2.844V16.409l7-3.286V8.38A3.262,3.262,0,0,0,25.5,5.536ZM8,3.482a2.123,2.123,0,0,1,2.188,2.054A2.123,2.123,0,0,1,8,7.589,2.123,2.123,0,0,1,5.813,5.536,2.123,2.123,0,0,1,8,3.482Zm7,20.536a2.058,2.058,0,1,1,2.188-2.054A2.123,2.123,0,0,1,15,24.018ZM22,7.589a2.123,2.123,0,0,1-2.187-2.054,2.192,2.192,0,0,1,4.375,0A2.123,2.123,0,0,1,22,7.589Z"
  //         transform="translate(-4.5 -2.25)"
  //         fill="#7e86a8"
  //       />
  //     </svg>
  //   ),
  //   // component: Security,
  //   layout: "/user",
  // },
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   svg: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="20.998"
  //       height="23"
  //       viewBox="0 0 20.998 23"
  //     >
  //       <path
  //         id="Path_2062"
  //         data-name="Path 2062"
  //         d="M149.756,21.93l-.34-.21a2.029,2.029,0,0,1,0-3.44l.34-.21a2.228,2.228,0,0,0,.75-3.01l-1.1-1.89a2.114,2.114,0,0,0-2.94-.76,1.856,1.856,0,0,1-.99.29,1.987,1.987,0,0,1-1.96-2.01,2.166,2.166,0,0,0-2.14-2.19h-2.14a2.1,2.1,0,0,0-1.52.64,2.2,2.2,0,0,0-.63,1.55,1.979,1.979,0,0,1-1.95,2.01,1.889,1.889,0,0,1-1-.29,2.11,2.11,0,0,0-2.93.77l-1.1,1.89a2.218,2.218,0,0,0,.75,3l.34.21a2.029,2.029,0,0,1,0,3.44l-.34.21a2.218,2.218,0,0,0-.75,3l1.1,1.89a2.137,2.137,0,0,0,1.85,1.08,2.041,2.041,0,0,0,1.08-.31,1.889,1.889,0,0,1,1-.29,1.979,1.979,0,0,1,1.95,2.01,2.168,2.168,0,0,0,2.15,2.19h2.14a2.092,2.092,0,0,0,1.51-.64,2.2,2.2,0,0,0,.63-1.55,1.987,1.987,0,0,1,1.96-2.01,1.856,1.856,0,0,1,.99.29,2.118,2.118,0,0,0,2.94-.76l1.1-1.89a2.228,2.228,0,0,0-.75-3.01m-9.45,1.61a3.54,3.54,0,1,1,3.54-3.54,3.547,3.547,0,0,1-3.54,3.54"
  //         transform="translate(-129.807 -8.5)"
  //         fill="#7e86a8"
  //       />
  //     </svg>
  //   ),
  //   // component: Security,
  //   layout: "/user",
  // },
];
export { routes, userRoutes };
