import { lazy, Suspense, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
// import { ClimbingBoxLoader } from "react-spinners";
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import '../../app.css';
import UserLayout from "layouts/UserLayout";
import { getAuthHeader } from "../../config/utils";
import axios from "axios";
import PrivacyPolicy from "../../components/GeneralPage/PrivacyPolicy";
import FullPageLoader from "ReuseableComps/FullPageLoader/full-page-loader";

const Homepage = lazy(() => import("../../pages/auth/homepage"));
const Dashboard = lazy(() => import("../../pages/user/dashboard"));
const ManageNfts = lazy(() => import("../../pages/user/manageNfts"));
const PublishCollection = lazy(() => import("../../pages/user/publishCollection"));
const GenerateUserNFT = lazy(() => import("../../pages/user/generateUserNft"));
const Security = lazy(() => import("../../pages/user/security"));
const MyCollections = lazy(() => import("../../pages/user/myCollections"));
const MyWallet = lazy(() => import("../../pages/user/myWallet"));
const DummyForm = lazy(() => import("../../pages/user/dummyForm"));
const NFTCollection = lazy(() => import("../../pages/user/NFTCollection"));
const Categories = lazy(() => import("../../pages/user/categories"));
const Payments = lazy(() => import("../../pages/user/payments"));
const Checkout = lazy(() => import("../../pages/user/checkout"));
const CheckoutPage = lazy(() => import("../../components/Checkout"));

const TermCondition = lazy(() => import("../../components/GeneralPage/TermsAndConditions"));

const UserRoutes = (props) => {

    const history = useHistory()
    const [userWithPlan, setUserWithPlan] = useState('')
    const [subFlag, setSubFlag] = useState(false)

    const location = useLocation();
    const pageVariants = {
        initial: {
            opacity: 0,
        },
        in: {
            opacity: 1,
        },
        out: {
            opacity: 0,
        },
    };
    const pageTransition = {
        type: "tween",
        ease: "linear",
        duration: 0.3,
    };


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'))
        let userId = user.id ? user.id : user._id
        const userWithPlan = async () => {

            if (userId) {
                const headers = await getAuthHeader();
                await axios.post(process.env.REACT_APP_BASE_URL + "/admin/plans/user-paln", { userId: userId }, headers)
                    .then((response) => {
                        if (response.data && response.data.code === 200) {


                            setUserWithPlan(response?.data?.data[0])

                            setTimeout(() => {
                                setSubFlag(true)
                            }, 1000);
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            }

        }
        userWithPlan()
    }, [])


    const SuspenseLoading = () => {
        const [show, setShow] = useState(false);
        useEffect(() => {
            let timeout = setTimeout(() => setShow(true), 300);
            return () => {
                clearTimeout(timeout);
            };
        }, []);
        return (
            <>
                <AnimatePresence>
                    {show && (
                        <motion.div
                            key="loading"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.4 }}
                        >
                            <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                                <div className="d-flex align-items-center flex-column px-4">
                                    <FullPageLoader />
                                </div>
                                <div className="text-muted font-size-xl text-center pt-3">
                                    Please wait
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </>
        );
    };


    const calculatePackageRemainingDays = (dateEnd) => {

        let date_1 = new Date(new Date(dateEnd * 1000));
        let date_2 = new Date();
        let difference = date_1.getTime() - date_2.getTime();

        return difference;
    }

    return (
        <>
            <AnimatePresence>
                <Suspense fallback={<SuspenseLoading />}>
                    <Switch>
                        <Redirect exact from="/" to="/home" />
                        <Route
                            path={[
                                "/home", "/checkout", "/CheckoutPage/:packageid/:cancel?", "/privacy-policy", "/term-and-condition"
                            ]}
                        >
                            <Switch location={location} key={location.pathname}>
                                <motion.div
                                    initial="initial"
                                    animate="in"
                                    exit="out"
                                    variants={pageVariants}
                                    transition={pageTransition}
                                    style={{ height: "100%" }}
                                >
                                    <Route path="/home" component={() => <Homepage plan={userWithPlan} />} />
                                    <Route path="/checkout" component={Checkout} />
                                    <Route path="/CheckoutPage/:packageid/:cancel?" component={CheckoutPage} />
                                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                                    <Route path="/term-and-condition" component={TermCondition} />
                                </motion.div>
                            </Switch>

                        </Route>
                        <Route path={["/dashboard", "/manageNfts", "/profile", "/mycollections", "/mywallet", "/dummyForm", "/generateUserNFT", '/publishedNfts/:NftId', '/categories', '/payments', '/nftlisting', "/privacy-policy", "/term-and-condition"]}>
                            <div className={!userWithPlan?.packageId ? 'blur-wrapper' : ''}>
                                {
                                    !userWithPlan?.packageId && subFlag ?

                                        <div className="blur-content-area d-flex align-items-center justify-content-center">
                                            <div className="blur-content text-center">
                                                <h3>You don't have any subscription yet</h3>
                                                <button onClick={() => history.push('/home#pricing')}>Subscribe</button>
                                            </div>
                                        </div>

                                        : subFlag && calculatePackageRemainingDays(userWithPlan?.subscriptionObj[0]?.endDate) < 1 ?

                                            <div className="blur-content-area d-flex align-items-center justify-content-center">
                                                <div className="blur-content text-center">
                                                    <h3>Your package has been expired. Pay your invoice or buy package to proceed further!</h3>
                                                    <button onClick={() => history.push('/home#pricing')}>Subscribe</button>
                                                </div>
                                            </div>
                                            : ''
                                }

                                <UserLayout>
                                    <Switch location={location} key={location.pathname}>
                                        <motion.div
                                            initial="initial"
                                            animate="in"
                                            exit="out"
                                            variants={pageVariants}
                                            transition={pageTransition}
                                            style={{ height: "100%" }}
                                        >
                                            <Route path="/dashboard" component={Dashboard} />
                                            <Route path="/manageNfts" component={ManageNfts} />
                                            <Route path="/generateUserNFT" component={GenerateUserNFT} />
                                            <Route path="/publishedNfts/:NftId" component={PublishCollection} />
                                            <Route path="/profile" component={Security} />
                                            <Route path="/mycollections" component={MyCollections} />
                                            <Route path="/categories" component={Categories} />
                                            <Route path="/mywallet" component={MyWallet} />
                                            <Route path="/dummyForm" component={DummyForm} />
                                            <Route path="/nftlisting" component={NFTCollection} />
                                            <Route path="/payments" component={Payments} />
                                        </motion.div>
                                    </Switch>
                                </UserLayout>
                            </div>
                        </Route>
                        <Route render={() => <Redirect to="/dashboard" />} />
                    </Switch>
                </Suspense>
            </AnimatePresence>{" "}
        </>
    );
};
export default UserRoutes;
