/* eslint-disable */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Switch from "react-ios-switch";
export default function CustomizedSwitches(props) {
  const { status, updateStatus, id, heading } = props;
  const [checkedd, setChecked] = useState(false);

  const SweetAlert = () => {
    Swal.fire({
      title: "Error!",
      text: "Do you want to continue",
      icon: "error",
      confirmButtonText: <div style={{ margin: "10px" }}>HEY BUTTON</div>,
    });
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-simple text-success",
      cancelButton: "btn btn-simple text-danger",
    },
    buttonsStyling: false,
  });

  const handleChange = (event) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You are going to chnage a user status!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        background: "#27293d",
      })
      .then((result) => {
        if (result.isConfirmed) {
          setChecked(!checkedd);
          updateStatus({ id: id, status: !checkedd });
        }
      })
  };
  useEffect(() => {
    setChecked(status);
  }, [status]);
  return (
    <div className="mt-lg-n2">
      <Switch
        checked={heading === 'Users List' ? !checkedd : checkedd}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </div>
  );
}
