/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import { Nav, Tab, Row } from "react-bootstrap";
import MyNftsContent from "./MyNftsContent";
import { ToasterContext } from "app";
import { GetCategories } from "services/admin/Categories";
import { GetArtByUser } from "services/admin/Art";
import { Link } from "react-router-dom";
import { getAuthHeader } from "config/utils";
import axios from "axios";
import FullPageLoader from "ReuseableComps/FullPageLoader/full-page-loader";


const MyNfts = (props) => {
    const [arts, setArts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [loadingCategory, setLoadingcategory] = useState(false);
    const [loadingArt, setLoadingArt] = useState(false);
    const [user, setUser] = useState('');
    const [userWithPlan, setUserWithPlan] = useState('');
    const [tostify, setTostify] = useContext(ToasterContext);

    const GetArtService = () => {
        setLoadingArt(true);
        let userrrr = JSON.parse(localStorage.getItem('user'));
        let userId = userrrr.id ? userrrr.id : userrrr._id
        GetArtByUser(userId).then((res) => {
            if (res.status === 200) {
                setArts(res.data);
                setLoadingArt(false);
            } else {
                setTostify({
                    ...tostify,
                    visible: true,
                    key: Math.random().toString(36).substring(7),
                    type: res && res.status === 200 ? "success" : "error",
                    message: res.message,
                });
                setLoadingArt(false);
            }
        });
    };
    const GetCategoriesService = () => {
        setLoadingcategory(true);
        let user = JSON.parse(localStorage.getItem('user'));
        let userId = user.id ? user.id : user._id
        GetCategories({type: "admin", userId: userId}).then((res) => {
            if (res.status === 200) {
                setCategories(res.data);
                setLoadingcategory(false);
            } else {
                setTostify({
                    ...tostify,
                    visible: true,
                    key: Math.random().toString(36).substring(7),
                    type: res && res.status === 200 ? "success" : "error",
                    message: res.message,
                });
                setLoadingcategory(false);
            }
        });
    };

    useEffect(() => {
        GetArtService()
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) setUser(user)
        GetCategoriesService()
    }, [])

    useEffect(() => {
        const userWithPlan = async () => {
            let userId = user.id ? user.id : user._id
            const headers = await getAuthHeader();
            await axios.post(process.env.REACT_APP_BASE_URL + "/admin/plans/user-paln", { userId: userId }, headers)
                .then((response) => {
                    if (response.data && response.data.code === 200) {
                        localStorage.setItem('userPlan', JSON.stringify(response?.data?.data[0].subscriptionObj))
                        setUserWithPlan(response?.data?.data[0])
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        }
        userWithPlan()
    }, [user])


    return (
        <>
            {
                userWithPlan && Object.keys(userWithPlan).length > 0 ? <div className="created-nft-main-section-headings d-flex align-items-center justify-content-between">
                    <h2>{`My Deployed Collections`}</h2>
                </div>
                    : ''
            }

            <div className="created-nft-tabs">
                {loadingCategory ?
                    <div className="d-flex align-items-center flex-column justify-content-center text-center py-3">
                        <div className="d-flex align-items-center flex-column px-4">
                            <FullPageLoader />
                        </div>
                        <div className="text-muted font-size-xl text-center pt-3">
                            Please wait
                        </div>
                    </div>
                    :
                    <Tab.Container id="" defaultActiveKey={0}>
                        <Nav variant="pills" className="nav-tabs">
                            {categories?.map((cat, ind) => (
                                <Nav.Item>
                                    <Nav.Link eventKey={ind}>{cat.category}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content>
                            {categories?.map((cat, ind) => {
                                let findArt = arts.find((art, ind) => art.category === cat._id);
                                if (findArt) {
                                    return <Tab.Pane eventKey={ind}>
                                        <Row>
                                            {
                                                arts?.map((art, ind) => (
                                                    art.category === cat._id ?
                                                        <MyNftsContent artObj={art} h_one="Geometry" h_two="100 Likes" h_three="Geo Runners #100" h_four="Current bid" h_five="10.01" h_six="ETH" image="cnt-one"
                                                        /> : ''
                                                ))
                                            }
                                        </Row>
                                    </Tab.Pane>
                                }
                                else {
                                    return <Tab.Pane eventKey={ind}>
                                        <div className="content-wrapper">
                                            <div className="checkout-content my-nfts-content text-center">
                                                <p className="mb-4">No NFT found at the moment.</p>
                                                <Link to='/generateUserNFT'>Generate NFT</Link>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                }
                            })}
                        </Tab.Content>
                    </Tab.Container>
                }
            </div>
        </>
    )

}

export default MyNfts;