/*eslint-disable*/
import useStyles from "../../assets/CustomeMaterialStyleSheet";
import { CardHeader, Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Formik } from "formik";
import FormikFieldDouble from "../../ReuseableComps/DoubleLineFormikForm";

const Body = (props) => {
  const {
    actionState,
    handleClose,
    updatedRow,
    setUpdatedRow,
    initialValues,
    setInitialValues, 
    schema,
    formFields,
    heading,
    isModal,
    roles,
    tostify,
    setTostify,
    add,
    update,
    color,
  } = props;
  const classes = useStyles();
  const SubmitForm = (data, actions) => {
    if(heading === 'Plans List'){
      if (actionState === "edit") {
        let upppp = {...data, price: data.price*100}
        update(upppp, actions);
      } else {
        let upppp = {...data, price: data.price*100}
        add(upppp, actions);
      }
    }else{
      if (actionState === "edit") {
        update(data, actions);
      } else {
        add(data, actions);
      }
    }
  };
  return (
    <>
      <Formik
        initialValues={
          actionState === "edit" ? setInitialValues(updatedRow) : initialValues
        }
        onSubmit={(values, actions) => {
          SubmitForm(values, actions);
        }}
        validationSchema={schema}
      >
        {(props) => {
          return (
            <>
              <div className={`all-modal ${heading === 'Plans List' ? 'planListModal' : ""}`}>
                <PerfectScrollbar
                  options={{
                    wheelPropagation: true,
                  }}
                >
                  <div className="card-user card">
                    <div className="card-body">
                      <div className="author">
                        <CardHeader className="d-flex justify-content-between p-2">
                          <h4 className="content-heading text-white ">
                            {actionState === "edit" && isModal
                              ? "Edit " + heading
                              : actionState === "edit"
                              ? heading
                              : null}
                            {actionState !== "edit" && isModal
                              ? "Add " + heading
                              : actionState !== "edit"
                              ? heading
                              : null}
                          </h4>
                          <Button
                            className={color === "btn-fill btn-primary btn theme"}
                            type="link"
                            size="sm"
                            onClick={() => {
                              handleClose();
                              setUpdatedRow({});
                            }}
                          >
                            <i className={"tim-icons icon-simple-remove"} />
                          </Button>
                        </CardHeader>
                      </div>
                      <FormikFieldDouble
                        color={color}
                        formFields={formFields}
                        {...props}
                        heading={heading}
                        tostify={tostify}
                        setTostify={setTostify}
                        actionState={actionState}
                      />
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};
export default Body;
