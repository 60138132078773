/* eslint-disable */
import { useEffect, useState, useCallback, useContext } from "react";
import {
  ProfileFormFields,
  ProfileInitialValues,
  ProfileSchema,
  getInitialValues,
} from "./helpers";
import {
  GetAdminInfo,
  UpdateAdminProfile,
} from "../../../services/admin/Admin";
import GenericFormikForm from "ReuseableComps/GenericForms";
import UploadIcon from "./UploadIcon";
import { ToasterContext, SideBarBgContext } from "../../../app";
import { ImageContext } from "../../../app";
import { serverUrl } from "../../../config/config";
import defaultProfile from '../../../assets/images/defaultProfile.jpg'
import FullPageLoader from "ReuseableComps/FullPageLoader/full-page-loader";
import { toast } from "react-toastify";

function UserProfile() {
  const [data, setData] = useState(null);
  const [profileImageData, setProfileImageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [color, setColor] = useContext(SideBarBgContext);
  const [profileImage, setProfileImage] = useContext(ImageContext);
  const [upload, setUpload] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const getAdminData = (email) => {
    setLoading(true);
    GetAdminInfo(email).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setProfileImageData(res.data.profileImage)
        setUpload(res.data.profileImage);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  useEffect(() => {
    getAdminData(user.email);
  }, []);

  const UpdateProfile = useCallback((data, actions) => {

    UpdateAdminProfile(data).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("role", res.data.role);
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  }, []);

  const UpdateProfileImage = () => {
    if(upload === ''){
			toast('Please Upload Profile Picture', {
				position: "top-right",
				autoClose: 5000,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				type: "warning",
			})
		}else{
      let userId = user.id ? user.id : user._id
      UpdateAdminProfile({id: userId, 'profileImage': upload}).then((res) => {
        if (res.status === 200) {
          setProfileImage(serverUrl + res.data.profileImage);
        }
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      });
    }
  }
  return (
    <>
      <div className="content">
        {loading ? <FullPageLoader /> : (
          <div className="row">
            <div className="col-md-8">
              <GenericFormikForm
                key={"profile-form" + Math.random().toString(36).substring(7)}
                heading={"Profile"}
                data={data}
                initialValues={ProfileInitialValues}
                getValues={getInitialValues}
                schema={ProfileSchema}
                formFields={ProfileFormFields}
                update={UpdateProfile}
              />
            </div>
            <div className="col-md-4">
              <div className="card-user card">
                <div className="card-body">
                  <p className="card-text"></p>
                  <div className="author">
                      <img
                        alt="..."
                        className="avatar mb-4"
                        src={
                          upload?.preview ? upload.preview : upload?.includes('public') ? serverUrl + upload : defaultProfile
                        }
                      />
                      <h5 className="title">{`${user?.firstName} ${user?.lastName}`}</h5>
                    <p className="description mb-4">{user?.email}</p>
                    <UploadIcon
                      color={color}
                      upload={upload}
                      setUpload={setUpload}
                      UpdateProfileImage={UpdateProfileImage}
                    />
                  </div>
                </div>
                <div className="card-footer">
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default UserProfile;
