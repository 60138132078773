import instance from "../../../config/axios";
import { ParseError, getAuthHeader } from "../../../config/utils";

export const GetArtLength = async (data) => {

    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };

    const headers = await getAuthHeader();

    return instance
        .get(`admin/art/length?folderHash=${data.folderHash}&userId=${data.userId}&category=${data.category}`, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {

                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};
export const GetArtByUser = async (data) => {

    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };

    const headers = await getAuthHeader();

    return instance
        .post(`admin/art/user?id=${data}`, data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {

                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};

export const UploadToPinataService = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    // const headers = await getAuthHeader();
    return instance
        .post("admin/art/uploadToPinata", data)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};
