import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "60%",
    left: "20%",
    top: "10%",
    height: "auto",
    border: "0px solid #000",
    borderRadius: "8px",
    overflow: "auto",
    [theme.breakpoints.down("xl")]: {
      width: "60%",
      left: "20%",
      top: "7%",
      bottom: "4%",
    },
    [theme.breakpoints.down("md")]: {
      width: "70%",
      left: "15%",
      top: "18%",
      bottom: "4%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "60%",
      left: "20%",
      top: "9%",
      bottom: "4%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      left: "5%",
      top: "11%",
      bottom: "4%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      left: "5%",
      top: "11%",
      bottom: "4%",
    },
  },
  paper60: {
    position: "absolute",
    width: "700px !important",
    left: "0",
    right: "0",
    margin: "0 auto",
    top: "12%",
    border: "0px solid #000",
    borderRadius: "8px",
    overflow: "auto !important",
    backgroundColor: "#27293d",
    // height:"auto"
    height: "auto",

    // [theme.breakpoints.down('xl')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '7%',
    //   bottom: '4%',
    // },
    // [theme.breakpoints.down('md')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '18%',
    //   bottom: '4%',
    // },
    // [theme.breakpoints.down('lg')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '9%',
    //   bottom: '4%',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '11%',
    //   bottom: '4%',
    //       },
    // [theme.breakpoints.down('xs')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '11%',
    //   bottom: '4%',
    // }
  },
  header60: {
    position: "absolute",
    width: "700px !important",
    left: "0 !important",
    right: "0",
    margin: "0 auto",
    top: "6% !important",
    backgroundColor: "#6b276a!important",
    border: "0px solid #000",
    borderRadius: "8px",
    paddingRight: "0px !important",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    boxShadow: theme.shadows[5],
    overflow: "auto",
    // [theme.breakpoints.down('md')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '4%'
    // },
    // [theme.breakpoints.down('lg')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '4%'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: '60%',
    //   left: '10%',
    //   top: '6%'
    // }
  },
  paper80: {
    position: "absolute",
    width: "1400px !important",
    left: "0",
    right: "0",
    margin: "0 auto",
    top: "12%",
    border: "0px solid #000",
    borderRadius: "8px",
    overflow: "auto !important",
    backgroundColor: "#27293d",
    // height:"auto"
    height: "auto",

    // [theme.breakpoints.down('xl')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '7%',
    //   bottom: '4%',
    // },
    // [theme.breakpoints.down('md')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '18%',
    //   bottom: '4%',
    // },
    // [theme.breakpoints.down('lg')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '9%',
    //   bottom: '4%',
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '11%',
    //   bottom: '4%',
    //       },
    // [theme.breakpoints.down('xs')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '11%',
    //   bottom: '4%',
    // }
  },
  header80: {
    position: "absolute",
    width: "1400px !important",
    left: "0 !important",
    right: "0",
    margin: "0 auto",
    top: "6% !important",
    backgroundColor: "#f64b1c!important",
    border: "0px solid #000",
    borderRadius: "8px",
    paddingRight: "0px !important",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    boxShadow: theme.shadows[5],
    overflow: "auto",
    // [theme.breakpoints.down('md')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '4%'
    // },
    // [theme.breakpoints.down('lg')]: {
    //   width: '60%',
    //   left: '20%',
    //   top: '4%'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   width: '60%',
    //   left: '10%',
    //   top: '6%'
    // }
  },

  paperTeam: {
    position: "absolute",
    width: "60%",
    left: "20%",
    top: "10%",
    height: "auto",
    border: "0px solid #000",
    borderRadius: "8px",
    overflow: "hidden !important",
    [theme.breakpoints.down("xl")]: {
      width: "60%",
      left: "20%",
      top: "7%",
      bottom: "4%",
    },
    [theme.breakpoints.down("md")]: {
      width: "60%",
      left: "20%",
      top: "18%",
      bottom: "4%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "60%",
      left: "20%",
      top: "9%",
      bottom: "4%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "60%",
      left: "20%",
      top: "11%",
      bottom: "4%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60%",
      left: "20%",
      top: "11%",
      bottom: "4%",
    },
  },
  header: {
    width: "60%",
    top: "4%",
    left: "20%",
    backgroundColor: "#6b276a!important",
    border: "0px solid #000",
    borderRadius: "8px",
    paddingRight: "0px !important",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    boxShadow: theme.shadows[5],
    overflow: "auto",
    [theme.breakpoints.down("md")]: {
      width: "70%",
      left: "15%",
      top: "4%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "60%",
      left: "20%",
      top: "4%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      left: "5%",
      top: "6%",
    },
  },
  tabCardHeading: {
    width: "100%",
    display: "flex !important",
    justifyContent: "center",
    padding: "0 !important",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "block !important",
      marginTop: "2% !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      display: "flex !important",
      marginTop: "2% !important",
    },
    [theme.breakpoints.down("xl")]: {
      width: "100%",
      display: "flex !important",
      marginTop: "10px !important",
    },
  },
  QuillFieldLarge: {
    width: "100%",
    margin: "0 auto",
  },

  underline: {
    "&:hover": {
      "&:before": {
        borderBottom: ["rgba(0, 188, 212, 0.7)", "!important"],
      },
    },
    "&:before": {
      borderBottom: "rgba(0, 188, 212, 0.7)",
    },
  },

  input: {
    color: "pink",
  },

  buttonDrawerPaper: {
    position: "fixed !important",
    width: "64px !important",
    right: "0% !important",
    top: "35vh !important",
    height: "auto !important",
    overflowY: "hidden !important", // hide vertical
    overflowX: "hidden !important", // hide horizontal
    backgroundColor: "rgba(0, 0, 0, 0.3) !important",
    border: "0px solid #000 !important",
    borderRadius: "8px 0 0 8px !important",
    textAlign: "center !important",
    overflow: "auto !important",
    zIndex: 1031,
    // [theme.breakpoints.down("sm")]: {
    //   width: "60px !important",
    // },
  },

  promoSectionButtons: {
    display: "flex",
    padding: "0px",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default useStyles;
