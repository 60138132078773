/* eslint-disable */
import { useEffect, useState, useRef  } from "react";
import TextFieldLabel from "../TextfieldLabel";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "formik";

const MultiSelect = (props) => {
	const {
		values,
		touched,
		errors,
		fieldName,
		field,
		required,
		widthClass,
		setFieldValue,
		place,
		role
	} = props;
	const customStyles = {
		menu: (provided, state) => ({
			...provided,
			width: state.selectProps.width,
			backgroundColor: "white",
			borderBottom: "1px dotted pink",
			color: state.selectProps.menuColor,
			padding: 1,
		}),
		control: (styles) => ({
			...styles,
			backgroundColor: "#27293d",
			borderColor: "#2b3553",
			":hover": {
				borderColor: "#2b3553",
				color: "white",
			},
			":visited": {
				backgroundColor: "red",
				color: "white",
			},
		}),
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: "hsl(298deg 64% 58%)",
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "white",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "red",
			":hover": {
				backgroundColor: "red",
				color: "white",
			},
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = "opacity 300ms";
			const backgroundColor = "hsl(295deg 67% 59%)";
			return { ...provided, opacity, transition, backgroundColor };
		},
	};
	const [options, setOptions] = useState([]);
	const selectRef = useRef(null);
	useEffect(() => {
		setOptions([])
		setFieldValue("selectedLayers", "");
		if (values.layers.length > 0) {
			let update = values.layers.map((l) => ({ value: l, label: l }));
			setOptions(update)
		}
	}, [values.layers]);
	  useEffect(() => {
    selectRef.current.setValue([]);
  }, [options]);
	const HandleMultiSelect = (value) => {
		let temp = [];
		if (value.length === 0) {
			setFieldValue("selectedLayers", "");
		}
		if (value.length > 0) {
			value.map((v) => {
				temp.push(v.value);
				return null
			});
			setFieldValue("selectedLayers", temp);
		}
	};
	return (
		<>
			<Form.Group
				size="lg"
				className={`${touched && touched[fieldName] && errors && errors[fieldName]
					? "has-danger has-label form- position-relative " + widthClass
					: touched && touched[fieldName] && errors && !errors[fieldName]
						? "has-success has-label form-group " + widthClass
						: "has-label form-group " + widthClass} ${role ? role : ''}`
				}
			>
				<Form.Label className="w-100">
					<div className="help-content d-flex align-items-center justify-content-between w-100">
						<TextFieldLabel widthclass="" field={field} required={required} />
					</div>
				</Form.Label>
				{ }
				{ }
				<Select
				    ref={selectRef}
					styles={customStyles}
					width="100%"
					menuColor="#fc407c"
					isMulti
					name="colors"
					placeholder={place}
					onChange={HandleMultiSelect}
					options={values.layers && values.layers.length > 0 ? options : []}
					className="basic-multi-select"
					classNamePrefix="select"
				/>
				<ErrorMessage
					name={fieldName}
					component="div"
					className={"text-warning"}
				/>
				{ }
			</Form.Group>
		</>
	);
};
export default MultiSelect;
