import * as Yup from "yup";
export const YearlyTableHeadings = [
  {
    id: "title",
    label: "Title",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "subHead",
    label: "Sub Heading",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "price",
    label: "Price",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "status",
    label: "Status",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light",
  },
];

export const createRows = (rowData) => {
  const rows = rowData.map((i) => {
    return {
      id: i._id,
      title: i.title,
      type: i.type,
      subHead: i.subHead,
      stripeProductId:i.stripeProductId,
      price: i.price === "" ? 0 : i.price,
      status: i.status,
      description: i.description,
      maxUploadFile: i.maxUploadFile,
      maxGenerateCollection:i.maxGenerateCollection,
    };
  });
  return rows;
};

export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    stripeProductId:updatedRow.stripeProductId ? updatedRow.stripeProductId : '',
    title: updatedRow.title ? updatedRow.title : "",
    type: updatedRow.type ? updatedRow.type : "",
    subHead: updatedRow.subHead ? updatedRow.subHead : "",
    price: updatedRow.price ? updatedRow.price/100 : 0,
    status: updatedRow.status ? updatedRow.status : "",
    description: updatedRow.description ? updatedRow.description : "",
    maxUploadFile: updatedRow.maxUploadFile ? updatedRow.maxUploadFile : "",
    maxGenerateCollection: updatedRow.maxGenerateCollection ? updatedRow.maxGenerateCollection : "",
  };
  return Values;
};

export const YearlyInitialValues = {
  title: "",
  type: "year",
  subHead: "",
  price: "",
  status: true,
  description: "",
  maxUploadFile:"",
  maxGenerateCollection:"",
};

export const YearlySchema = Yup.object().shape({
  title: Yup.string().required("Title is required!"),
  type: Yup.string().required("Type is required!"),
  subHead: Yup.string().required("Sub Headiing is required!"),
  price: Yup.string().required("Price is required!"),
  description: Yup.string().required("Description is required!"),
  maxUploadFile: Yup.number().required("how many file upload is required!"),
  maxGenerateCollection: Yup.number().required("how many Collection genrate is required!"),
});

export const YearlyFormFields = [
  {
    field: "Title",
    fieldName: "title",
    place: "Enter Title",
    type: "text",
    required: true,
    field1: "Sub Heading",
    fieldName1: "subHead",
    place1: "Enter Sub Heading",
    type1: "text",
    required1: true,
  },
  {
    field: "Package Type",
    fieldName: "type",
    place: "Enter Package Type",
    type: "text",
    required: true,
    field1: "Price",
    fieldName1: "price",
    place1: "Enter price",
    type1: "number",
    required1: true,
  },
  {field: "User can upload max file",
  fieldName: "maxUploadFile",
  place: "User can upload max file",
  type: "number",
  required: true,
  field1: "Amount to generate",
  fieldName1: "maxGenerateCollection",
  place1: "Enter Amount to generate",
  type1: "number",
  required1: true,

  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "description",
    required: true,
  },
];
