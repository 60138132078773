import instance from '../../config/axios';
import { ParseError } from '../../config/utils';
export const UserLogin = async (formData) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .post('auth/login', formData)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    data: response,
                    status: 200,
                    message: response.message
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data)
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
};
export const UserRegister = async (formData) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .post('auth/register', formData)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data)
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
};

export const UserLogout = async (formData) => {

    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .post('auth/logout', formData)
        .then((response) => {})
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}

export const UserDelete = async (formData) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .delete('auth/delete/' + formData.email)
        .then((response) => {})
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}
export const UserDeactivate = async (formData) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .put('auth/update/' + formData.email)
        .then((response) => {})
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}
export const UserForgotPassword = async (email) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .post('auth/forgot-password', email)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: response.data.message
                };
            } else {
                return {
                    ...responseData,
                    message: response.data.message
                };
            }
        })
        .catch((err) => {
            console.log("🚀 ~ file: index.js ~ line 198 ~ UserForgtotPassword ~ err", err)
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}

export const UserResetPassword = async (email) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .post('auth/reset-password', email)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: response.data.message
                };
            } else {
                return {
                    ...responseData,
                    message: response.data.message
                };
            }
        })
        .catch((err) => {
            console.log("🚀 ~ file: index.js ~ line 198 ~ UserForgtotPassword ~ err", err)
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}

export const VerifyCode = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .post('auth/verifyCode', data)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: response.data.message
                };
            } else {
                return {
                    ...responseData,
                    message: response.data.message
                };
            }
        })
        .catch((err) => {
            console.log("🚀 ~ file: index.js ~ line 198 ~ UserForgtotPassword ~ err", err)
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}