import React, { useEffect } from "react";
import Header from "components/Homepage/Header";
import Footer from "components/Homepage/Footer";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Header />
            <div className="wrapper">
                <div className="login-main all-content-page">
                    <div className="container-width">
                        <div className="privacy-list">
                            <div className='mb-5 text-center'>
                                <h2>Privacy Policy</h2>
                                <p className="mb-4">
                                    MutoLab is one of the world’s best NFT avatar creation and minting services. MutoLab allows creators to develop and launch their own bespoke drop of NFTs, with holders receiving exclusive benefits set by the creator that can only be accessed through this platform.
                                </p>
                                <p>
                                    MutoLab is committed to protecting and respecting your privacy. This Privacy Policy (this “Policy”) sets out how we collect and process personal information about you when you visit our website at <a href='https://mutolab.metamuto.com/' target='_blank' rel="noreferrer">https://mutolab.metamuto.com/</a> , when you use our Services (our “Service”), or when you otherwise do business or make contact with us.

                                </p>
                            </div>
                            <div className='mb-4'>
                                <h3>What information do we collect?</h3>
                                <p className="mb-4">
                                    MutoLab collects data to enable us to operate the Service effectively and to provide you with the best experiences on our website and through our Service. You provide some of this data to us directly, such as when you register to use our Service, contact us for support, respond to a survey, make an inquiry through our website, contact us for support, or contact us as a prospective user. We also obtain and process data in the context of making the Service available to you.
                                </p>
                                <p>
                                    You have choices about the data we collect. When you are asked to provide personal data, you may decline. However, if you decide not to provide the data necessary to enable us to make the Service available to you, you may not be able to sign up for or use the Service. The data we collect depends on the context and nature of your interactions with MutoLab and your choices (including your privacy settings). The information we collect can include the following:
                                </p>
                                <ul>
                                    <li>Your personal and business name, email address, cryptocurrency wallet address, and any other data you provide through the use of our platform.</li>
                                    <li>Device and Usage information. We may collect data about your device and how you and your device interact with MutoLab and our Service. For example, we may collect your interactions on our website, your usage patterns of features on the platform, location data, and interactions you have with us. We may also collect data about your device and the network you use to connect to our Service, including your IP address, browser type, operating system, and referring URLs.</li>
                                </ul>
                            </div>
                            <div className="mb-4">
                                <h3>How do we utilize your information?</h3>
                                <p className="mb-4">
                                    We use the data we collect to operate our business and to make our Service available to you. This includes using data collected to improve our Service and to personalize your experiences on the platform. We may also use the data to communicate with you too, among other things, provide security updates, inform you about your account, and give you additional information about the Service. We may also utilize the data to manage your email subscriptions, improve the relevance and security of our website, respond to user queries, send you periodic marketing communications about our Services, and improve the accuracy and relevance of our advertising.
                                </p>
                                <p className="mb-4">
                                    Generally - We use data to respond to your inquiries and requests relating to our Service, create and administer your account, and provide us with information and access to your requested resources. We also may use data for general business purposes, including, among other things, to improve customer service, to help us improve the content and functionality of our Service, to enforce our Terms of Use, to better understand our users, to protect against wrongdoing, and to generally manage our business.
                                </p>
                                <p className="mb-4">
                                    Providing and improving our Service - We use collected data to provide the Service to you, to complete essential business operations, and to enhance the Service for our users. This includes operating the Service, maintaining and improving its performance of the Service, developing new features, conducting research, and offering customer support. Examples of such uses include the following:
                                </p>
                                <div className="mb-4">
                                    <h4>Providing the Service</h4>
                                    <p>
                                        We use data to carry out your transactions with us and to make the Service available to you. In some instances, the Service may include personalized features and recommendations to enhance your enjoyment and to tailor your experience based on the data collected automatically.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Improving Advertising Campaigns</h4>
                                    <p>
                                        We may use your data to improve our advertising campaigns, primarily to prevent targeting you with advertisements that are not relevant to you.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Technical support</h4>
                                    <p>
                                        We use data to diagnose problems and to provide customer care and other support Services.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Sending Periodic Emails</h4>
                                    <p>
                                        We may use your data to send periodic emails. For example, depending on the marketing preferences you select on your privacy dashboard, we may send you occasional marketing emails about our Service, which you can unsubscribe from at any time using the link provided in the message.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Improving the Service</h4>
                                    <p>
                                        We use data to continually improve our website and our Service, including system administration, system security, adding new features or capabilities, and maintaining existing services.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Business Operations</h4>
                                    <p>
                                        We use data to develop business intelligence that enables us to operate, protect, make more informed decisions, and report on the performance of our business.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Promotions</h4>
                                    <p>
                                        We may use your data to administer contests, giveaways, surveys, or other site features.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Communications</h4>
                                    <p>
                                        We use the data we collect to communicate and personalize those communications with you. For example, we may contact you to discuss your account, remind you about the service's features available for your use, update you about a support request, or invite you to participate in a competition or survey. Additionally, you can sign up for email subscriptions and have the option to receive marketing communications from us.
                                    </p>
                                </div>
                            </div>
                            <div className="mb-4">
                                <h3>How do we deal with data protection?</h3>
                                <p>
                                    We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information. We offer the use of a secure server. After that, it is encrypted into our gateway providers' database only to be accessible by those authorized with special access rights to such systems. They are required to keep the information confidential.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h3>How do we ensure that our processing systems remain confidential, resilient, and available?</h3>
                                <p className="mb-4">
                                    We implement a variety of measures to ensure that our processing systems remain confidential, resilient, and available. Specifically, we have implemented processes to help ensure business continuity, high availability, and prompt disaster recovery. In addition, we commit to maintaining strong physical and logical access controls and conduct penetration testing to identify and address potential vulnerabilities in our Service and surrounding systems.
                                </p>
                                <p className="mb-4">
                                    Business Continuity - We keep encrypted data backups daily in multiple regions. While never expected, in the case of production data loss (i.e., primary data stores loss), we can restore organizational data from these backups.
                                </p>
                                <p className="mb-4">
                                    High Availability - Every part of the Service utilizes properly-provisioned, redundant servers (e.g., multiple load balancers, web servers, replica databases) in case of failure.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h3>Do we disclose any information to outside parties?</h3>
                                <p className="mb-4">
                                    We share your data with your consent or as necessary to make the Service available to you. We may also share your data with vendors working on our behalf; when required by law or to respond to legal processes; protect our customers; protect lives; maintain the security and integrity of our Service; and protect our rights or property.
                                </p>
                                <p className="mb-4">
                                    We share your data with your consent or as necessary to make the Service available to you. We also share personal data with vendors or agents working on our behalf for the purposes described in this Policy. For example, companies we have hired to provide cloud hosting Services, off-site backups, and customer support may need access to personal data to provide those functions. In such cases, these companies are required to abide by our data privacy and security requirements. Therefore, they are not allowed to use personal data they receive from us for any other purpose. If you have questions or concerns about our vendors, contact us at <a href="mailto:muto@metamuto.com">muto@metamuto.com</a>.
                                </p>
                                <p className="mb-4">
                                    We may disclose your data as part of a corporate transaction such as a corporate sale, merger, reorganization, dissolution, or similar event. Finally, we will access, transfer, disclose, and/or preserve personal data when we have a good faith belief that doing so is necessary to:
                                </p>
                                <ul>
                                    <li>Comply with applicable law or respond to valid legal processes, judicial orders, or subpoenas</li>
                                    <li>Respond to requests from public or governmental authorities, including for national security or law enforcement purposes</li>
                                    <li>Operate and maintain the security or integrity of our Service, including preventing or stopping an attack on our computer systems or networks</li>
                                    <li>Protect the rights, interests, or property of MutoLab, or third parties</li>
                                    <li>Protect the interests of our users, customers, or other third parties (including, for example, to prevent spam or attempts to defraud users of our products or to help prevent the loss of life or severe injury of anyone);</li>
                                    <li>prevent or investigate possible wrongdoing in connection with the Service; or</li>
                                    <li>Enforce our Terms of Use.
                                        We may use and share aggregated non-personal information with third parties for marketing, advertising, and analytics purposes. We do not sell or trade your personal information to third parties.
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-4">
                                <h3>How to Access and Control Your Personal Data</h3>
                                <div className="mb-4">
                                    <h4>Data Access</h4>
                                    <p>You can access your personal data on your account’s user dashboard.</p>
                                </div>
                                <div className="mb-4">
                                    <h4>Data Portability</h4>
                                    <p>
                                        You can request a copy of your personal data by emailing us at <a href="mailto:muto@metamuto.com">muto@metamuto.com</a> and including “Please send me a copy of my personal data” in the “Subject” line. MutoLab will verify your ability to access that email, then sends you a digital export of the data we hold that is associated with your email address. We will use reasonable efforts to respond to your request within 14 days, but in all events, within 30 days of our receipt of the request. MutoLab may be limited in sending specific personal data to you (e.g., identifying your Metamask wallet).
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Data Erasure</h4>
                                    <p>
                                        You may request that MutoLab delete your personal data by emailing us at <a href="mailto:muto@metamuto.com">muto@metamuto.com</a> and including “Please delete my personal data” in the “Subject” line. MutoLab will verify your ability to access that email, then deletes the personal data associated with your email address. We will use reasonable efforts to respond to your request within 14 days, but in all events, within 30 days of our receipt of the request.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Data Correction</h4>
                                    <p>
                                        You can modify your personal data on your account’s user dashboard.
                                    </p>
                                </div>
                                <div className="mb-4">
                                    <h4>Your Communications Preferences</h4>
                                    <p className="mb-4">
                                        You can choose whether you wish to receive marketing communications from us. If you receive marketing communications from us and want to opt out, you can follow the directions in that communication. You can also make choices about your receipt of marketing communications by signing into your account, and viewing and managing your communication permissions in your account’s user dashboard, where you can update contact information, manage your contact preferences, opt out of email subscriptions, and choose whether to share your contact information with MutoLab and our partners.
                                    </p>
                                    <p className="mb-4">
                                        Alternatively, you can request that we withdraw consent to use your personal data by emailing us at <a href="mailto:muto@metamuto.com">muto@metamuto.com</a> and including “Please withdraw my consent for marketing communications” in the “Subject” line. MutoLab will verify your ability to access that email, then update our systems to remove your email address from our marketing communications method. We will use reasonable efforts to respond to your request within 14 days, but in all events, within 30 days of our receipt of the request. Please note that these choices do not apply to mandatory communications that are part of the Service or to surveys or other informational communications that have their unsubscribe method.
                                    </p>
                                </div>
                            </div>
                            <div className="mb-4">
                                <h3>Third-Party Links</h3>
                                <p>
                                    Occasionally, at our discretion, we may include or offer third-party products or Services on our website or through our Service. For example, suppose you access other websites using the links provided. In that case, the operators of these websites may collect information from you that will be used by them following their privacy policies. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h3>Where we Store and Process Personal Data; International Transfers</h3>
                                <p>
                                    Personal data collected by MutoLab may be stored and processed in the country where MutoLab or its affiliates, subsidiaries, or Service providers maintain facilities. The storage location(s) are chosen to improve performance, operate efficiently, and create redundancies to protect the data in the event of an outage or other issues. We ensure that the data we collect is processed according to the provisions of this Policy and the requirements of applicable law wherever the data is located.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h3>Data Retention</h3>
                                <p className="mb-4">
                                    We may retain your personal information as long as you continue to use the Service, have an account with us, or for as long as is necessary to fulfill the purposes outlined in this Policy. You can ask to close your account by contacting us as described above, and we will delete your personal information upon request. However, we may retain personal data for an additional period as permitted or required under applicable laws, for legal, tax, or regulatory reasons, or for legitimate and lawful business purposes.
                                </p>
                                <p className="mb-4">
                                    We will retain your personal data for as long as necessary to make the Service available to you or for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different data types, actual retention periods can vary significantly. The criteria we use to determine the retention periods include the following:
                                </p>
                                <ul>
                                    <li>How long is the personal data needed to make the Service available to you and/or operate our business? It includes maintaining and improving the performance of the Service, keeping our systems secure, and maintaining appropriate business and financial records. This general rule establishes the baseline for most data retention periods.</li>
                                    <li>Is there an automated control, such as in your user dashboard, that enables you to access and delete personal data at any time? If not, a shortened data retention time will generally be adopted.</li>
                                    <li>Is the personal data of a sensitive type? If so, a shortened retention time is generally appropriate.</li>
                                    <li>Has the user provided consent for a more extended retention period? If so, we will retain the data following your consent.</li>
                                    <li>
                                        Is MutoLab subject to a legal, contractual, or similar obligation to retain the data? Examples can include mandatory data retention laws in the applicable jurisdiction, government orders to preserve data relevant to an investigation, or data that must be retained for litigation.
                                    </li>
                                </ul>
                            </div>
                            <div className="mb-4">
                                <h3>Changes to our Privacy Policy</h3>
                                <p>
                                    We will update this privacy statement when necessary to reflect customer feedback and changes to our Service. When we post changes to this statement, we will revise the "last updated" date at the top. For example, suppose there are material changes to the information or how MutoLab will use or process your personal data. In that case, we will notify you either by prominently posting a notice of such changes before they take effect or by sending you a notification directly. Therefore, please review this privacy statement periodically to learn about and stay updated on how MutoLab protects your information.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h3>How to Contact Us</h3>
                                <p className="mb-4">
                                    If you have a technical or support question, please send us an email at
                                    <a href="mailto:muto@metamuto.com">muto@metamuto.com</a>.
                                </p>
                                <p className="mb-4">
                                    If you have a privacy concern, complaint, or question for the Data Protection Officer of MutoLab, don't hesitate to contact us by email at <a href="mailto:muto@metamuto.com">muto@metamuto.com</a>.
                                </p>
                                <p className="mb-4">
                                    We will respond to questions or concerns within 30 days.
                                </p>
                                <p className="mb-4">
                                    Unless otherwise stated, MutoLab is a data controller for personal data we collect through the Service subject to this statement.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default PrivacyPolicy;
