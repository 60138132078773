import * as Yup from "yup";
export const getInitialValues = data => {
    return {
        mainHead: data.mainHead,
        subHead: data.subHead,
        card1heading: data.card1.heading,
        card1Description: data.card1.description,
        card2heading: data.card2.heading,
        card2Description: data.card2.description,
        card3heading: data.card3.heading,
        card3Description: data.card3.description,
        card4heading: data.card4.heading,
        card4Description: data.card4.description,
        type: data.type,
    }
};

export const HowItWorksFormFields = [
    {
        field: "Main Heading",
        fieldName: "mainHead",
        place: "",
        type: "text",
        required: true,
        field1: "Sub Heading",
        fieldName1: "subHead",
        place1: "",
        type1: "text",
        required1: true,
    }
];
export const HowItWorksCardFormFields = [
    {
        field: 'Heading 1',
        fieldName: "card1heading",
        place: "",
        type: "text",
        required: true,
    },
    {

        field: 'Description',
        fieldName: "card1Description",
        place: "",
        type: "description",
        required: true,
    },
    {
        field: 'Heading 2',
        fieldName: "card2heading",
        place: "",
        type: "text",
        required: true,
    },
    {

        field: 'Description',
        fieldName: "card2Description",
        place: "",
        type: "description",
        required: true,
    },
    {
        field: 'Heading 3',
        fieldName: "card3heading",
        place: "",
        type: "text",
        required: true,
    },
    {

        field: 'Description',
        fieldName: "card3Description",
        place: "",
        type: "description",
        required: true,
    },
    {
        field: 'Heading 4',
        fieldName: "card4heading",
        place: "",
        type: "text",
        required: true,
    },
    {

        field: 'Description',
        fieldName: "card4Description",
        place: "",
        type: "description",
        required: true,
    },
]

export const HowItWorksSchema = Yup.object().shape({
    mainHead: Yup.string().trim('Required').required('Required'),
    subHead: Yup.string().trim('Required').required('Required'),
    card1heading: Yup.string().trim('Required').required('Required'),
    card1Description: Yup.string().trim('Required').required('Required'),
    card2heading: Yup.string().trim('Required').required('Required'),
    card2Description: Yup.string().trim('Required').required('Required'),
    card3heading: Yup.string().trim('Required').required('Required'),
    card3Description: Yup.string().trim('Required').required('Required'),
    card4heading: Yup.string().trim('Required').required('Required'),
    card4Description: Yup.string().trim('Required').required('Required'),
});
