import instance from "../../../config/axios";
import { ParseError, getAuthHeader } from "../../../config/utils";
export const GetAdminInfo = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .get("admin/" + data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const UpdatePassword = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("admin/password", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const UpdateAdminProfile = async (formData) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  const data = new FormData();
  if (formData.profileImage) {
    data.append("id", formData.id);
    data.append("img", formData.profileImage);
  }else{
    data.append("firstName", formData.firstName);
    data.append("lastName", formData.lastName);
    data.append("phone", formData.phone);
    data.append("id", formData.id);
  }
  return instance
    .put("admin/profile", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
export const GetAllUsers = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getAuthHeader();
  return instance
    .get("admin/users", headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        return {
          ...responseData,
          status: 200,
          message: response.data.message,
          data: response.data.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
}
export const updateUserStatus = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };

  const headers = await getAuthHeader();
  return instance
    .put("admin/user/status", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        return {
          ...responseData,
          status: 200,
          message: response.data.message,
          data: response.data.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
}