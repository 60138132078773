import { useEffect, useState, useContext } from "react";
import Swal from "sweetalert2";
import GenericTable from "ReuseableComps/GenericTables";
import SkeltonTable from "ReuseableComps/GenericTablesSkelton";
import {
  CategoriesTableHeadings,
  createRows,
  CategoryFormFields,
  setInitialValues,
  CategoryInitialValues,
  CategorySchema,
} from "./helpers";
import {
  GetCategories,
  AddCategory,
  updateCategory,
  DeleteCategory,
} from "../../../services/admin/Categories";
import TableRows from "./TableRows";
import { ToasterContext } from "../../../app";
function Categories() {
  const [dataComp, setCompData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-simple text-success",
      cancelButton: "btn btn-simple text-danger",
    },
    buttonsStyling: false,
  });

  const getCategoriesData = () => {
    setLoading(true);
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
    GetCategories({ type: "admin", userId: user.id }).then((res) => {
      if (res.status === 200) {
        setCompData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };

  const AddCategoryService = async (data, actions) => {
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
    data = { ...data, userId: user.id, type: "admin" }
    AddCategory(data).then((res) => {
      if (res.status === 200) {
        setCompData([res?.data, ...dataComp ]);
        handleClose()
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  const updateCategoryService = async (data, actions) => {
    updateCategory(data).then((res) => {
      if (res.status === 200) {
        let updated = dataComp.map(dc => dc._id === res?.data._id ? dc = res?.data : dc)
        setCompData(updated);
        handleClose()
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  const DeleteCategoryService = async (data, actions) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        background: "#27293d",
      })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteCategory(data).then((res) => {
            if (res.status === 200) {
              swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: res.message,
                icon: "success",
                background: "#27293d",
                timer: 1000,
              });
              let updated = dataComp.filter(dc => dc._id !== res?.data._id)
              setCompData(updated);
            } else {
              swalWithBootstrapButtons.fire({
                title: "",
                text: res.message,
                icon: "warning",
                background: "#27293d",
                timer: 1000,
              });
            }
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "",
            icon: "error",
            background: "#27293d",
            timer: 1000,
          });
        }
      });
  };

  useEffect(() => {
    getCategoriesData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <SkeltonTable header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} />
      ) : (
        <GenericTable
          isModal={true}
          modalHeading={"Category"}
          heading={"Categories"}
          actionState={actionState}
          open={open}
          updatedRow={updatedRow}
          setUpdatedRow={setUpdatedRow}
          setActionState={setActionState}
          //Handlers
          handleOpen={handleOpen}
          handleClose={handleClose}
          Columns={CategoriesTableHeadings}
          searchPlaceHolder={"Seacrh Categories"}
          data={dataComp}
          TableRow={TableRows}
          tostify={tostify}
          //Helpers States &  Function
          createRows={createRows}
          initialValues={CategoryInitialValues}
          schema={CategorySchema}
          setInitialValues={setInitialValues}
          formFields={CategoryFormFields}
          //SERVICES
          add={AddCategoryService}
          update={updateCategoryService}
          deleteRow={DeleteCategoryService}
        />
      )}
    </>
  );
}
export default Categories;
