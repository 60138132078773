import * as Yup from "yup";
export const ProfileInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
};

export const ProfileFormFields = [
  {
    field: "First Name",
    fieldName: "firstName",
    place: "Enter Your First Name",
    type: "text",
    required: true,
    field1: "Last Name",
    fieldName1: "lastName",
    place1: "Enter Your Last Name",
    type1: "text",
    required1: true,
  },
];

export const ProfileSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'Must be 50 characters or less').trim()
    .required("First Name is required!").notOneOf([" "], "First Name cannot contain only spaces"),
  lastName: Yup.string().max(50, 'Must be 50 characters or less').trim()
  .required("Last Name is required!").notOneOf([" "], "Last Name cannot contain only spaces"),
});

export const getInitialValues = (data) => {
  return {
    id: data.id ? data.id : data._id,
    firstName: data.firstName ? data.firstName : "",
    lastName: data.lastName ? data.lastName : "",
  };
};
