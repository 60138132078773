import React from 'react'
import TextFieldLabel from "../TextfieldLabel";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ErrorMessage } from "formik";
import { Form } from 'react-bootstrap';

const Ckeditor = (props) => {
    const {
        values,
        touched,
        errors,
        fieldName,
        field,
        required,
        widthClass,
        setFieldValue,
        role,
    } = props;

    const inputHandler = (event, editor) => {
        setFieldValue(fieldName, editor.getData());
    };

    return (
        <>
            <Form.Group
                size="lg"
                className={`${touched && touched[fieldName] && errors && errors[fieldName]
                    ? "has-danger has-label form-group position-relative ckeditor " + widthClass
                    : touched && touched[fieldName] && errors && !errors[fieldName]
                        ? "has-success has-label form-group " + widthClass
                        : "has-label form-group " + widthClass} ${role ? role : ''}`
                }
                controlId={"formik-form-element-" + fieldName}
            >
                <Form.Label>
                    <TextFieldLabel widthclass="" field={field} required={required} />
                </Form.Label>
                <CKEditor editor={ClassicEditor} data={values[fieldName]} onChange={inputHandler}/>
                <ErrorMessage
                    name={fieldName}
                    component="div"
                    className={"text-warning"}
                />
            </Form.Group>
        </>
    )
}

export default Ckeditor