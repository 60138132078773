import ClipLoader from "../ClipLoader";
import { Button } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import { useState } from "react";
const FormikFormFooter = (props) => {
	const [downloading, setDownloading] = useState(false)
	const { isSubmitting, role, handleSubmit, heading, values, color, footerClass, pubPhase, walletPhase, deployPhase, btnDisable } =
		props;

	let { NftId } = useParams();

	const downloadNftFiles = (id) => {
		setDownloading(true)
		axios.get(
			process.env.REACT_APP_BASE_URL + "/collections/download/" + id
		).then(function (result) {
			if (result.data.code === 200) {
				const url = process.env.REACT_APP_BASE_URL + "/collections/downloaded/file-download?id=" + result.data.data
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('target', `_blank`);

				document.body.appendChild(link);
				link.click();
				link.remove();
				setDownloading(false)
			}

		})

	}

	return (
		<>
			{
				color === "primary" ? '' : ''
			}

			{heading === "Sign Up" || heading === "Login" ? (
				<>
					<Button
						disabled={isSubmitting}
						onClick={handleSubmit}
						className="login-btn"
						type='submit'
					>
						{" "}
						{isSubmitting ? (
							<div>
								<ClipLoader color={"white"} />
							</div>
						) : (
							<div className="d-flex justify-content-center align-items-center">
								<span>{heading === "Sign Up" ?
									<>
										<TiTick size={25} /> Register
									</> : "Login"}</span>
							</div>
						)}
					</Button>
					{heading === "Sign Up" ? (
						<NavLink
							to="/login"
							className="forgot-password d-flex justify-content-center mb-2"
						>
							Already a User? Login
						</NavLink>
					) : heading === "Login" ? (
						<NavLink
							to="/signup"
							className="forgot-password d-flex justify-content-center mb-2"
						>
							Not a User? Register
						</NavLink>
					) : null}
				</>

			) : values && values["mintAmount"] > 0 ? (
				<div className={footerClass ? "mt-3" : "card-footer"}>
					<Button
						disabled={isSubmitting}
						onClick={handleSubmit}
						className={'btn-theme'}
					>
						{" "}
						{isSubmitting ? (
							<div className="d-flex align-items-center">
								<ClipLoader color={"white"} /> <span className="ml-2">processing....</span>
							</div>
						) : (
							<div className="d-flex justify-content-center">
								<span>
									{heading === "NFT Details"
										? "Generate"
										: (heading === "Live Collections" && role === "user")
											? "Mint"
											: "Save"}
								</span>
							</div>
						)}
					</Button>
				</div>
			) : heading === "Collections" ? (
				<div className={footerClass ? "mt-3" : "card-footer d-flex justify-content-between"}>
					<Button
						disabled={isSubmitting || btnDisable}
						onClick={handleSubmit}
						className={'btn-theme'}
					>
						{" "}
						{isSubmitting || btnDisable ? (
							<div className="d-flex align-items-center">
								<ClipLoader color={"white"} /> <span className="ml-2">processing....</span>
							</div>
						) : (
							<div className="d-flex justify-content-center">
								{pubPhase && <span>Publish</span>}
								{walletPhase && <span>Connect Wallet</span>}
								{deployPhase && <span>Deploy</span>}
							</div>
						)}
					</Button>
					<Button
						disabled={isSubmitting || btnDisable}
						onClick={() => downloadNftFiles(NftId)}
						className={'btn-theme'}
					>
						{" "}
						{downloading ? (
							<div className="d-flex align-items-center">
								<ClipLoader color={"white"} /> <span className="ml-2">processing....</span>
							</div>
						) : (
							<div className="d-flex justify-content-center">
								Download <i className="fa fa-download pl-2"></i>
							</div>
						)}
					</Button>
				</div>
			) : heading === "Live Collections" ? (
				values && role === "user" && values["collections"].length > 0 ?
					(<div className={footerClass ? "mt-3" : "card-footer"}>
						<Button
							disabled={isSubmitting}
							onClick={handleSubmit}
							className={'btn-theme'}
						>
							{" "}
							{isSubmitting ? (
								<div>
									<ClipLoader color={"white"} />
								</div>
							) : (
								<div className="d-flex justify-content-center">
									<span>Mint</span>
								</div>
							)}
						</Button>
					</div>) : ''
			) : (
				<div className={footerClass ? "mt-3" : "card-footer"}>
					<Button
						disabled={isSubmitting}
						onClick={handleSubmit}
						type='submit'
						className={'btn-theme'}
					>
						{" "}
						{isSubmitting ? (
							<div className="d-flex align-items-center">
								<ClipLoader color={"white"} /> <span className="ml-2">processing....</span>
							</div>
						) : (
							<div className="d-flex justify-content-center">
								<span>Save</span>
							</div>

						)}
					</Button>
				</div>
			)}
		</>
	);
};
export default FormikFormFooter;
