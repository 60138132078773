import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import Web3 from "web3";

const MyWallet = (props) => {
    const [copySuccess, setCopySuccess] = useState('');
    const [walletDetail, setWalletDetail] = useState('')

    window.addEventListener('storage', () => {
        let wallet_connect = localStorage.getItem('wallet_connect') ? JSON.parse(localStorage.getItem('wallet_connect')) : ''
        if (wallet_connect) {
            setWalletDetail(wallet_connect)
        }
    })


    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied!');
            setTimeout(() => {
                setCopySuccess('');
            }, 2000);
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    const connectWallet = async () => {
        if (!window.ethereum) {
            alert("Please install MetaMask!");
        } else {
            try {
                const currProvider = window.ethereum;
                await currProvider.request({ method: "eth_requestAccounts" });
                //eslint-disable-next-line
                const web3 = new Web3(currProvider);
                const accounts = await web3.eth.getAccounts();
                const balance = await web3.eth.getBalance(accounts[0]);
                let bal = web3.utils.fromWei(balance.toString(), 'ether')
                setWalletDetail({ 'accounts': accounts[0], 'balance': bal })
                localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': accounts[0], 'balance': bal }))
                window.dispatchEvent(new Event("storage"));
            } catch (error) {
                console.log(error, "COnnect wallet error")
            }
        }
    };

    useEffect(() => {
        const accountChangeEvent = () => {
            if (window.ethereum) {
                window.ethereum.on('accountsChanged', () => {
                    connectWallet()
                })
                window.ethereum.on('networkChanged', () => {
                    connectWallet()
                })
            }
        }
        accountChangeEvent()
    }, [])

    useEffect(() => {
        let wallet_connect = localStorage.getItem('wallet_connect') ? JSON.parse(localStorage.getItem('wallet_connect')) : ''
        if (wallet_connect) {
            setWalletDetail(JSON.parse(localStorage.getItem('wallet_connect')))
            connectWallet()
        }
    }, [])

    return (
        <>
            <Row className="d-flex justify-content-center">
                <Col md='6'>
                    <div className="your-balance-area">

                        {

                            walletDetail?.balance && walletDetail?.accounts ?
                                <>
                                    <div className="dashboard-box ">
                                        <h3>Your Wallet</h3>
                                        <div className="d-flex align-items-center">
                                            <p className='wallet-info'>Address: {walletDetail?.accounts}
                                            </p>
                                            <a className="copy-text" href={() => false} onClick={() => copyToClipBoard(walletDetail?.accounts)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="folder" width="21" height="24" viewBox="0 0 21 24">
                                                    <path id="Icon_awesome-copy" data-name="Icon awesome-copy" d="M15,21v1.875A1.125,1.125,0,0,1,13.875,24H1.125A1.125,1.125,0,0,1,0,22.875V5.625A1.125,1.125,0,0,1,1.125,4.5H4.5V18.375A2.628,2.628,0,0,0,7.125,21ZM15,4.875V0H7.125A1.125,1.125,0,0,0,6,1.125v17.25A1.125,1.125,0,0,0,7.125,19.5h12.75A1.125,1.125,0,0,0,21,18.375V6H16.125A1.128,1.128,0,0,1,15,4.875Zm5.671-1.454L17.579.329a1.125,1.125,0,0,0-.8-.329H16.5V4.5H21V4.216a1.125,1.125,0,0,0-.329-.8Z" fill="#878991" />
                                                </svg>
                                            </a>
                                            <span className="ml-3 copy">{copySuccess}</span>
                                        </div>
                                        <a href={() => false}>Balance: {walletDetail?.balance}</a>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="dashboard-box">
                                        <p className="mb-3">No Wallet Connected</p>
                                        <Button className="btn-theme" onClick={connectWallet}>Connect MetaMask</Button>
                                    </div>
                                </>

                        }



                        <div className="total-offers d-flex justify-content-between">
                            {/* <div className="total-offers-left d-flex align-items-center">
                                <h4 className="mb-0">Total Offers</h4>
                                <a href="/" className="mb-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.591" height="17.666" viewBox="0 0 21.591 17.666">
                                        <g id="Group_1178" data-name="Group 1178" transform="translate(-1564.219 -363.003)">
                                            <path id="Path_3220" data-name="Path 3220" d="M5545.62,379.267l5.49-7.194,5.467,3.921,6.538-11.727,1.5,6.929"
                                                transform="translate(-3980)" fill="none" stroke="#c220d6" stroke-linecap="round" stroke-linejoin="round"
                                                stroke-width="2" />
                                            <path id="Path_3221" data-name="Path 3221" d="M5562.048,364.267l-6.93,2.3" transform="translate(-3978.995)"
                                                fill="none" stroke="#c220d6" stroke-linecap="round" stroke-width="2" />
                                        </g>
                                    </svg>
                                </a>
                            </div> */}
                            {/* <h4 className="mb-0">USD 420,000<span className="offer-yellow">(59.11%)</span></h4> */}
                        </div>
                        {/* <Button className="light-gray-btn">View Transactions</Button> */}
                    </div>
                </Col>
            </Row>

        </>
    )

}

export default MyWallet;