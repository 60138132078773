import Actions from "ReuseableComps/Actions";

const TableRow = (props) => {
  const {
    row,
    index,
    handleOpen,
    setUpdatedRow,
    setActionState,
    deleteRow,
  } = props;

  return (
    <>
      <tr key={row.id + index} id={"faqs-tr-" + index}>
        <td className="text-left ">
          <span>{row.title}</span>
        </td>
        <td className="text-left ">
          <Actions
            handleOpen={handleOpen}
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            row={row}
            deleteRow={deleteRow}
          />
        </td>
        
      </tr>
    </>
  );
};
export default TableRow;
