import instance from "../../../config/axios";
import { ParseError, getAuthHeader } from "../../../config/utils";

export const GetNetworks = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .get("admin/networks/", headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const GetAllNetworks = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  return instance
    .get(process.env.REACT_APP_CHAINLIST)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: "Networks Fetched Successfully",
          data: response,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const AddNetwork = async (networkConfig) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .post("admin/networks/", { network: networkConfig }, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const DeleteNetwork = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .delete("/admin/networks/" + id, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateNetworkStatus = async (data) => {
  const responseData = {
      loading: false,
      status: 210,
      message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
      .put("/admin/network/status", data, headers)
      .then((response) => {
          if (response.data && response.data.code === 200) {
              return {
                  ...responseData,
                  status: 200,
                  message: response.data.message,
                  data: response.data.data,
              };
          } else {
              return {
                  ...responseData,
                  message: ParseError(response.data),
              };
          }
      })
      .catch((err) => {
          return {
              ...responseData,
              message: ParseError(
                  err.response && err.response.data ? err.response.data : err.message
              ),
          };
      });
};
