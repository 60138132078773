/*eslint-disable*/
import React, { useContext, useState, useEffect } from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Image, Dropdown, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { UserLogout } from '../../../services/auth';
import { userPlanContext } from "contexts/UserPlanContext";
import { serverUrl } from "config/config";
import defaultProfile from '../../../assets/images/defaultProfile.jpg'
import Web3 from "web3";
import { ImageContext } from "app";
import { GetUser } from "services/user";

const UserDasboardHead = (props) => {

    const [balance, setBalance] = useState('')
    const [load, setLoad] = useState('')
    const [walletDetail, setWalletDetail] = useState('')

    const [profileImage, setProfileImage] = useContext(ImageContext);
    const { userCurrentPlan } = useContext(userPlanContext);

    window.addEventListener('storage', () => {
        let wallet_connect = localStorage.getItem('wallet_connect') ? JSON.parse(localStorage.getItem('wallet_connect')) : ''
        if(wallet_connect && walletDetail === ''){
            setWalletDetail(wallet_connect)
        }
    })


    let history = useHistory()
    const user = JSON.parse(localStorage.getItem("user"));

    const getUserData = (email) => {
        GetUser(email).then((res) => {
          if (res.status === 200) {
            if (res.data.profileImage) {
              setProfileImage(serverUrl + res.data.profileImage);
            } else {
              setProfileImage(defaultProfile)
            }
          }
        });
      };

    const Logout = async () => {
        UserLogout(user).then((res) => {

        })
        await localStorage.clear();
        history.push('/home');
    };

    const connectWallet = async () => {
        try {
            const currProvider = window.ethereum;
            await currProvider.request({ method: "eth_requestAccounts" });
            const web3 = new Web3(currProvider);
            const accounts = await web3.eth.getAccounts();
            const balance = await web3.eth.getBalance(accounts[0]);
            let bal = web3.utils.fromWei(balance.toString(),'ether')
            setWalletDetail({'accounts':accounts[0], 'balance': bal})
            localStorage.setItem("wallet_connect", JSON.stringify({'accounts':accounts[0], 'balance': bal}))
            window.dispatchEvent(new Event("storage"));
        } catch (error) {
            console.log(error, "COnnect wallet error")
        }
    };
    useEffect(() => {
        getUserData(user.email);
    }, [])

    useEffect(() => {
        let wallet_connect = localStorage.getItem('wallet_connect') ? JSON.parse(localStorage.getItem('wallet_connect')) : ''
        if(wallet_connect){
            setWalletDetail(JSON.parse(localStorage.getItem('wallet_connect')))
        }
    }, [])

    

    return (
        <>
            <div className="created-nft-header d-flex justify-content-between align-items-center">
                <div className="created-nft-header-input">
                    &nbsp;
                </div>
                <div className="created-nft-header-subscription d-flex align-items-center">

                    <Dropdown className="dropdown">
                        <Dropdown.Toggle className="dropdown-toggle btn-white d-flex align-items-center justify-content-center" id="dropdown-basic">
                            <h4 className="mb-0">Current Plan: {userCurrentPlan[0]?.packageTitle}</h4>
                        </Dropdown.Toggle>
                    </Dropdown>
                    {walletDetail?.accounts ?
                        <Button className="address btn-theme-two">{walletDetail?.accounts}</Button> :
                        <Button className="btn-theme-two" onClick={connectWallet}>Connect MetaMask</Button>
                    }
                    <Dropdown className="dropdown profile-dropdown">
                        <Dropdown.Toggle className="dropdown-toggle d-flex align-items-center justify-content-center" id="dropdown-basic">
                            <div className="emoji-icon">
                                <Image src={profileImage} className="d-flex align-items-center justify-content-center" />
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => history.push('/profile')}>Profile</Dropdown.Item>
                            <Dropdown.Item onClick={() => history.push('/mywallet')}>My Wallet</Dropdown.Item>
                            <Dropdown.Item onClick={Logout}>Log out</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="created-nft-header-input mobile-header-input">
                    <Form.Control type="text" placeholder="Search here" />
                    <FontAwesomeIcon icon={faSearch} />
                </div>
            </div>

        </>
    )

}

export default UserDasboardHead;