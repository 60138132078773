
const TextFieldLabel = (props) => {
  const { field, widthclass, required, values, heading } = props;

  return (
    <>
      <div className={widthclass}>
        <label>
          {field}{" "}
          {heading === "Collections" ? values["collectionSymbol"] : null}{" "}
          {required ? <span className="text-danger">*</span> : null}
        </label>
      </div>
    </>
  );
};
export default TextFieldLabel;
