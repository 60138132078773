import * as Yup from "yup";
export const getInitialValues = (data) => {
    return {
        mainHead: data.mainHead,
        subHead: data.subHead,
        description: data.description,
        videoLink: data.videoLink,
        type: data.type,
    };
};

export const AboutFormFields = [
    {
        field: "Main Heading",
        fieldName: "mainHead",
        place: "",
        type: "text",
        required: true,
        field1: "Sub Heading",
        fieldName1: "subHead",
        place1: "",
        type1: "text",
        required1: true,
    },
    {
        field: "Description",
        fieldName: "description",
        place: "",
        type: "description",
        required: true,
    },
    {
        field: "Video Link",
        fieldName: "videoLink",
        place: "",
        type: "text",
        required: true,
    }
];

export const AboutSchema = Yup.object().shape({
    mainHead: Yup.string().trim('Required').required('Required'),
    subHead: Yup.string().trim('Required').required('Required'),
    description: Yup.string().trim('Required').required('Required'),
    videoLink: Yup.string().trim('Required').required('Required'),
});
