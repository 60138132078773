const TableHeader = (props) => {
  const { orderBy, onRequestSort, Columns } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <thead
      colSpan="5"
      className="text-primary text-capitalize font-size-sm font-weight-bold border-top thead-wrapper"
    >
      <tr>
        {Columns.map((headCell, index) => (
          <th
            id={"table-header" + index}
            key={headCell.label}
            className={headCell.class}
          >
            {headCell.sort ? (
              <div
                id={"sort-table" + index}
                active={orderBy === headCell.id}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <span>{headCell.label}</span>
                </div>
              </>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};
export default TableHeader;
