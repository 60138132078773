
export const NetworksTableHeadings = [
  {
    id: "name",
    label: "Name",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "chainId",
    label: "Chain Id",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "currencySymbol",
    label: "Currency Symbol",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light",
  },
];

export const createRows = (rowData) => {
  const rows = rowData.map((i) => {
    return {
      id: i._id,
      name: i.name,
      chainId: i.chainId,
      nativeCurrency: i.nativeCurrency,
    };
  });
  return rows;
};

export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    name: updatedRow.name ? updatedRow.name : "",
    chainId: updatedRow.chainId ? updatedRow.chainId : "",
    currencySymbol: updatedRow.currencySymbol ? updatedRow.currencySymbol : "",
  };
  return Values;
};
