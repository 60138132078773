import React from "react";
import { Image, Button } from "react-bootstrap";


const NftPreview = (props) => {
    return (
        <>
            <div className="nft-preview-content">
                <h3>NFT Preview</h3>
                <div className="created-nft-header-subscription-img padding-avatar d-flex align-items-center justify-content-center">
                    <Image src="images/related-cr-img.svg" fluid/>
                </div>
                <Button className="generate">Generate</Button>
            </div>

        </>
    )

}

export default NftPreview;