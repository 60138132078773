/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShoppingCart, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';
import { Nav, Navbar, Container, Button, Image } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { Link } from 'react-scroll'
import Web3 from "web3";

const Header = (props) => {

    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const [walletDetail, setWalletDetail] = useState('')

    window.addEventListener('storage', () => {
        let wallet_connect = localStorage.getItem('wallet_connect') ? JSON.parse(localStorage.getItem('wallet_connect')) : ''
        if (wallet_connect && walletDetail === '') {
            setWalletDetail(wallet_connect)
        }
    })

    const connectWallet = async () => {
        try {
            const currProvider = window.ethereum;
            await currProvider.request({ method: "eth_requestAccounts" });
            const web3 = new Web3(currProvider);
            const accounts = await web3.eth.getAccounts();
            const balance = await web3.eth.getBalance(accounts[0]);
            let bal = web3.utils.fromWei(balance.toString(), 'ether')
            localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': accounts[0], 'balance': bal }))
            window.dispatchEvent(new Event("storage"));
            setWalletDetail({ 'accounts': accounts[0], 'balance': bal })
        } catch (error) {
            console.log(error, "COnnect wallet error")
        }
    };

    useEffect(() => {
        let wallet_connect = localStorage.getItem('wallet_connect') ? JSON.parse(localStorage.getItem('wallet_connect')) : ''
        if (wallet_connect) {
            setWalletDetail(JSON.parse(localStorage.getItem('wallet_connect')))
        }
    }, [])

    return (
        <header className="header home-header">
            <>
                <Navbar expand="lg">
                    <Container className="container-width" fluid>
                        <NavLink to="/home">
                            <div className="logo header-logo">
                                <Image src="images/mutolab.png" fluid />
                            </div>
                        </NavLink>
                        <div className="navbar-toggler">
                            <Navbar.Toggle><FontAwesomeIcon icon={faBars} className="navbar-toggler-icon toggler-icon" /></Navbar.Toggle>
                        </div>
                        <Navbar.Collapse id="navbarScroll" className="header-list">
                            <Nav
                                className=" my-2 my-lg-0"
                            >
                                {window.location.pathname.includes('home') ?
                                    <Link activeClass="active" className="nav-link" spy={true} smooth={true} to="aboutUs">About Us</Link>
                                    : <NavLink className="nav-link" to="/home#aboutUs">About Us</NavLink>
                                }
                                {window.location.pathname.includes('home') ?
                                    <Link className="nav-link" spy={true} smooth={true} to="pricing">Pricing</Link>
                                    : <NavLink className="nav-link" to="/home#pricing">Pricing</NavLink>
                                }
                                {window.location.pathname.includes('home') ?
                                    <Link className="nav-link" spy={true} smooth={true} to="faq">FAQ</Link>
                                    : <NavLink className="nav-link" to="/home#faq">FAQ</NavLink>
                                }
                                <div class="dropdown">
                                    <button class="dropbtn">More</button>
                                    <div class="home-dropdown-content">
                                        {window.location.pathname.includes('home') ?
                                            <Link className="nav-link" spy={true} smooth={true} to="how">How it Works</Link>
                                            : <NavLink className="nav-link" to="/home#how">How it Works</NavLink>
                                        }
                                        {window.location.pathname.includes('home') ?
                                            <Link className="nav-link" spy={true} smooth={true} to="featured">Featured Projects</Link>
                                            : <NavLink className="nav-link" to="/home#featured">Featured Projects</NavLink>
                                        }
                                    </div>
                                </div>
                                <div className="mobile-drop">
                                    {window.location.pathname.includes('home') ?
                                        <Link className="nav-link" spy={true} smooth={true} to="how">How it Works</Link>
                                        : <NavLink className="nav-link" to="/home#how">How it Works</NavLink>
                                    }
                                    {window.location.pathname.includes('home') ?
                                        <Link className="nav-link" spy={true} smooth={true} to="featured">Featured Projects</Link>
                                        : <NavLink className="nav-link" to="/home#featured">Featured Projects</NavLink>
                                    }
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="header-buttons header-list">
                            <Nav.Link className="nav-link" href="https://mutoplace.metamuto.com/" target={'_blank'} rel="noreferrer">Marketplace</Nav.Link>

                                {token ? 
                                <a className="nav-link" href={() => false} onClick={() => window.location.href = "/dashboard"}>Dashboard</a> :
                                <NavLink className="nav-link" to="/login">Login</NavLink> 
                                }
                            {token ? null : <NavLink className="nav-link" to="/signup">Sign Up</NavLink>}


                            {
                                walletDetail?.accounts ? <Button className="btn-pink text-center"><span className='address'>{walletDetail?.accounts}</span></Button> :
                                    <Button className="btn-pink" onClick={connectWallet}>Connect MetaMask</Button>
                            }

                        </div>
                        <ul className="responsive-header header-buttons m-0 p-0">
                            <li className="nav-item">
                                <Nav.Link className='p-0' href="https://mutoplace.metamuto.com/" target={'_blank'} rel="noreferrer" ><FontAwesomeIcon icon={faShoppingCart} className="header-icons" /></Nav.Link>
                            </li>
                            <li className="nav-item">
                                <NavLink to={token ? "/dashboard" : "/login"}><FontAwesomeIcon icon={faUser} className="header-icons" /></NavLink>
                            </li>
                            <li className='nav-item'>
                                {
                                    walletDetail?.accounts ? <span className='address'>{walletDetail?.accounts}</span> :
                                        <Nav.Link onClick={connectWallet}><FontAwesomeIcon icon={faWallet} className="header-icons" /></Nav.Link>
                                }
                            </li>
                        </ul>
                    </Container>
                </Navbar>
            </>
        </header>
    )
}

export default Header;