import Web3 from "web3";

const TableRow = (props) => {
  const { row, contractData, index } = props;
  return (
    <>
      <tr key={row.id + index} id={"selected-collection-tr-" + index}>
        <td className="pl-3 ">
          <span>{row.name}</span>
        </td>
        <td className="pl-3 ">{row.symbol}</td>
        <td className="pl-3 ">{row.network}</td>
        <td className="pl-3 ">
          {contractData[0]
            ? Web3.utils.fromWei(contractData[0], "ether")
            : row.price}
        </td>
        <td className="pl-3 ">{contractData[2] ? contractData[2] : "0"}</td>
        <td className="pl-3 ">{row.totalSupply ? row.totalSupply : "0"}</td>
        <td className="pl-3 ">
        True{/*  {contractData[2] === false ? "False" : "True"} */}
        </td>
        <td className="pl-3 ">{contractData[3] ? contractData[3] : "0"}</td>
        <td className="pl-3 ">
          {row.contractAddress ? row.contractAddress : ""}
        </td>
      </tr>
    </>
  );
};
export default TableRow;
