import moment from "moment";

export const PaymentsTableHeadings = [
    {
        id: "txn_id",
        label: "Transaction Id",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "package",
        label: "Package",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "amount",
        label: "Amount",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "paidamount",
        label: "Paid Amount",
        sort: true,
        class: "bg-body-light",
    },

    {
        id: "paymentsource",
        label: "Payment Source ",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "type",
        label: "Type ",
        sort: true,
        class: "bg-body-light",
    },

    {
        id: "paymentdate",
        label: "Payment Date",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "status",
        label: "Status",
        sort: true,
        class: "bg-body-light",
    },
];

export const PaymentsInitialValues = {
    txn_id:"",
    package: "",
    amount: "",
    paidamount: '',
    paymentsource:'',
    paymentdate:'',
    status:''
};

export const createRows = (rowData) => {
        const rows = rowData.map((i) => {
            const paymentDate = moment(i.createdAt).format("YYYY-MM-DD");
            
            return {
                id: i._id,
                txn_id: i.payload.id,
                package: i.packageTitle,
                amount: Number(i?.price/100).toFixed(2),
                paidamount: Number(i?.price/100).toFixed(2),
                paymentsource: 'Stripe',
                type: i.plans[0].type,
                paymentdate: paymentDate,
                status: 'Paid'
            };
        });
        return rows;
    
};
