import React, { useState, useContext, useEffect } from 'react'
import GenericTable from "ReuseableComps/GenericTables";
import { UserListingTableHeadings, UserListingInitialValues, createRows } from "./UserListingHelpers";
import { ToasterContext } from "app";
import { GetAllUsers } from 'services/admin/Admin';
import TableRows from "./TableRows";
import { updateUserStatus } from 'services/admin/Admin';
import SkeltonTable from "ReuseableComps/GenericTablesSkelton"

const UserListing = () => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(true);
    const [tostify, setTostify] = useContext(ToasterContext);

    const updateUserStatusService = async (data, actions) => {
        tostify.dismissAll()
        updateUserStatus(data).then(res => {
            if (res.status === 200) {
                setData(res.data)
            }
            setTostify({
                ...tostify,
                visible: true,
                key: Math.random().toString(36).substring(7),
                type: res && res.status === 200 ? data.status === true ? 'warning' : "success" : "error",
                message: res.status === 200 ? data.status === true ? 'User has been deactivated' : "User has been activated" : res.message,
            });
        });
    }
    const userCollections = () => { }
    const userSubscriptions = () => { }
    const userPayment = () => { }

    const GetUsers = async () => {
        setLoading(true)
        GetAllUsers().then(res => {
            if (res.status === 200) {
                setData(res.data);
                setLoading(false);
            } else {
                setLoading(false);
                setTostify({
                    ...tostify,
                    visible: true,
                    key: Math.random().toString(36).substring(7),
                    type: res && res.status === 200 ? "success" : "error",
                    message: res.message,
                });
            }
        })
    }

    useEffect(() => {
        GetUsers()
        //eslint-disable-next-line
    }, [])

    return (
        <div className="">
            {loading ? <SkeltonTable header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} /> :
                <GenericTable
                    heading={"Users List"}
                    Columns={UserListingTableHeadings}
                    tostify={tostify}
                    createRows={createRows}
                    initialValues={UserListingInitialValues}
                    data={data}
                    TableRow={TableRows}
                    updateStatus={updateUserStatusService}
                    collectionBtn={userCollections}
                    subscriptionBtn={userSubscriptions}
                    paymentBtn={userPayment}
                />
            }
        </div>
    )
}

export default UserListing