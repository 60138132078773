import Actions from "ReuseableComps/Actions";
import Switch from "ReuseableComps/Switch";

const TableRow = (props) => {
  const {
    row,
    index,
    updateStatus,
    collectionBtn,
    subscriptionBtn,
    paymentBtn,
    heading
  } = props;
 

  return (
    <>
      <tr key={row.id + index} id={"monthly-tr-" + index}>
        <td className="text-left ">
          <span>{row.firstName}</span>
        </td>
        <td className="text-left ">
          <span>{row.lastName}</span>
        </td>
        <td className="text-left ">
          <span>{row.email}</span>
        </td>
        <td className="text-left ">
          <Switch status={row.status} id={row.id} updateStatus={updateStatus} heading={heading}/>
        </td>
        <td className="text-left ">
          <Actions
            row={row}
            collectionBtn={collectionBtn}
            subscriptionBtn={subscriptionBtn}
            paymentBtn={paymentBtn}
          />
        </td>
      </tr>
    </>
  );
};
export default TableRow;
