import * as Yup from "yup";
export const FaqsTableHeadings = [
  {
    id: "title",
    label: "Question",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light",
  },
];
export const createRows = (rowData) => {
  const rows = rowData.map((i) => {
    return {
      id: i._id,
      title: i.title,
      description: i.description,
    };
  });
  return rows;
};
export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    title: updatedRow.title ? updatedRow.title : "",
    description: updatedRow.description ? updatedRow.description : "",
  };
  return Values;
};
export const FaqInitialValues = {
  title: "",
  description: "",
};
export const FaqSchema = Yup.object().shape({
  title: Yup.string().trim('Required').required("Question is required!"),
  description: Yup.string().trim('Required').required("Answer is required!"),
});
export const FaqFormFields = [
  {
    field: "Question",
    fieldName: "title",
    place: "Enter Question",
    type: "text",
    required: true,
  },
  {
    field: "Answer",
    fieldName: "description",
    place: "Enter Answer",
    type: "description",
    required: true,
  },
];
