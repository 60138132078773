import { useEffect, useState, useContext } from "react";
import { GetNetworks } from "../../../services/admin/Networks";
import { ToasterContext } from "../../../app";
import {
  GalleryFormFields,
  GalleryInitialValues,
  GallerySchema,
  CollectionTableMiniHeadings,
} from "./helpers";
import GenericFormikForm from "ReuseableComps/GenericForms";
import axios from "axios";
import Web3 from "web3";
import C_Interface from "../../../utils/ABI.json";
import { getAuthHeader } from "config/utils"
import FullPageLoader from "ReuseableComps/FullPageLoader/full-page-loader";


let globleNetwork = ''
const Gallery = () => {
  const [networks, setNetworks] = useState([]);
  const [networkNames, setNetworkNames] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState();
  const [wallet, setWallet] = useState(null);
  const [networkName, setNetworkName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tostify, setTostify] = useContext(ToasterContext);
  // eslint-disable-next-line
  const [selectedColl, setSelectedCol] = useState(null);
  // eslint-disable-next-line
  const [selectedNet, setSelectedNet] = useState("");
  // eslint-disable-next-line
  const [ind, setInd] = useState(0);
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [contractAddress, setContractAddress] = useState("");
  // eslint-disable-next-line
  const [chainLoading, setChainLoading] = useState(false);
  // eslint-disable-next-line
  const [updatedCost, setUpdatedCost] = useState(0);
  // eslint-disable-next-line
  const [maxMintAmount, setMaxMintAmount] = useState(0);
  const [usersAll, setUsersAll] = useState();
  // eslint-disable-next-line
  const [refetch, setRefetch] = useState(false);



  const GetNetworksService = () => {
    setLoading(true);
    GetNetworks().then((res) => {
      if (res.status === 200) {
        setNetworks(res.data);
        let netName = res?.data.map(net => net.name)
        setNetworkNames(netName)
        globleNetwork = res.data[0].name
        setSelectedNetwork(res.data[0].name);
        setLoading(false);
      } else {
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
        setLoading(false);
      }
    });
  };
  const GetCollectionsService = async (net, setFieldValue, getuser) => {
    net = net === 'Select option' ? "" : net
    getuser = getuser === 'Select option' ? "" : getuser
    if (net) {
      const result = await axios.get(
        process.env.REACT_APP_BASE_URL + "/collections/" + net + "?id=" + getuser
      );
      if (result.status === 200) {
        return result;
      }
    }
  };

  const GetUsers = async (net = '') => {
    let header = await getAuthHeader();
    const result = await axios.get(
      process.env.REACT_APP_BASE_URL + "/admin/users",
      header
    );
    if (result.status === 200) {
      setUsersAll(result.data.data)
    }
  };

  useEffect(() => {
    GetNetworksService();
    GetUsers()
    // eslint-disable-next-line
  }, []);

  const connectWallet = async () => {
    if (!window.ethereum) {
      alert("Please install MetaMask!");
    } else {
      try {
        const currentProvider = window.ethereum;
        await currentProvider.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(currentProvider);
        const userAccout = await web3.eth.getAccounts();
        setWallet(userAccout[0]);
        localStorage.setItem("userWallet", userAccout[0]);
        const balance = await web3.eth.getBalance(userAccout[0]);
        let bal = web3.utils.fromWei(balance.toString(),'ether')
        localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': userAccout[0], 'balance': bal }))
        window.dispatchEvent(new Event("storage"));

      } catch (err) { }
    }
  };

  useEffect(() => {
    const getCollections = async () => {
      try {
        const result = await axios.get(
          process.env.REACT_APP_BASE_URL + "/collections/" + globleNetwork
        );
        if (result) {
          if (result.data.data.length > 0) {
            setSelected(result.data.data);
          }
          setLoading(false);
        }
      } catch (err) { }
    };

    connectWallet();
    getCollections();
    // eslint-disable-next-line
  }, []);
  const checkNetworkService = async (target, setFieldValue, values) => {
    globleNetwork = target;
    if (window.ethereum) {
      const currentProvider = window.ethereum;

      const distinationNetwork =
        networks.length > 0 ? networks.find((net) => net.name === target) : {};
      if (
        parseInt(currentProvider.chainId, 16) !== distinationNetwork.chainId
      ) {
        const result = await changeNetwork(
          values,
          distinationNetwork,
          setFieldValue
        );
        return result;
      }
    }
  };
  const changeNetwork = async (values, _selectedNetwork, setFieldValue) => {

    try {
      const params = {
        chainId: `0x${Number(_selectedNetwork.chainId).toString(16)}`, // A 0x-prefixed hexadecimal string
        chainName: _selectedNetwork.name,
        nativeCurrency: {
          name: _selectedNetwork.nativeCurrency.name,
          symbol: _selectedNetwork.nativeCurrency.symbol, // 2-6 characters long
          decimals: _selectedNetwork.nativeCurrency.decimals,
        },
        rpcUrls: _selectedNetwork.rpc,
        blockExplorerUrls: [
          _selectedNetwork.explorers &&
            _selectedNetwork.explorers.length > 0 &&
            _selectedNetwork.explorers[0].url
            ? _selectedNetwork.explorers[0].url
            : _selectedNetwork.infoURL,
        ],
      };
      if (!window.ethereum) throw new Error("No crypto wallet found");
      if (networkNames.includes(_selectedNetwork.name)) {
        const currentProvider = window.ethereum;
        await currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [
            { chainId: `0x${Number(_selectedNetwork.chainId).toString(16)}` },
          ],
        });
        if(values['collections'].length > 0){
          let currentColl = values['collections'].filter(col => col.name === values['collection']);
          fetchDataFromAddress(currentColl[0].contractAddress, setFieldValue)
      }
        const web3 = new Web3(currentProvider);
        const userAccout = await web3.eth.getAccounts();
        const balance = await web3.eth.getBalance(userAccout[0]);
        let bal = web3.utils.fromWei(balance.toString(),'ether')
        localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': userAccout[0], 'balance': bal }))
        window.dispatchEvent(new Event("storage"));
        return currentProvider;

      } else {
        const currentProvider = window.ethereum;
        await currentProvider.request({
          method: "wallet_addEthereumChain",
          params: [params, wallet],
        });
        const web3 = new Web3(currentProvider);
        const userAccout = await web3.eth.getAccounts();
        const balance = await web3.eth.getBalance(userAccout[0]);
        let bal = web3.utils.fromWei(balance.toString(),'ether')
        localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': userAccout[0], 'balance': bal }))
        window.dispatchEvent(new Event("storage"));
      }
    } catch (err) {
      // setMsg('');
    }
  };
  useEffect(() => {
    const checkNetwork = async () => {
      if (window.ethereum) {
        const currentProvider = window.ethereum;
        const distinationNetwork =
          networks.length > 0
            ? networks.find((net) => net.name === globleNetwork)
            : {};
        if (
          parseInt(currentProvider.chainId, 16) !== distinationNetwork.chainId
        ) {
          await changeNetwork(distinationNetwork);
        }
      }
    };
    axios
      .get(process.env.REACT_APP_BASE_URL + "/collections/" + globleNetwork)
      .then((result) => {
        if (networks.length > 0 && result.data.data.length > 0) {
          checkNetwork();
          setSelected(result.data.data);
        }
      });
    // eslint-disable-next-line
  }, [globleNetwork]);
  useEffect(() => {
    const currentCollection =
      selected.length > 0 ? selected[ind] : { contractAddress: "" };

    setSelectedCol(currentCollection);
    setSelectedNet(
      networks
        ? networks.find((net) => net.name === currentCollection.network)
        : {}
    );
    const ContractAddress = currentCollection.contractAddress
      ? currentCollection.contractAddress
      : "";
    if (ContractAddress !== "") {
      setContractAddress(ContractAddress);
    }
    // eslint-disable-next-line
  }, [ind]);
  const fetchDataFromAddress = async (_address, setFieldValue) => {
    try {
      if (window.ethereum) {
        const currentProvider = window.ethereum;
        await currentProvider.request({ method: "eth_requestAccounts" });
        const web3 = new Web3(currentProvider);
        const userAccout = await web3.eth.getAccounts();
        const NFTContract = new web3.eth.Contract(C_Interface.abi, _address);
        const promisArray = [
          NFTContract.methods.cost().call({ from: userAccout[0] }),
          NFTContract.methods.owner().call({ from: userAccout[0] }),
          NFTContract.methods.totalSupply().call({ from: userAccout[0] }),
          NFTContract.methods
            .balanceOf(userAccout[0])
            .call({ from: userAccout[0] }),
          web3.eth.getBalance(_address),
        ];
        const ContractData = await Promise.all(promisArray);
        setFieldValue("contractData", ContractData);
      }
    } catch (err) { }
  };
  useEffect(() => {
    const fetchData = async (_address) => {
      try {
        setChainLoading(true);
        if (window.ethereum) {
          const currentProvider = window.ethereum;
          await currentProvider.request({ method: "eth_requestAccounts" });
          const web3 = new Web3(currentProvider);
          const userAccout = await web3.eth.getAccounts();
          setWallet(userAccout[0]);
          const NFTContract = new web3.eth.Contract(C_Interface.abi, _address);
          const promisArray = [
            NFTContract.methods.cost().call({ from: userAccout[0] }),
            NFTContract.methods.owner().call({ from: userAccout[0] }),
            NFTContract.methods.totalSupply().call({ from: userAccout[0] }),
            NFTContract.methods
              .balanceOf(userAccout[0])
              .call({ from: userAccout[0] }),
            web3.eth.getBalance(_address),
          ];
          const ContractData = await Promise.all(promisArray);

          setData(ContractData);
          setChainLoading(false);
        }
      } catch (err) {
        setChainLoading(false);
      }
    };
    fetchData(contractAddress);
  }, [contractAddress, refetch]);

  return (
    <>
      <div className="content">
        {loading && usersAll?.length < 1 ? <FullPageLoader/> : (
          <GenericFormikForm
            key={"Live Collections" + Math.random().toString(36).substring(7)}
            heading={"Live Collections"}
            data={[]}
            initialValues={GalleryInitialValues}
            schema={GallerySchema}
            formFields={GalleryFormFields}
            networks={networks}
            getCollectionByNetwork={GetCollectionsService}
            tostify={tostify}
            setTostify={setTostify}
            setSelectedNetwork={setSelectedNetwork}
            selectedNetwork={selectedNetwork}
            TableMiniHeadings={CollectionTableMiniHeadings}
            fetchData={fetchDataFromAddress}
            checkNetwork={checkNetworkService}
            setNetworkName={setNetworkName}
            networkName={networkName}
            getCollectionByUser={GetUsers}
            usersAll={usersAll}
          />
        )}
      </div>
    </>
  );
};
export default Gallery;
