import Actions from "ReuseableComps/Actions";
import Switch from "ReuseableComps/Switch";

const TableRow = (props) => {
  const {
    row,
    index,
    updateStatus,
    handleOpen,
    setUpdatedRow,
    setActionState,
    deleteRow,
  } = props;
 

  return (
    <>
      <tr key={row.id + index} id={"monthly-tr-" + index}>
        <td className="text-left ">
          <span>{row.title}</span>
        </td>
        <td className="text-left ">
          <span>{row.subHead}</span>
        </td>
        <td className="text-left ">
          <span>{row.maxGenerateCollection}</span>
        </td>
        <td className="text-left ">
          <span>{row.maxGenerateNfts}</span>
        </td>
        <td className="text-left ">
          <span>{`$${row.price / 100}`}</span>
        </td>
        <td className="text-left ">
          <span>{row.type}</span>
        </td>
        <td className="text-left ">
          <Switch status={row.status} id={row.id} updateStatus={updateStatus} />
        </td>
        <td className="text-left ">
          <Actions
            handleOpen={handleOpen}
            setUpdatedRow={setUpdatedRow}
            setActionState={setActionState}
            row={row}
            deleteRow={deleteRow}
          />
        </td>
      </tr>
    </>
  );
};
export default TableRow;
