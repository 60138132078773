import { ConnectedFocusError } from "focus-formik-error";
import TextFieldGeneric from "ReuseableComps/TextFieldGeneric";
import { Tab, Form } from "react-bootstrap";
import FormikFormFooter from "ReuseableComps/FormFooter";
const FormikFieldDoubleUser = (props) => {
  const {
    touched,
    errors,
    isSubmitting,
    values,
    handleChange,
    handleSubmit,
    heading,
    formFields,
  } = props;
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="third">
        <Tab.Content>
          <Tab.Pane eventKey="third">
            <Form className="login-form">
              <ConnectedFocusError />
              {formFields.map((item, index) => {
                return (
                    <div key={index} className="d-flex flex-lg-row flex-md-row flex-sm-row flex-column justify-content-between">
                      <TextFieldGeneric
                        touched={touched}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        fieldName={item.fieldName}
                        place={item.place}
                        field={item.field}
                        type={item.type}
                        heading={heading}
                        required={item.required}
                        widthClass={"w-45"}
                      />
                      {item && item.fieldName1 ? (
                        <TextFieldGeneric
                          touched={touched}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          fieldName={item.fieldName1}
                          place={item.place1}
                          field={item.field1}
                          type={item.type1}
                          heading={heading}
                          required={item.required1}
                          widthClass={"w-45"}
                        />
                      ) : null}
                    </div>
                );
              })}
              <FormikFormFooter
                isSubmitting={isSubmitting}
                heading={heading}
                handleSubmit={handleSubmit}
                values={values}
                footerClass={"card-footer-user"}
              />
            </Form>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
export default FormikFieldDoubleUser;
