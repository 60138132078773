/*eslint-disable*/
import { useEffect, useState, useContext } from "react";
import { GetNetworks } from "../../../services/admin/Networks";
import { ToasterContext } from "../../../app";
import {
    GalleryFormFields,
    GalleryInitialValues,
    GallerySchema,
    CollectionTableMiniHeadings,
} from "./helpers";
import GenericFormikForm from "ReuseableComps/GenericForms";
import axios from "axios";
import Web3 from "web3";
import C_Interface from "../../../utils/ABI.json";
import { toast } from "react-toastify";
import FullPageLoader from "ReuseableComps/FullPageLoader/full-page-loader";

let globleNetworkVar;

const MyCollections = (props) => {
    const [networks, setNetworks] = useState([]);
    const [networkNames, setNetworkNames] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState();
    const [wallet, setWallet] = useState(null);
    const [networkName, setNetworkName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tostify, setTostify] = useContext(ToasterContext);
    const [selectedColl, setSelectedCol] = useState(null);
    const [selectedNet, setSelectedNet] = useState("");
    const [ind, setInd] = useState(null);
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [contractAddress, setContractAddress] = useState("");
    const [chainLoading, setChainLoading] = useState(false);
    const [updatedCost, setUpdatedCost] = useState(0);
    const [maxMintAmount, setMaxMintAmount] = useState(0);
    const [refetch, setRefetch] = useState(false);

    const GetNetworksService = () => {
        setLoading(true);
        GetNetworks().then((res) => {
            if (res.status === 200) {
                setNetworks(res.data);
                let netName = res?.data.map(net => net.name)
                setNetworkNames(netName)
                setSelectedNetwork(res.data[0]);
                setLoading(false);
            } else {
                setTostify({
                    ...tostify,
                    visible: true,
                    key: Math.random().toString(36).substring(7),
                    type: res && res.status === 200 ? "success" : "error",
                    message: res.message,
                });
                setLoading(false);
            }
        });
    };
    const GetCollectionsService = async (net) => {

        let getUserStorage = localStorage.getItem("user");
        if (getUserStorage) {
            getUserStorage = JSON.parse(localStorage.getItem("user"))
        }

        let userId
        if (getUserStorage?.id) {
            userId = getUserStorage.id
        } else if (getUserStorage?._id) {
            userId = getUserStorage._id
        }

        if (net) {
            const result = await axios.get(
                process.env.REACT_APP_BASE_URL + "/collections/" + net,
                { params: { id: userId } }
            );
            if (result.status === 200) {
                return result;
            }
        }
    };
    useEffect(() => {
        GetNetworksService();
    }, []);

    const connectWallet = async () => {
        if (!window.ethereum) {
            alert("Please install MetaMask!");
        } else {
            try {
                const currentProvider = window.ethereum;
                await currentProvider.request({ method: "eth_requestAccounts" });
                const web3 = new Web3(currentProvider);
                const userAccout = await web3.eth.getAccounts();
                const balance = await web3.eth.getBalance(userAccout[0]);
                let bal = web3.utils.fromWei(balance.toString(), 'ether')
                setWallet(userAccout[0]);
                localStorage.setItem("userWallet", userAccout[0]);
                localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': userAccout[0], 'balance': bal }))
                window.dispatchEvent(new Event("storage"));
            } catch (err) { }
        }
    };

    useEffect(() => {
        const getCollections = async () => {
            try {
                const result = await axios.get(
                    process.env.REACT_APP_BASE_URL + "/collections/" + networkName
                );
                if (result) {
                    if (result.data.data.length > 0) {
                        setSelected(result.data.data);
                    }
                    setLoading(false);
                }
            } catch (err) { }
        };
        connectWallet();
        getCollections();
    }, []);

    const checkNetworkService = async (target, setFieldValue, values) => {
        if (window.ethereum) {
            const currentProvider = window.ethereum;
            const distinationNetwork =
            networks.length > 0 ? networks.find((net) => net.name === target) : {};
            globleNetworkVar = distinationNetwork
            if (
                parseInt(currentProvider.chainId, 16) !== distinationNetwork.chainId
            ) {
                const result = await changeNetwork(
                    values,
                    distinationNetwork,
                    setFieldValue
                );
                return result;
            }
        }
    };

    const changeNetwork = async (values, _selectedNetwork, setFieldValue) => {
        try {
            const params = {
                chainId: `0x${Number(_selectedNetwork.chainId).toString(16)}`, // A 0x-prefixed hexadecimal string
                chainName: _selectedNetwork.name,
                nativeCurrency: {
                    name: _selectedNetwork.nativeCurrency.name,
                    symbol: _selectedNetwork.nativeCurrency.symbol, // 2-6 characters long
                    decimals: _selectedNetwork.nativeCurrency.decimals,
                },
                rpcUrls: _selectedNetwork.rpc,
                blockExplorerUrls: [
                    _selectedNetwork.explorers &&
                        _selectedNetwork.explorers.length > 0 &&
                        _selectedNetwork.explorers[0].url
                        ? _selectedNetwork.explorers[0].url
                        : _selectedNetwork.infoURL,
                ],
            };
            if (!window.ethereum) throw new Error("No crypto wallet found");
            if (networkNames.includes(_selectedNetwork.name)) {
                const currentProvider = window.ethereum;
                await currentProvider.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                        { chainId: `0x${Number(_selectedNetwork.chainId).toString(16)}` },
                    ],
                });
                if(values['collections'].length > 0){
                    let currentColl = values['collections'].filter(col => col.name === values['collection']);
                    fetchDataFromAddress(currentColl[0].contractAddress, setFieldValue)
                }
                const web3 = new Web3(currentProvider);
                const userAccout = await web3.eth.getAccounts();
                const balance = await web3.eth.getBalance(userAccout[0]);
                let bal = web3.utils.fromWei(balance.toString(), 'ether')
                localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': userAccout[0], 'balance': bal }))
                window.dispatchEvent(new Event("storage"));
                return currentProvider;
            } else {
                const currentProvider = window.ethereum;
                await currentProvider.request({
                    method: "wallet_addEthereumChain",
                    params: [params, wallet],
                });
                const web3 = new Web3(currentProvider);
                const userAccout = await web3.eth.getAccounts();
                const balance = await web3.eth.getBalance(userAccout[0]);
                let bal = web3.utils.fromWei(balance.toString(), 'ether')
                localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': userAccout[0], 'balance': bal }))
                window.dispatchEvent(new Event("storage"));
            }
        } catch (err) {
        }
    };

    useEffect(() => {
        const checkNetwork = async () => {
            if (window.ethereum) {
                const currentProvider = window.ethereum;
                const distinationNetwork =
                    networks.length > 0
                        ? networks.find((net) => net.name === networkName)
                        : {};
                if (
                    parseInt(currentProvider.chainId, 16) !== distinationNetwork.chainId
                ) {
                    await changeNetwork(distinationNetwork);
                }
            }
        };
        axios
            .get(process.env.REACT_APP_BASE_URL + "/collections/" + networkName)
            .then((result) => {
                if (networks.length > 0 && result.data.data.length > 0) {
                    checkNetwork();
                    setSelected(result.data.data);
                }
            });
    }, [networkName]);

    useEffect(() => {
        const currentCollection =
            selected.length > 0 ? selected[ind] : { contractAddress: "" };

        setSelectedCol(currentCollection);
        setSelectedNet(
            networks
                ? networks.find((net) => net.name === currentCollection.network)
                : {}
        );
        const ContractAddress = currentCollection.contractAddress
            ? currentCollection.contractAddress
            : "";
        if (ContractAddress !== "") {
            setContractAddress(ContractAddress);
        }
    }, [ind]);

    const fetchDataFromAddress = async (_address, setFieldValue) => {
        try {
            if (window.ethereum) {
                const currentProvider = window.ethereum;
                await currentProvider.request({ method: "eth_requestAccounts" });
                const web3 = new Web3(currentProvider);
                const userAccout = await web3.eth.getAccounts();
                const NFTContract = new web3.eth.Contract(C_Interface.abi, _address);
                const promisArray = [
                    NFTContract.methods.cost().call({ from: userAccout[0] }),
                    NFTContract.methods.owner().call({ from: userAccout[0] }),
                    NFTContract.methods.totalSupply().call({ from: userAccout[0] }),
                    NFTContract.methods
                        .balanceOf(userAccout[0])
                        .call({ from: userAccout[0] }),
                    web3.eth.getBalance(_address),
                ];
                const ContractData = await Promise.all(promisArray);
                setFieldValue("contractData", ContractData);
            } else {}
        } catch (err) { console.log('error', err) }
    };

    useEffect(() => {
        const fetchData = async (_address) => {
            try {
                setChainLoading(true);
                if (window.ethereum) {
                    const currentProvider = window.ethereum;
                    await currentProvider.request({ method: "eth_requestAccounts" });
                    const web3 = new Web3(currentProvider);
                    const userAccout = await web3.eth.getAccounts();
                    setWallet(userAccout[0]);
                    const NFTContract = new web3.eth.Contract(C_Interface.abi, _address);
                    const promisArray = [
                        NFTContract.methods.cost().call({ from: userAccout[0] }),
                        NFTContract.methods.owner().call({ from: userAccout[0] }),
                        // NFTContract.methods.revealed().call({ from: userAccout[0] }),
                        NFTContract.methods.totalSupply().call({ from: userAccout[0] }),
                        NFTContract.methods
                            .balanceOf(userAccout[0])
                            .call({ from: userAccout[0] }),
                        web3.eth.getBalance(_address),
                    ];
                    const ContractData = await Promise.all(promisArray);
                    setData(ContractData);
                    setChainLoading(false);
                }
            } catch (err) {
                setChainLoading(false);
            }
        };

        if (localStorage.getItem("userWallet")) {
            fetchData(contractAddress);
        }
    }, [contractAddress, refetch]);

    const handleMint = async (data, actions) => {
        // console.log("🚀 ~ file: index.js:314 ~ handleMint ~ globleNetworkVar", globleNetworkVar)
        // actions.setSubmitting(false);
        if (data.selectedCollection.length === 0) {
            toast.warning('Please select a collection!', {
                position: "top-right",
                autoClose: 3000,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
            });
            actions.setSubmitting(false);
        } else {
            let remainingMint = data.selectedCollection[0].totalSupply - data.contractData[3]
            let mintAmt = data.mintAmount;

            if (mintAmt > remainingMint) {

                toast.error('Mint supplying more than the availability!', {
                    position: "top-right",
                    autoClose: 3000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    progress: undefined
                });

                actions.setSubmitting(false);
                return;
            }

            const { mintAmount, contractData } = data;
            const contractAddress = data.selectedCollection[0].contractAddress;

            try {
                if (window.ethereum) {
                    const currentProvider = window.ethereum;
                    await currentProvider.request({ method: "eth_requestAccounts" });
                    if (parseInt(currentProvider.chainId, 16) !== globleNetworkVar.chainId) {
                        await window.ethereum.request({
                            method: "wallet_switchEthereumChain",
                            params: [
                                { chainId: `0x${Number(globleNetworkVar.chainId).toString(16)}` },
                            ],
                        });
                    }
                    // const currentProvider = window.ethereum;
                    // await currentProvider.request({ method: "eth_requestAccounts" });
                    const web3 = new Web3(currentProvider);
                    const userAccout = await web3.eth.getAccounts();
                    const cost = contractData[0] * mintAmount;
                    const NFTContract = new web3.eth.Contract(
                        C_Interface.abi,
                        contractAddress
                    );

                    const tx = await NFTContract.methods
                        .mint(mintAmount)
                        .send({ from: userAccout[0], value: cost });
                    if (tx) {
                        fetchDataFromAddress(contractAddress, actions.setFieldValue);

                        const currentProvider = window.ethereum;
                        await currentProvider.request({ method: "eth_requestAccounts" });
                        const web3 = new Web3(currentProvider);
                        const userAccout = await web3.eth.getAccounts();
                        const balance = await web3.eth.getBalance(userAccout[0]);
                        let bal = web3.utils.fromWei(balance.toString(), 'ether')
                        localStorage.setItem("wallet_connect", JSON.stringify({ 'accounts': userAccout[0], 'balance': bal }))
                        window.dispatchEvent(new Event("storage"));

                        toast.success('Mint successfully', {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                            progress: undefined
                        });

                        actions.setSubmitting(false);
                        actions.setFieldValue('mintAmount', '')

                        actions.setFieldValue('mintAmount', '')
                        setTimeout(() => {
                            var elements = document.getElementsByClassName('has-danger has-label');
                            while (elements.length > 0) {
                                elements[0].classList.remove('has-danger');
                            }
                            var social_share = document.querySelector('.text-warning');
                            social_share.innerHTML = '';

                        }, 100);

                    }
                } else {
                    alert("Please Install MetaMask!");
                }
            } catch (err) {
                actions.setSubmitting(false);

            }
        };
    };

    return (
        <>
            {loading ?
                <div className="d-flex align-items-center flex-column justify-content-center text-center py-3">
                    <div className="d-flex align-items-center flex-column px-4">
                        <FullPageLoader />
                    </div>
                    <div className="text-muted font-size-xl text-center pt-3">
                        Please wait
                    </div>
                </div>
                : (
                    <GenericFormikForm
                        key={"Live Collections" + Math.random().toString(36).substring(7)}
                        heading={"Live Collections"}
                        data={[]}
                        initialValues={GalleryInitialValues}
                        schema={GallerySchema}
                        formFields={GalleryFormFields}
                        networks={networks}
                        getCollectionByNetwork={GetCollectionsService}
                        tostify={tostify}
                        setTostify={setTostify}
                        setSelectedNetwork={setSelectedNetwork}
                        selectedNetwork={selectedNetwork}
                        TableMiniHeadings={CollectionTableMiniHeadings}
                        fetchData={fetchDataFromAddress}
                        checkNetwork={checkNetworkService}
                        handleMint={handleMint}
                        role='user'
                    />
                )}

        </>
    )

}

export default MyCollections;