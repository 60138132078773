import React from "react";
import { TableContainer } from "@material-ui/core";
import TableHeader from "./TableHeader";
import { CardBody, Table, Row, Col, CardHeader } from "reactstrap";
import Skeleton from "@material-ui/lab/Skeleton";
const SkeletonTable = (props) => {
  let { role } = props
  const length = ["0", "1", "2", "3"];
  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <div className={`${role === "user" ? 'card-user card role-user' : 'card-user card'}`}>
              <div className="card-body">
                {role === 'user' ? '' : <div className="author">
                </div>}
                <CardHeader className="d-flex justify-content-between">
                  <Skeleton
                    animation="wave"
                    height={66}
                    width="20%"
                    style={{ marginTop: "-7px" }}
                  />

                  <Skeleton
                    animation="wave"
                    height={66}
                    width="10%"
                    style={{ marginTop: "-7px" }}
                  />
                </CardHeader>
                <CardBody>
                  <TableContainer>
                    <Table className="tablesorter table">
                      <TableHeader />
                      <tbody>
                        {length.map((row, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="px-4 text-center font-size-xs w-100"
                                colSpan="6"
                              >
                                <div className="my-xl-n3">
                                  <Skeleton
                                    animation="wave"
                                    height={66}
                                    width="100%"
                                    style={{ marginTop: "-7px" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </TableContainer>
                  <div className="divider" />
                  <div className="divider" />
                  <div className="card-footer px-4 py-0 d-flex justify-content-center">
                    <Skeleton
                      animation="wave"
                      height={66}
                      width="30%"
                      style={{ marginTop: "-7px" }}
                    />
                  </div>
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default SkeletonTable;
