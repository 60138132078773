import { useEffect, useState } from "react";
import GenericTable from "ReuseableComps/GenericTables";
import SkeltonTable from "ReuseableComps/GenericTablesSkelton";
import Swal from "sweetalert2";
import {
    CategoriesTableHeadings,
    createRows,
    CategoryFormFields,
    setInitialValues,
    CategoryInitialValues,
    CategorySchema,
} from "./helpers";
import TableRows from "./TableRows";
import {
    GetCategories,
    AddCategory,
    updateCategory,
    DeleteCategory,
} from "../../../services/admin/Categories";
import { toast } from "react-toastify";

const Categories = () => {
    const [dataComp, setCompData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [updatedRow, setUpdatedRow] = useState({});
    const [actionState, setActionState] = useState("new");
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setActionState("new");
        setUpdatedRow({});
    };

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-simple text-success",
            cancelButton: "btn btn-simple text-danger",
        },
        buttonsStyling: false,
    });

    const getCategoriesData = () => {
        setLoading(true);
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
        let userId = user.id ? user.id : user._id
        GetCategories({ type: "", userId: userId }).then((res) => {
            if (res.status === 200) {
                setCompData(res.data);
                setLoading(false);
            } else {
                setLoading(false);
                toast(res.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    type: res && res.status === 200 ? "success" : "error",
                })
            }
        });
    };
    const AddCategoryService = async (data, actions) => {
        let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
        let userId = user.id ? user.id : user._id
        data = { ...data, userId: userId, type: "user" }
        AddCategory(data).then((res) => {
            if (res.status === 200) {
                setCompData([res?.data, ...dataComp]);
                handleClose()
            }
            actions.setSubmitting(false);
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                type: res && res.status === 200 ? "success" : "error",
            })
        });
    };

    const updateCategoryService = async (data, actions) => {
        updateCategory(data).then((res) => {
            if (res.status === 200) {
                let updated = dataComp.map(dc => dc._id === res?.data._id ? dc = res?.data : dc)
                setCompData(updated);
                handleClose()
            }
            actions.setSubmitting(false);
            toast(res.message, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                type: res && res.status === 200 ? "success" : "error",
            })
        });
    };

    const DeleteCategoryService = async (data, actions) => {
        swalWithBootstrapButtons
            .fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
                background: "#27293d",
            })
            .then((result) => {
                if (result.isConfirmed) {
                    DeleteCategory(data).then((res) => {
                        if (res.status === 200) {
                            swalWithBootstrapButtons.fire({
                                title: "Deleted!",
                                text: res.message,
                                icon: "success",
                                background: "#27293d",
                                timer: 1000,
                            });
                            let updated = dataComp.filter(dc => dc._id !== res?.data._id)
                            setCompData(updated);
                        } else {
                            swalWithBootstrapButtons.fire({
                                title: "",
                                text: res.message,
                                icon: "warning",
                                background: "#27293d",
                                timer: 1000,
                            });
                        }
                    });
                } else if (
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire({
                        title: "Cancelled",
                        text: "",
                        icon: "error",
                        background: "#27293d",
                        timer: 1000,
                    });
                }
            });
    };

    useEffect(() => {
        getCategoriesData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {loading ? (
                <SkeltonTable header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} />
            ) : (
                <GenericTable
                    isModal={true}
                    modalHeading={"Category"}
                    heading={"Categories"}
                    actionState={actionState}
                    open={open}
                    updatedRow={updatedRow}
                    setUpdatedRow={setUpdatedRow}
                    setActionState={setActionState}
                    //Handlers
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    Columns={CategoriesTableHeadings}
                    searchPlaceHolder={"Seacrh Categories"}
                    data={dataComp}
                    TableRow={TableRows}
                    //Helpers States &  Function
                    createRows={createRows}
                    initialValues={CategoryInitialValues}
                    schema={CategorySchema}
                    setInitialValues={setInitialValues}
                    formFields={CategoryFormFields}
                    //SERVICES
                    add={AddCategoryService}
                    update={updateCategoryService}
                    deleteRow={DeleteCategoryService}
                />
            )}
        </>
    )
}

export default Categories