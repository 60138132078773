import { useEffect, useState, useContext } from "react";
import GenericTable from "ReuseableComps/GenericTables";
import {
  FaqsTableHeadings,
  createRows,
  setInitialValues,
  FaqInitialValues,
  FaqSchema,
  FaqFormFields,
} from "./helpers";
import TableRows from "./TableRows";
import SkeltonTable from "ReuseableComps/GenericTablesSkelton";
import {
  GetCMSDataByType,
  AddCMSFaqLisitng,
  UpdateCMSFaqLisitngStatus,
  DeleteCMSFaqListing,
  UpdateCMSFaqLisitng,
} from "../../../../../services/admin/CMS";
import { ToasterContext } from "../../../../../app";
import Swal from "sweetalert2";

const FaqsList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [reset, setReset] = useState(false);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [updatedRow, setUpdatedRow] = useState({});
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
    setUpdatedRow({});
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-simple text-success",
      cancelButton: "btn btn-simple text-danger",
    },
    buttonsStyling: false,
  });

  const GetCMSDataService = () => {
    setLoading(true);
    GetCMSDataByType("faqListing").then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setLoading(false);
        setReset(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const AddCMSFaqService = async (data, actions) => {
    AddCMSFaqLisitng(data, "faqListing").then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setReset(true);
        handleClose()
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  const UpdateCMSFaqservice = async (data, actions) => {
    UpdateCMSFaqLisitng(data).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        handleClose()
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  const UpdateCMSFaqStatuservice = async (data, actions) => {
    UpdateCMSFaqLisitngStatus(data).then((res) => {
      if (res.status === 200) {
        // setData(res.data);
      }
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };

  const DeleteCMSFaqLisitngervice = async (data, actions) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        background: "#27293d",
      })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteCMSFaqListing(data).then((res) => {
            if (res.status === 200) {
              swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: res.message,
                icon: "success",
                background: "#27293d",
                timer: 1000,
              });
              setData(res.data);
            }else{
              setTostify({
                ...tostify,
                visible: true,
                key: Math.random().toString(36).substring(7),
                type: res && res.status === 200 ? "success" : "error",
                message: res.message,
              });
            }
          });
        }
      })

  };

  useEffect(() => {
    GetCMSDataService();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="content">
      {loading ? (
        <SkeltonTable header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} />
      ) : (
        <GenericTable
          isModal={true}
          modalHeading={"FAQ List"}
          heading={"FAQ Listing"}
          actionState={actionState}
          open={open}
          updatedRow={updatedRow}
          setUpdatedRow={setUpdatedRow}
          setActionState={setActionState}
          //Handlers
          handleOpen={handleOpen}
          handleClose={handleClose}
          Columns={FaqsTableHeadings}
          searchPlaceHolder={"Seacrh Faqs"}
          data={data}
          TableRow={TableRows}
          tostify={tostify}
          //Helpers States &  Function
          createRows={createRows}
          initialValues={FaqInitialValues}
          schema={FaqSchema}
          setInitialValues={setInitialValues}
          formFields={FaqFormFields}
          //SERVICES
          add={AddCMSFaqService}
          update={UpdateCMSFaqservice}
          updateStatus={UpdateCMSFaqStatuservice}
          deleteRow={DeleteCMSFaqLisitngervice}
        />
      )}
    </div>
  );
};
export default FaqsList;
