/* eslint-disable */
import { Formik } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { SideBarBgContext } from "../../app";
import ProfileImageBox from "ReuseableCompsUser/ProfileImageBar";
import FormikFieldDoubleUser from "ReuseableCompsUser/DoubleFormFIeld";
import { UserDeactivate, UserDelete } from "services/auth";
import { enable2Fa, verifyTwoFa, disbaleTwoFa } from "services/user";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { GetUser } from "services/user";

const GenericFormikUserForm = (props) => {
  const {
    type,
    heading,
    data,
    initialValues,
    getValues,
    schema,
    formFields,
    update,
    formFields1,
    formFields2,
    headingFormFields1,
    headingFormFields2,
    networks,
    categories,
    setselectedFiles,
    tostify,
    setTostify,
    setSelectedNetwork,
    getCollectionByNetwork,
    selectedNetwork,
    TableMiniHeadings,
    fetchData,
    checkNetwork,
    handleMint,
    upload,
    setUpload,
    active,
    classProfile,
    UpdateProfileImage,
  } = props;
  const [color, setColor] = useContext(SideBarBgContext);
  const [twoFa, setTwoFa] = useState(false);
  const [show, setShow] = useState(false);
  const [qr, setQr] = useState("");
  const [code, setCode] = useState("");
  const [userData, setUserData] = useState("");
  const [verify, setVerify] = useState("");
  const [error, setError] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-simple text-success",
      cancelButton: "btn btn-simple text-danger",
    },
    buttonsStyling: false,
  });

  const SubmitForm = useCallback((data, actions) => {
    update(data, actions);
  }, []);
  const deActive = () => {
    UserDeactivate(user).then((res) => {});
    localStorage.clear();
    history.go("/home");
  };
  const deleteUser = () => {
    UserDelete(user).then((res) => {});
    localStorage.clear();
    history.go("/home");
  };

  const enableTwoFa = () => {
    if (twoFa) {
      enable2Fa({ email: user.email }).then((res) => {
        if (res && res.status === 200) {
          setQr(res.data);
          setShow(true);
        }
      });
    }
  };
  const disable2Fa = () => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, disable it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        background: "#27293d",
      })
      .then((result) => {
        if (result.isConfirmed) {
          disbaleTwoFa({ email: user.email }).then((res) => {
            if (res.status === 200) {
              setTwoFa(false);
              setVerify(false);
            }
            toast(res.message, {
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              type: res && res.status === 200 ? "success" : "error",
            });
          });
        }
      });
  };
  function handleChange(event) {
    setCode(event.target.value);
    if (code.trim() !== "") {
      setError("");
    }
  }
  const verify2Fa = () => {
    if (code.trim() !== "") {
      verifyTwoFa({ email: user.email, code: code }).then((res) => {
        if (res.status === 200) {
          toast(res.message, {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            type: "success",
          });
          setVerify(true);
          setShow(false);
          setCode("");
        } else {
        }
      });
    } else {
      setError("Please enter 2FA code!");
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    GetUser(user.email).then((res) => {
      if (res.status === 200) {
        setUserData(res.data);
      }
    });
  }, [verify]);

  return (
    <>
      <Row>
        <Col md="12">
          <div className={`card-user ${classProfile}`}>
            <div className="card-body">
              <p className="card-text"></p>
              <Formik
                enableReinitialize={false}
                initialValues={
                  heading === "Profile" || heading === "Pinata Key"
                    ? getValues(data)
                    : initialValues
                }
                onSubmit={(values, actions) => {
                  SubmitForm(values, actions);
                }}
                validationSchema={schema}
              >
                {(props) => {
                  return (
                    <>
                      {type === "profile" && (
                        <ProfileImageBox
                          formFields={formFields}
                          {...props}
                          heading={heading}
                          tostify={tostify}
                          setTostify={setTostify}
                          upload={upload}
                          setUpload={setUpload}
                          classProfile={classProfile}
                          UpdateProfileImage={UpdateProfileImage}
                        />
                      )}
                      {type === "password" && (
                        <>
                          <div
                            className="manage-nfts-main-section"
                            id="password"
                          >
                            <div className="manage-nfts-main-section-content">
                              <h3>Change Password</h3>
                            </div>
                            <div className="card-body pt-0">
                              <FormikFieldDoubleUser
                                formFields={formFields}
                                {...props}
                                heading={heading}
                                tostify={tostify}
                                setTostify={setTostify}
                                initialValues={initialValues}
                                schema={schema}
                                getValues={getValues}
                              />
                              <h3 className="my-4">Password requirements</h3>
                              <p className="mb-2">
                                Please follow this guide for a strong password:
                              </p>
                              <ul className="text-muted ps-4 mb-0 float-start">
                                <li>
                                  <span className="text-sm">
                                    One special characters
                                  </span>
                                </li>
                                <li>
                                  <span className="text-sm">
                                    Min 8 characters
                                  </span>
                                </li>
                                <li>
                                  <span className="text-sm">One number</span>
                                </li>
                                <li>
                                  <span className="text-sm">
                                    One upper case
                                  </span>
                                </li>
                                <li>
                                  <span className="text-sm">
                                    One lower case
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>{" "}
                        </>
                      )}
                      {type === "2fa" && (
                        <div
                          className="manage-nfts-main-section security-checkbox"
                          id="2fa"
                        >
                          <div className="manage-nfts-main-section-content d-flex flex-column">
                            <div className="d-flex align-items-center mb-4">
                              <h3 className="mb-0 mr-4">
                                Two-factor authentication
                              </h3>
                              {userData.fa2Status === true ? (
                                <span className="badge badge-success ms-auto">
                                  Enabled
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            {userData.fa2Status === true ? (
                              <>
                                <div className="d-flex flex-column">
                                  <Button onClick={() => disable2Fa()}>
                                    Disbale 2FA
                                  </Button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="d-flex flex-column">
                                  <div className="d-flex">
                                    <input
                                      className="mr-3"
                                      type="checkbox"
                                      onChange={() => setTwoFa(!twoFa)}
                                    />
                                    <Button
                                      className="btn-theme"
                                      disabled={twoFa ? false : true}
                                      onClick={() => enableTwoFa()}
                                    >
                                      Configure
                                    </Button>
                                  </div>
                                  {twoFa ? (
                                    <p className="mb-3">
                                      Two-factor Authentication (2FA) adds
                                      additional account security if your
                                      password is compromised or stolen. With
                                      2FA, access to your account requires a
                                      password and a second form of
                                      verification. Move Immunity supports 2FA
                                      by using one-time passwords generated with
                                      the TOTP algorithm You can use any mobile
                                      application employing TOTP. We recommend
                                      the following apps. Android, iOS, and
                                      Blackberry—Google Authenticator
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {type === "sessions" && (
                        <div className="manage-nfts-main-section" id="sessions">
                          <div className="manage-nfts-main-section-content pb-3">
                            <h3>Sessions</h3>
                            <p className="text-sm">
                              This is a list of devices that have logged into
                              your account.
                            </p>
                          </div>
                          <div className="card-body security-checkbox pt-0 px-xs-0">
                            <div className="content-wrapper-list" style={{width:"100%"}}>
                              <Table>
                                <tbody>
                                  {data?.sessions
                                    ? data?.sessions
                                        .slice(0)
                                        .reverse()
                                        .map((ses, index) => (
                                          <tr key={index}>
                                            <td>
                                              <i
                                                className="fas fa-desktop text-lg opacity-6 mr-3"
                                                aria-hidden="true"
                                              ></i>
                                            </td>
                                            <td className="">
                                              {`${ses?.country_name} ${ses?.ip}`}
                                            </td>
                                            <td>
                                              {`${ses.browser} on ${ses.os}`}{" "}
                                            </td>
                                            <td>{ses.dateTime}</td>
                                            <td>{ses?.country_code}</td>
                                          </tr>
                                        ))
                                    : ""}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Col>
      </Row>
      <Modal isOpen={show} toggle={() => setShow(false)} className="twofamodal">
        <ModalHeader toggle={!show} className="twofa-header">
          2FA Verification
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column twofa-image">
            <img src={qr} className="img-fluid shadow-4" alt="..." />
            <input type="text" value={code} onChange={handleChange} />
            <p style={{ color: "#e02058", fontWeight: 500 }}>
              {error ? error : ""}
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={verify2Fa}>
            Verify
          </Button>
          <Button color="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default GenericFormikUserForm;
