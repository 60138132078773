import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
const Clip = ({ color }) => {
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: ${color};
  `;
    return (
        <>
            <ClipLoader
                css={override}
                color={color ? color : "#fff"}
                loading={true}
                size={24}
            />
        </>
    );
};
export default Clip;
