import { useEffect, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
const UploadIcon = (props) => {
  const { upload, setUpload, UpdateProfileImage } = props;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles([upload]);
  }, [upload]);

  const focusedStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: "#00e676",
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: false,
    noKeyboard: true,
    multiple: false,
    accept: {
      'image/*': ['.jpeg', '.png','.jpg']
    },
    minSize: 0,
    maxSize: 2097152,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        setUpload(
          acceptedFiles && acceptedFiles.length > 0
            ? acceptedFiles[0]
            : props.icon
        );
      }
      if (rejectedFiles.length > 0) {
        rejectedFiles.map(file => {
          if (!file.file.type.match('image/jpeg|image/png|image/jpg')) {
            toast('Format not supported! Supported file types are: jpeg, png, jpg', {
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              type: "warning",
            })
            return null
          } else if (file.file.size > 2097152) {
            toast(file.errors[0].message, {
              position: "top-right",
              autoClose: 5000,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              type: "warning",
            })
            return null
          } else {
            return null
            // return { type: '', message: '' };
          }
        })
      }
    },
  });
  const style = useMemo(
    () => ({
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file?.preview));
    },
    [files]
  );
  return (
    <>
      <section className="d-flex justify-content-center">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div className="d-flex justify-content-end">
            <button
              onClick={(e) => {
                e.preventDefault();
              }}
              className={'btn-theme btn mr-2'}
            >
              <div className="d-flex justify-content-center">
                <span>Upload</span>
              </div>
            </button>
          </div>
        </div>
          <button className="btn-theme btn" onClick={UpdateProfileImage}>Save</button>
      </section>
    </>
  );
};
export default UploadIcon;
