import instance from "../../../config/axios";
import { ParseError, getAuthHeader} from "../../../config/utils";

export const GetSiteSettings = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  // const headers = await getAuthHeader();
  return instance
    .get("admin/siteSettings/")
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateSiteSettings = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("admin/siteSettings/", data,headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
