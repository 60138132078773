/*eslint-disable*/
import React, { useContext, useState, useEffect } from 'react'
import GenericTable from "ReuseableComps/GenericTables";
import { PaymentsTableHeadings, PaymentsInitialValues, createRows } from "./helpers";
import { ToasterContext } from "app";
import TableRows from "./TableRows";
import { getAuthHeader } from "../../../config/utils";
import axios from "axios";
import SkeletonTable from 'ReuseableComps/GenericTablesSkelton/SkeltonTable';

const Payments = () => {
    const [tostify, setTostify] = useContext(ToasterContext);
    const [data, setData] = useState()
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        const getPayments = async () => {
            const headers = await getAuthHeader();
            await axios.post(process.env.REACT_APP_BASE_URL + "/admin/plans/payments", {}, headers)
                .then((response) => {
                    if (response.data && response.data.code === 200) {
                        setData(response.data.data);
                        setTimeout(() => {
                            setLoader(true)
                        }, 1000);
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        }
        getPayments()
        //eslint-disable-next-line
    }, [])


    return (
        <div>
            {
                loader ? <GenericTable
                    heading={"Payments"}
                    Columns={PaymentsTableHeadings}
                    tostify={tostify}
                    createRows={createRows}
                    initialValues={PaymentsInitialValues}
                    data={data}
                    TableRow={TableRows}
                    role={'user'}
                /> : <SkeletonTable role='user' header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} />
            }
        </div>
    )
}

export default Payments