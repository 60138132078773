import * as Yup from "yup";
export const PricingPlansInitialValues = {
  mainHead: "",
  description: "",
};

export const PricingPlansFormFields = [
  {
    field: "Main Heading",
    fieldName: "mainHead",
    place: "",
    type: "text",
    required: true,
  },
  {
    field: "Description",
    fieldName: "description",
    place: "",
    type: "description",
    required: true,
  }
];
export const Tab1FormFields = [
  {
    field: "Card 1 Main",
    fieldName: "homecard1heading",
    place: "",
    type: "input",
    required: true,
    field1: "Card 1 Sub",
    fieldName1: "homecard1subheading",
    place1: "",
    type1: "input",
    required1: true,
  },
  {
    field: "Card 1 Description",
    fieldName: "homecard1description",
    place: "Enter Address Url",
    type: "text",
    required: true,
    field1: "Card 1 Text",
    fieldName1: "homecard1text",
    place1: "",
    type1: "text",
    required1: true,
  },
  {
    field: "Card 2 Main",
    fieldName: "homecard2heading",
    place: "",
    type: "input",
    required: true,
    field1: "Card 2 Sub",
    fieldName1: "homecard2subheading",
    place1: "",
    type1: "input",
    required1: true,
  },
  {
    field: "Card 2 Description",
    fieldName: "homecard2description",
    place: "Enter Address Url",
    type: "text",
    required: true,
    field1: "Card 2 Text",
    fieldName1: "homecard2text",
    place1: "",
    type1: "text",
    required1: true,
  },
  {
    field: "Card 3 Main",
    fieldName: "homecard3heading",
    place: "",
    type: "input",
    required: true,
    field1: "Card 3 Sub",
    fieldName1: "homecard3subheading",
    place1: "",
    type1: "input",
    required1: true,
  },
  {
    field: "Card 3 Description",
    fieldName: "homecard3description",
    place: "Enter Address Url",
    type: "text",
    required: true,
    field1: "Card 3 Text",
    fieldName1: "homecard1text",
    place1: "",
    type1: "text",
    required1: true,
  },
  {
    field: "Card 4 Main",
    fieldName: "homecard4heading",
    place: "",
    type: "input",
    required: true,
    field1: "Card 4 Sub",
    fieldName1: "homecard4subheading",
    place1: "",
    type1: "input",
    required1: true,
  },
  {
    field: "Card 4 Description",
    fieldName: "homecard4description",
    place: "Enter Address Url",
    type: "text",
    required: true,
    field1: "Card 3 Text",
    fieldName1: "homecard1text",
    place1: "",
    type1: "text",
    required1: true,
  },
];

export const Tab2FormFields = [
  {
    field: "Card 1 Main",
    fieldName: "profilecard1heading",
    place: "",
    type: "input",
    required: true,
    field1: "Card 1 Sub",
    fieldName1: "profilecard1subheading",
    place1: "",
    type1: "input",
    required1: true,
  },
  {
    field: "Card 1 Description",
    fieldName: "profilecard1description",
    place: "Enter Address Url",
    type: "text",
    required: true,
    field1: "Card 1 Text",
    fieldName1: "profilecard1text",
    place1: "",
    type1: "text",
    required1: true,
  },
  {
    field: "Card 2 Main",
    fieldName: "profilecard2heading",
    place: "",
    type: "input",
    required: true,
    field1: "Card 2 Sub",
    fieldName1: "profilecard2subheading",
    place1: "",
    type1: "input",
    required1: true,
  },
  {
    field: "Card 2 Description",
    fieldName: "profilecard2description",
    place: "Enter Address Url",
    type: "text",
    required: true,
    field1: "Card 2 Text",
    fieldName1: "profilecard2text",
    place1: "",
    type1: "text",
    required1: true,
  },
  {
    field: "Card 3 Main",
    fieldName: "profilecard3heading",
    place: "",
    type: "input",
    required: true,
    field1: "Card 3 Sub",
    fieldName1: "profilecard3subheading",
    place1: "",
    type1: "input",
    required1: true,
  },
  {
    field: "Card 3 Description",
    fieldName: "profilecard3description",
    place: "Enter Address Url",
    type: "text",
    required: true,
    field1: "Card 3 Text",
    fieldName1: "profilecard1text",
    place1: "",
    type1: "text",
    required1: true,
  },
  {
    field: "Card 4 Main",
    fieldName: "profilecard4heading",
    place: "",
    type: "input",
    required: true,
    field1: "Card 4 Sub",
    fieldName1: "profilecard4subheading",
    place1: "",
    type1: "input",
    required1: true,
  },
  {
    field: "Card 4 Description",
    fieldName: "profilecard4description",
    place: "Enter Address Url",
    type: "text",
    required: true,
    field1: "Card 3 Text",
    fieldName1: "profilecard1text",
    place1: "",
    type1: "text",
    required1: true,
  },
];


export const PricingPlansSchema = Yup.object().shape({
  mainHead: Yup.string().trim('Required').required('Required'),
  description: Yup.string().trim('Required').required('Required'),
});

export const getInitialValues = (data) => {
  return {
    mainHead: data.mainHead,
    description: data.description,
    type: data.type,
  };
};
