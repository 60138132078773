import * as Yup from "yup";
export const GalleryInitialValues = {
  network: "",
  networkChangeStatus: "",
  collection: "",
  selectedCollection: "",
  collections: "",
  contractData: "",
  mintAmount: "",
};

export const GalleryFormFields = [
  {
    field: "Select Network",
    fieldName: "network",
    place: "",
    type: "select",
    required: true,
    field1: "Select Collection",
    fieldName1: "collection",
    place1: "",
    type1: "select",
    required1: true,
  },
  {
    field: "Selected Collection",
    fieldName: "selectedCollection",
    place: "",
    type: "tableRow",
    required: true,
  },
  {
    field: "Mint Amount",
    fieldName: "mintAmount",
    place: "",
    type: "number",
    required: true,
  },
];

export const GallerySchema = Yup.object().shape({
  network: Yup.string().required("Network is required!"),
  mintAmount: Yup.number().positive().integer('Mint amount must be a positive integer').min(1,'Mint amount must be greater than or equal to 1.').required('Minting amount is required'),
});

export const CollectionTableMiniHeadings = [
  {
    id: "name",
    label: "Name",
    sort: true,
    class: "bg-body-light",
  },

  {
    id: "symbol",
    label: "Symbol",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "network",
    label: "Network",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "price",
    label: "Price",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "minted",
    label: "Minted",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "supply",
    label: "Supply",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "revealed",
    label: "Revealed",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "balance",
    label: "balance",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "address",
    label: "address",
    sort: true,
    class: "bg-body-light",
  },
];
