import * as Yup from "yup";
export const PasswordInitialValues = {
  password: "",
  newPassword: "",
  confirmPassword: "",
};
export const PasswordFormFields = [
  {
    field: "Current Password",
    fieldName: "password",
    place: "Enter Your Current Password",
    type: "password",
  },
  {
    field: "New Password",
    fieldName: "newPassword",
    place: "Enter New Password",
    type: "password",
  },
  {
    field: "Confirm Password",
    fieldName: "confirmPassword",
    place: "Confirm Your New Password",
    type: "password",
  },
];
export const PasswordSchema = Yup.object().shape({
  password: Yup.string().required("Password is required!"),
  newPassword: Yup.string()
    .required("Please Enter New password")
    .test(
      "regex",
      "Password must be min 8 characters, and have 1 Special Character, 1 Uppercase, 1 Number and 1 Lowercase",
      (val) => {
        let regExp = new RegExp(
          "^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
        );
        return regExp.test(val);
      }
    ),
  confirmPassword: Yup.string()
    .required("Please Enter confirm password")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});


