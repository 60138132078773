import React, { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import AdminRoutes from "./Router/adminRouter";
import AuthRoutes from "./Router/authRoutes";
import UserRoutes from "./Router/userRouter";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import { userPlanContext } from "contexts/UserPlanContext";
import Helmet from "react-helmet";

import {
    fab,
    faFacebook,
    faTwitter,
    faVuejs,
    faReact,
    faHtml5,
    faGoogle,
    faInstagram,
    faPinterest,
    faYoutube,
    faDiscord,
    faSlack,
    faDribbble,
    faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
    far,
    faSquare,
    faLifeRing,
    faCheckCircle,
    faTimesCircle,
    faDotCircle,
    faThumbsUp,
    faComments,
    faFolderOpen,
    faTrashAlt,
    faFileImage,
    faFileArchive,
    faCommentDots,
    faFolder,
    faKeyboard,
    faCalendarAlt,
    faEnvelope,
    faAddressCard,
    faMap,
    faObjectGroup,
    faImages,
    faUser,
    faLightbulb,
    faGem,
    faClock,
    faUserCircle,
    faQuestionCircle,
    faBuilding,
    faBell,
    faFileExcel,
    faFileAudio,
    faFileVideo,
    faFileWord,
    faFilePdf,
    faFileCode,
    faFileAlt,
    faEye,
    faChartBar,
} from "@fortawesome/free-regular-svg-icons";
import {
    fas,
    faExclamation,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faCheck,
    faSmile,
    faHeart,
    faBatteryEmpty,
    faBatteryFull,
    faChevronRight,
    faSitemap,
    faPrint,
    faMapMarkedAlt,
    faTachometerAlt,
    faAlignCenter,
    faExternalLinkAlt,
    faShareSquare,
    faInfoCircle,
    faSync,
    faQuoteRight,
    faStarHalfAlt,
    faShapes,
    faCarBattery,
    faTable,
    faCubes,
    faPager,
    faCameraRetro,
    faBomb,
    faNetworkWired,
    faBusAlt,
    faBirthdayCake,
    faEyeDropper,
    faUnlockAlt,
    faDownload,
    faAward,
    faPlayCircle,
    faReply,
    faUpload,
    faBars,
    faEllipsisV,
    faSave,
    faSlidersH,
    faCaretRight,
    faChevronUp,
    faPlus,
    faLemon,
    faChevronLeft,
    faTimes,
    faChevronDown,
    faFilm,
    faSearch,
    faEllipsisH,
    faCog,
    faArrowsAltH,
    faPlusCircle,
    faAngleRight,
    faAngleUp,
    faAngleLeft,
    faAngleDown,
    faArrowUp,
    faArrowDown,
    faArrowRight,
    faArrowLeft,
    faStar,
    faSignOutAlt,
    faLink,
} from "@fortawesome/free-solid-svg-icons";
import { GetUserPlan } from "services/user";
import { useLocation } from "react-router-dom";
import { GetSiteSettings } from "services/admin/SiteSettings";
library.add(
    far,
    faSquare,
    faLifeRing,
    faCheckCircle,
    faTimesCircle,
    faDotCircle,
    faThumbsUp,
    faComments,
    faFolderOpen,
    faTrashAlt,
    faFileImage,
    faFileArchive,
    faCommentDots,
    faFolder,
    faKeyboard,
    faCalendarAlt,
    faEnvelope,
    faAddressCard,
    faMap,
    faObjectGroup,
    faImages,
    faUser,
    faLightbulb,
    faGem,
    faClock,
    faUserCircle,
    faQuestionCircle,
    faBuilding,
    faBell,
    faFileExcel,
    faFileAudio,
    faFileVideo,
    faFileWord,
    faFilePdf,
    faFileCode,
    faFileAlt,
    faEye,
    faChartBar
);
library.add(
    fab,
    faFacebook,
    faTwitter,
    faVuejs,
    faReact,
    faHtml5,
    faGoogle,
    faInstagram,
    faPinterest,
    faYoutube,
    faDiscord,
    faSlack,
    faDribbble,
    faGithub
);
library.add(
    fas,
    faExclamation,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faCheck,
    faSmile,
    faHeart,
    faBatteryEmpty,
    faBatteryFull,
    faChevronRight,
    faSitemap,
    faPrint,
    faMapMarkedAlt,
    faTachometerAlt,
    faAlignCenter,
    faExternalLinkAlt,
    faShareSquare,
    faInfoCircle,
    faSync,
    faQuoteRight,
    faStarHalfAlt,
    faShapes,
    faCarBattery,
    faTable,
    faCubes,
    faPager,
    faCameraRetro,
    faBomb,
    faNetworkWired,
    faBusAlt,
    faBirthdayCake,
    faEyeDropper,
    faUnlockAlt,
    faDownload,
    faAward,
    faPlayCircle,
    faReply,
    faUpload,
    faBars,
    faEllipsisV,
    faSave,
    faSlidersH,
    faCaretRight,
    faChevronUp,
    faPlus,
    faLemon,
    faChevronLeft,
    faTimes,
    faChevronDown,
    faFilm,
    faSearch,
    faEllipsisH,
    faCog,
    faArrowsAltH,
    faPlusCircle,
    faAngleRight,
    faAngleUp,
    faAngleLeft,
    faAngleDown,
    faArrowUp,
    faArrowDown,
    faArrowRight,
    faArrowLeft,
    faStar,
    faSignOutAlt,
    faLink
);

export const ToasterContext = React.createContext("");
export const ImageContext = React.createContext("");
export const SideBarBgContext = React.createContext("");
export const ThemeContext = React.createContext("");
export const WalletContext = React.createContext("");

const App = () => {

    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const [profileImg, setProfileImg] = useState("");
    const backgroundColors = {
        primary: "primary",
        blue: "blue",
        green: "green",
        orange: "warning",
        red: "danger",
    };

    const themeOptions = {
        black: "",
        white: "white-content",
    };

    const [wallet, setWallet] = useState({ address: '', balance: '' });
    const [color, setColor] = useState(backgroundColors.blue);
    const [theme, setTheme] = useState(themeOptions.black);
    const [userCurrentPlan, setUserCurrentPlan] = useState([]);
    const [tostify, setTostify] = useState({
        visible: false,
        key: "",
        type: "",
        message: "",
        dismissAll: () => toast.dismiss(),
    });
    const [siteInfo, setSiteInfo] = useState('')

    let location = useLocation();

    useEffect(() => {
        const getSiteData = () => {
            GetSiteSettings().then(res => {
                if (res.status === 200) {
                    setSiteInfo(res.data)
                }
            })
        }
        getSiteData()
    }, [])
    useEffect(() => { }, [tostify]);

    if (theme === "") {
        document.body.classList.remove("white-content");
    }
    if (theme === "white-content") {
        document.body.classList.add("white-content");
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user !== null) {
            let userId = user.id ? user.id : user._id
            GetUserPlan(userId).then(res => {
                if (res.status === 200) {
                    setUserCurrentPlan(res.data)
                    localStorage.setItem('userPlan', JSON.stringify(res.data))
                }
            })
        }
    }, [location])


    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        document.title = siteInfo?.title ? `${siteInfo?.title}` : "MutoLab"
    }, [siteInfo])


    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={`${siteInfo.meta_description}`}
                />
                <meta name="keywords" content={`${siteInfo.meta_key}`} />
                {/* twitter */}

                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:description"
                    content={`${siteInfo.meta_description}`}
                />
                <meta name="twitter:creator" content={`${siteInfo.title}`} />

                {/* twitter */}

                {/* facebook */}
                <meta name="author" content={`${siteInfo.title}`} />
                <meta property="og:title" content={`${siteInfo.title}`} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:description"
                    content={`${siteInfo.meta_description}`}
                />

                {/* facebook */}
            </Helmet>
            <WalletContext.Provider value={[wallet, setWallet]}>
                <ThemeContext.Provider value={[theme, setTheme]}>
                    <ThemeContextWrapper>
                        <BackgroundColorWrapper>
                            <ImageContext.Provider value={[profileImg, setProfileImg]}>
                                <ToasterContext.Provider value={[tostify, setTostify]}>
                                    <SideBarBgContext.Provider value={[color, setColor]}>
                                        <ToastContainer
                                            closeButton={false}
                                            position="top-right"
                                            autoClose={8000}
                                            hideProgressBar={false}
                                            newestOnTop={false}
                                            draggable={false}
                                            closeOnClick
                                        />
                                        {token ? (
                                            role === "admin" || role === "super" ? (
                                                <AdminRoutes
                                                    color={color}
                                                    tostify={tostify}
                                                    setTostify={setTostify}
                                                />
                                            ) :
                                                <userPlanContext.Provider value={{ userCurrentPlan }}>
                                                    <UserRoutes tostify={tostify} setTostify={setTostify} />
                                                </userPlanContext.Provider>
                                        ) : (
                                            <AuthRoutes tostify={tostify} setTostify={setTostify} />
                                        )}
                                    </SideBarBgContext.Provider>
                                </ToasterContext.Provider>
                            </ImageContext.Provider>
                        </BackgroundColorWrapper>
                    </ThemeContextWrapper>
                </ThemeContext.Provider>
            </WalletContext.Provider>
        </>
    );
};
export default App;
