import React, { useEffect, useState } from "react";
import { Col, Image, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GetSiteSettings } from "services/admin/SiteSettings";
import { useHistory } from "react-router-dom";
import logo from "assets/images/mutolab.png";
import { Link as LinkScroll } from 'react-scroll'

const Footer = (props) => {
    const [siteInfo, setSiteInfo] = useState('')
    useEffect(() => {
        const getSiteData = () => {
            GetSiteSettings().then(res => {
                if (res.status === 200) {
                    setSiteInfo(res.data)
                }
            })
        }
        getSiteData()
    }, [])
    const history = useHistory()
    let user = localStorage.getItem('user')
    const handleRedirect = () => {
        if (!user) {
            history.push('/signup')
        } else {
            window.location.href = "/dashboard"
        }
    }

    return (
        <div className="home-footer footer">

            <div className="footer-top">
                <div className="container-width">
                    <Row>
                        <Col md={12} lg={4}>
                            <div className="footer-top-content">
                                <div className="footer-logo margin-content">
                                    <Image src={logo} fluid />
                                </div>
                                <p>{siteInfo.description}</p>
                            </div>
                        </Col>
                        <Col md={4} lg={2}>
                            <div className="footer-top-content-heading margin-content">
                                <h4>Explore</h4>
                            </div>
                            <ul className="list p-0">
                                {window.location.pathname.includes('home') ?
                                    <li className="list-item"><LinkScroll spy={true} smooth={true} to="pricing">Pricing</LinkScroll></li>
                                    : <li className="list-item"><Link to="/home#pricing">Pricing</Link></li>
                                }
                                {window.location.pathname.includes('home') ?
                                    <li className="list-item"><LinkScroll spy={true} smooth={true} to="how">How It Works</LinkScroll></li>
                                    : <li className="list-item"><Link to="/home#how">How It Works</Link></li>
                                }
                            </ul>
                        </Col>
                        <Col md={4} lg={2}>
                            <div className="footer-top-content-heading margin-content">
                                <h4>Helpful Links</h4>
                            </div>
                            <ul className="list p-0">
                                {window.location.pathname.includes('home') ?
                                    <li className="list-item"><LinkScroll spy={true} smooth={true} to="aboutUs">About Us</LinkScroll></li>
                                    : <li className="list-item"><Link to="/home#aboutUs">About Us</Link></li>
                                }
                                {window.location.pathname.includes('home') ?
                                    <li className="list-item"><LinkScroll spy={true} smooth={true} to="contactUs">Contact Us</LinkScroll></li>
                                    : <li className="list-item"><Link to="/home#contactUs">Contact Us</Link></li>
                                }
                                <li className="list-item"><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li className="list-item"><Link to="/term-and-condition">Terms & Conditions</Link></li>
                            </ul>
                        </Col>
                        <Col md={4} lg={4}>
                            <div className="social-links-wrapper">
                                <div className="footer-top-content-heading margin-content">
                                    <h4>Follow Us</h4>
                                </div>
                                <ul className="list p-0 social-links d-flex align-items-center flex-wrap">
                                    {siteInfo?.twitter ?
                                        <li className="list-item"><a href={siteInfo?.twitter ? siteInfo?.twitter : '#'} target='_blank' rel="noreferrer" ><Image src="images/twitter.png" fluid /></a></li> : ''}
                                    {siteInfo?.linkedin ?
                                        <li className="list-item"><a href={siteInfo?.linkedin ? siteInfo?.linkedin : '#'} target='_blank' rel="noreferrer" ><Image src="images/linkedin2.png" fluid /></a></li> : ''}
                                    {siteInfo?.youtube ?
                                        <li className="list-item"><a href={siteInfo?.youtube ? siteInfo?.youtube : '#'} target='_blank' rel="noreferrer" ><Image src="images/youtube.png" fluid /></a></li> : ''}
                                    {siteInfo?.facebook ?
                                        <li className="list-item"><a href={siteInfo?.facebook ? siteInfo?.facebook : '#'} target='_blank' rel="noreferrer" ><Image src="images/facebook.png" fluid /></a></li> : ''}
                                    {siteInfo?.github ?
                                        <li className="list-item"><a href={siteInfo?.github ? siteInfo?.github : '#'} target='_blank' rel="noreferrer" ><Image src="images/github.png" fluid /></a></li> : ''}
                                    {siteInfo?.medium ?
                                        <li className="list-item"><a href={siteInfo?.medium ? siteInfo?.medium : '#'} target='_blank' rel="noreferrer" ><Image src="images/medium.png" fluid /></a></li> : ''}
                                    {siteInfo?.telegram ?
                                        <li className="list-item"><a href={siteInfo?.telegram ? siteInfo?.telegram : '#'} target='_blank' rel="noreferrer" ><Image src="images/telegram.png" fluid /></a></li> : ''}
                                    {siteInfo?.discord ?
                                        <li className="list-item"><a href={siteInfo?.discord ? siteInfo?.discord : '#'} target='_blank' rel="noreferrer" ><Image src="images/discord.png" fluid /></a></li> : ''}
                                </ul>
                                <div className="footer-platform-btn d-flex justify-content-end">
                                    <Button className="join-btn" onClick={handleRedirect}>Join MutoLab</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container-width">
                    <div className="footer-bottom-content text-center">
                        <p className="m-0">{`© ${new Date().getFullYear()} MetaMuto. All rights reserved.`}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer;
