import React from "react";

import SkeltonTable from "./SkeltonTable";

const Skelton = (props) => {
  
  return (
    <>
      <div className="content">
        <SkeltonTable props={props}/>
      </div>
    </>
  );
};
export default Skelton;
