import Actions from "ReuseableComps/Actions";
const TableRow = (props) => {
  const { row, index, setActionState, deleteRow } = props;

  return (
    <>
      <tr key={row._id + index} id={"network-tr-" + index}>
        <td className="text-left ">
          <span>{row.name}</span>
        </td>
        <td className="text-left ">
          <span>{row.chainId}</span>
        </td>
        <td className="text-left ">
          <span>{row.nativeCurrency.symbol}</span>
        </td>
        <td className="text-left ">
          <Actions
            setActionState={setActionState}
            row={row}
            deleteRow={deleteRow}
          />
        </td>
      </tr>
    </>
  );
};
export default TableRow;
