const TableHeader = (props) => {
  const { Columns } = props;

  return (
    <thead
      colSpan="5"
      className="text-primary text-capitalize font-size-sm font-weight-bold border-top"
    >
      <tr>
        {Columns.map((headCell, index) => (
          <th
            id={"table-header" + index}
            key={headCell.label}
            className={headCell.class}
          >
            <div className="d-flex">
              <span>{headCell.label}</span>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
export default TableHeader;
