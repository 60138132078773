/*eslint-disable*/
import { useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import axios from "axios";
import ClipLoader from "../ClipLoader";

const Actions = (props) => {
    const { handleOpen, setUpdatedRow, setActionState, row, deleteRow, collectionBtn, subscriptionBtn, paymentBtn, redirectLink, downloadNft } = props;
    const [swalProps, setSwalProps] = useState({});
    const [downloading, setDownloading] = useState(false)

    const handleClick = () => {
        setSwalProps({
            show: true,
            title: "Example",
            text: "Hello World",
        });
    };
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
    });

    let history = useHistory()

    const routeChange = (id) => {
        let path = `/publishedNfts/${id}`;
        history.push(path);
    }


    const routePayment = (id) => {
        let path = `/payments/${id}`;
        history.push(path);
    }
    const userCollection = (id) => {
        let path = `/collection/${id}`;
        history.push(path);
    }

    const downloadNftFiles = (id) => {
        setDownloading(true)
        const result = axios.get(
            process.env.REACT_APP_BASE_URL + "/collections/download/" + id
        ).then(function (result) {
            if (result.data.code === 200) {
                const url = process.env.REACT_APP_BASE_URL + "/collections/downloaded/file-download?id=" + result.data.data
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('target', `_blank`);

                document.body.appendChild(link);
                link.click();
                link.remove();
                setDownloading(false)
            }

        })

    }

    return (
        <>
            {
                handleOpen ? (
                    <Button
                        className="btn-icon btn-simple ml-1 mr-1"
                        color="success"
                        onClick={() => {
                            handleOpen();
                            setActionState("edit");
                            setUpdatedRow(row);
                        }}
                        size="sm"
                        title="Edit"
                    >
                        <i className="fa fa-edit"></i>
                    </Button>
                ) : null}


            {
                redirectLink ? (
                    <Button
                        className="btn-icon btn-simple ml-1 mr-1"
                        color="successs"
                        onClick={() => {
                            routeChange(row.id);
                        }}
                        size="sm"
                        title="Edit"
                    >
                        <i className="fa fa-edit"></i>
                    </Button>
                ) : null
            }

            {
                downloadNft ? (
                    <Button
                        className="btn-icon btn-simple ml-1 mr-1"
                        color="successs"
                        disabled={downloading}
                        onClick={() => {
                            downloadNftFiles(row.id);
                        }}
                        size="sm"
                        title="Download"
                    >
                        {downloading ? <ClipLoader/> : <i className="fa fa-download"></i>}
                    </Button>
                ) : null
            }

            {
                deleteRow ? (
                    <Button
                        className="btn-icon btn-simple mr-1 ml-1"
                        onClick={() => deleteRow(row.id)}
                        color="danger"
                        size="sm"
                        title="Delete"
                    >
                        <i className="fa fa-times" />
                    </Button>
                ) : null}
            {
                collectionBtn ?
                    <Button
                        className="btn-icon btn-simple ml-1 mr-1"
                        color="info"
                        onClick={() => { userCollection(row.id) }}
                        size="sm"
                        title="NFT Collection"
                    >
                        <i class="fa fa-list"></i>
                    </Button> : null}
            {
                paymentBtn ?
                    <Button
                        className="btn-icon btn-simple ml-1 mr-1"
                        color="primary"
                        onClick={() => { routePayment(row.id) }}
                        size="sm"
                        title="Payments"
                    >
                        <i class="fa fa-credit-card"></i>

                    </Button> : null}
        </>
    );
};

export default Actions;
