import { useContext, useEffect, useState } from "react";
import { ToasterContext } from "app";
import UserListing from "./UserListing";
import { GetAllUsers } from "services/admin/Admin";
import { getAllCollections } from "services/admin/Collections";
import { GetCategories } from "services/admin/Categories";
import { GetPlans } from "services/admin/Subscriptions";

function Dashboard(props) {
  const [data, setData] = useState('')
  const [collections, setCollections] = useState('')
  const [categories, setCategories] = useState('')
  const [plans, setPlans] = useState('')
  //eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const [tostify, setTostify] = useContext(ToasterContext);

  const GetUsers = async () => {
    setLoading(true)
    GetAllUsers().then(res => {
      if (res.status === 200) {
        setData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    })
  }
  const getCollections = () => {
    getAllCollections().then(res => {
      if (res.status === 200) {
        setCollections(res.data);
        setLoading(false);
      }
    })
  }
  const getAllCategories = () => {
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
    GetCategories({ type: "admin", userId: user.id }).then(res => {
      if (res.status === 200) {
        setCategories(res.data);
        setLoading(false);
      }
    })
  }
  const getAllPlans = () => {
    GetPlans('?status=true').then(res => {
      if (res.status === 200) {
        setPlans(res.data);
        setLoading(false);
      }
    })
  }

  useEffect(() => {
    GetUsers()
    getCollections()
    getAllCategories()
    getAllPlans()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="content">
        <div className="row">
          <div class="col-lg-6 col-xl-3 col-md-6">
            <div class="card-stats card">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <div >
                    <div class="info-icon text-center icon-success">
                      <i class="tim-icons icon-single-02"></i>
                    </div>
                  </div>
                  <div>
                    <div className="numbers">
                      <p className="card-category">Users</p>
                      <h4 className="card-title">{data?.length > 0 ? data.length : 0}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-xl-3 col-md-6">
            <div className="card-stats card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="info-icon text-center icon-warning">
                      <i className="tim-icons icon-chat-33"></i>
                    </div>
                  </div>
                  <div>
                    <div className="numbers">
                      <p className="card-category">Categories</p>
                      <h4 className="card-title">{categories?.length > 0 ? categories.length : 0}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-3 col-md-6">
            <div class="card-stats card">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <div >
                    <div class="info-icon text-center icon-primary">
                      <i class="tim-icons icon-shape-star"></i>
                    </div>
                  </div>
                  <div>
                    <div className="numbers">
                      <p className="card-category">Collections</p>
                      <h4 className="card-title">{collections.length > 0 ? collections.length : 0}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-xl-3 col-md-6">
            <div class="card-stats card">
              <div class="card-body">
                <div class="d-flex justify-content-between">
                  <div >
                    <div class="info-icon text-center icon-danger">
                      <i class="tim-icons icon-molecule-40"></i>
                    </div>
                  </div>
                  <div>
                    <div className="numbers">
                      <p className="card-category">Pricing Plans</p>
                      <h4 className="card-title">{plans?.length > 0 ? plans.length : 0}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserListing />
      </div>
    </>
  );
}
export default Dashboard;
