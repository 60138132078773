import instance from "../../../config/axios";
import { ParseError, getAuthHeader } from "../../../config/utils";

export const GetCMSData = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .get("admin/cms/", headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const GetCMSDataByType = async (type) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .get("admin/cms/byType/" + type, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateCMSDataByType = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("admin/cms/byType/", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
// FAQS
export const AddCMSFaqLisitng = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .post("admin/cms/faqs/listing", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateCMSFaqLisitngStatus = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("admin/cms/faqs/listing/status", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateCMSFaqLisitng = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("admin/cms/faqs/listing/", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const DeleteCMSFaqListing = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("admin/cms/faqs/listing/" + id, {},headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

// Monthly Plan
export const GetPricingPlans = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .get("/admin/pricing/" + data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const AddCMSMonthlyLisitng = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .post("admin/pricing", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateCMSMonthlyLisitngStatus = async (data) => {
 
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/admin/pricing/status", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const UpdateCMSMonthlyLisitng = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  return instance
    .put("/admin/pricing", data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          message: response.message,
          data: response.data,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const DeleteCMSMonthlyListing = async (id) => {
  const responseData = {
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getAuthHeader();
  
  return instance
    .delete("/admin/pricing/" + id, headers)
    .then((response) => {
      if (response.data && response.status === 200) {
        response = response.data;
        return {
          ...responseData,
          status: 200,
          data: response.data,
          message: response.message,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
