import React from "react";
import { Row, Badge, Accordion ,Image, Form} from "react-bootstrap";
import NftDetailContent from "./NftDetailContent";
import legsImageOne from '../../../assets/images/legs-one.svg';
import legsImageTwo from '../../../assets/images/legs-two.svg';
import legsImageThree from '../../../assets/images/legs-three.svg';
import legsImageFour from '../../../assets/images/legs-four.svg';
import legsImageFive from '../../../assets/images/legs-five.svg';
import lockImageOne from '../../../assets/images/legs-lock.svg';


const NftDetail = (props) => {
    const miniCard =[
        {id:1,image:legsImageOne},
        {id:2,image:legsImageTwo},
        {id:3,image:legsImageThree},
        {id:4,image:legsImageFour},
        {id:5,image:legsImageFive},
        {id:1,image:legsImageOne},
        {id:2,image:legsImageTwo},
        {id:3,image:legsImageThree},
        {id:4,image:legsImageFour},
        {id:5,image:legsImageFive},
        {id:1,image:legsImageOne},
        {id:6,image:lockImageOne},
        {id:7,image:lockImageOne},
        {id:8,image:lockImageOne},
    ]
    const NftsDetailAccordion =[
        {id:0,title:"Legs"},
        {id:1,title:"Arms"},
        {id:2,title:"Body"},
        {id:3,title:"Backgrounds"},
        {id:4,title:"Horns"},
        {id:5,title:"Face"},
    ]
    return (
        <>
            <div className="manage-nfts-main-section-content">
                <h3>NFT Details</h3>
                <Row>
                    <NftDetailContent image="cnt-one" />
                    <NftDetailContent image="cnt-two" />
                    <NftDetailContent image="cnt-three" />
                </Row>
                <div className="manage-nfts-main-section-badges d-flex justify-content-between">
                    <h4 className="mb-0 h4-first">Select Layer Order:</h4>
                    <div className="manage-nfts-main-section-badges-content d-flex align-items-center">
                        <div className="badges-area">
                            <Badge>Arms</Badge>{' '}
                            <Badge>Body</Badge>{' '}
                            <Badge>Background</Badge>{' '}
                            <Badge>Legs</Badge>{' '}
                            <Badge>Horns</Badge>{' '}
                            <Badge>Face</Badge>{' '}
                        </div>
                    </div>
                    <h4 className="mb-0 h4-second">Possible Combination: 53461</h4>
                </div>
                <Accordion defaultActiveKey="0" className="manage-nfts-accordion">
                    {NftsDetailAccordion.map((e,index) =>
                     <Accordion.Item eventKey={`${index}`}   >
                     <Accordion.Header><h3 className="mb-0">{e.title}</h3></Accordion.Header>
                     <Accordion.Body>
                     <div className="accordian-images-wrapper d-flex flex-wrap"> 
                         {miniCard.map((i)=>
                         <div className="accordian-images-wrapper-img d-flex align-items-center justify-content-center"> <Image src={i.image} fluid/></div>                
                         )}
                         </div>  
                     </Accordion.Body>
                 </Accordion.Item>
                    )}
                   
                </Accordion>
                <div className="select-content">
                    <h5>Select Category</h5>
                    <Form.Select>
                        <option selected></option>
                        <option>one</option>
                        <option>two</option>
                        <option>three</option>
                    </Form.Select>
                </div>
                <div className="file-uploader">
                    <h5>Upload Folder</h5>
                    <div className="uploader-content text-center">
                        <div className="upload-logo">
                            <Image src="images/uploader-icon.svg" fluid/>
                        </div>
                        <h5>Upload or Drag & Drop Folder</h5>
                        <span>Image size will be same in height & width (1080x1080)</span>
                        <a href="/">Upload</a>
                        <Form.Control type="file" className="choose-file"/>
                    </div>
                </div>
            </div>

        </>
    )

}

export default NftDetail;