import $ from 'jquery';

$(document).ready(function () {
    if (window.matchMedia('(max-width: 991px)').matches) {
        $('.dashboard-area').addClass('hide');
    }
});
$('.toggler-bars').click(function () {

    if ($('.dashboard-area').hasClass('hide')) {
        if (window.matchMedia('(max-width: 991px)').matches) {
            $('.dashboard-area').css('position', 'absolute');
            $('.toggler-bars').css('left', '340px');
            $('.toggler-bars').css('z-index', '100');
            $('.dashboard-area').removeClass("hide");
            $('.dashboard-area').css('left', '0');
            $('.dashboard-area').css('opacity', '1');
            $('.dashboard-area').css('z-index', '1000');
        }
        if (window.matchMedia('(min-width: 992px)').matches) {
            $('.dashboard-area').css('left', '0');
            $('.dashboard-area').removeClass("hide");
            $('.dashboard-area').css('opacity', '1');
            $('.toggler-bars').css('z-index', '100');
            $('.dashboard-area').css('z-index', '1000');
            $('.toggler-bars').css('left', '0%');
        }
    }
    else {
        if (window.matchMedia('(min-width: 992px)').matches) {
            $('.toggler-bars').css('left', '-300px');
            $('.dashboard-area').css('left', '-15.5%');
            $('.dashboard-area').css('opacity', '0');
            $('.toggler-bars').css('z-index', '100');
            $('.dashboard-area').css('z-index', '0');
            $('.dashboard-area').addClass("hide");
           
        }
        if (window.matchMedia('(max-width: 991px)').matches) {
            $('.dashboard-area').addClass("hide");
            $('.dashboard-area').css('left', '-35%');
            $('.dashboard-area').css('z-index', '0');
            $('.toggler-bars').css('z-index', '100');
            $('.toggler-bars').css('left', '0%');
           

        }

    }


})

    // $(document).ready(function(){
    //     var fullpath =window.location.pathname;
    //     var filename=fullpath.replace(/^.*[\\\/]/, '');
    //     //alert(filename);
    //     var currentLink=$('a[href="' + filename + '"]'); //Selects the proper a tag
    //     currentLink.addClass("active");
    //     });




