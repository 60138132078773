/* eslint-disable */
import { lazy, Suspense, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
// import { ClimbingBoxLoader } from "react-spinners";
import AlertToaster from "../../ReuseableComps/Toast";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import "../../app.css";
import PrivacyPolicy from "../../components/GeneralPage/PrivacyPolicy";
import TermsAndConditions from "../../components/GeneralPage/TermsAndConditions";
import FullPageLoader from "ReuseableComps/FullPageLoader/full-page-loader";

const Homepage = lazy(() => import("../../pages/auth/homepage"));
const Login = lazy(() => import("../../pages/auth/login"));
const Signup = lazy(() => import("../../pages/auth/signup"));
const ForgotPassword = lazy(() => import("../../pages/auth/forgotPassword"));
const ResetPassword = lazy(() => import("../../pages/auth/resetPassword"));

const AuthRoutes = (props) => {
  const { setTostify, tostify } = props;
  const location = useLocation();
  useEffect(() => {
    tostify.dismissAll();
  }, [location.pathname]);
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };
  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <FullPageLoader />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  const TostifyWait = () => {
    const [showTostify, setShowTostify] = useState(false);
    useEffect(() => {
      let timeout = "";
      if (location.pathname === "/dashboard") {
        let timeout = setTimeout(() => setShowTostify(true), 300);
      } else {
        let timeout = setTimeout(() => setShowTostify(true), 0);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        {showTostify && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AlertToaster setTostify={setTostify} {...tostify} />
          </motion.div>
        )}
      </>
    );
  };


  return (
    <>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <TostifyWait />
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path={["/home", "/login", "/signup", "/forgot-password", "/reset-password/:userid/:code", "/privacy-policy", "/term-and-condition" ]}>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}
                  style={{ height: "100%" }}
                >
                  <Route path="/home" component={Homepage} />
                  <Route path="/login" component={Login} />
                  <Route path="/signup" component={Signup} />
                  <Route path="/forgot-password" component={ForgotPassword} />
                  <Route path="/reset-password/:userid/:code" component={ResetPassword} />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Route path="/term-and-condition" component={TermsAndConditions} />
                </motion.div>
              </Switch>
            </Route>
            <Route render={() => <Redirect to="/home" />} />
          </Switch>
        </Suspense>
      </AnimatePresence>{" "}
    </>
  );
};
export default AuthRoutes;
