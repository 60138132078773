import React from "react";
import { Image, Col } from "react-bootstrap";


const NftDetailContent = (props) =>{
    return (
        <>
            <Col md={4}>
                <div className="created-nft-header-subscription-img d-flex align-items-center justify-content-center">
                    <Image src={`images/${props.image}.png`} fluid/>
                </div>
            </Col>
            
        </>
    )
            
}

export default NftDetailContent;