/* eslint-disable */
import { Formik } from "formik";
import { useCallback, useContext } from "react";
import FormikFieldDouble from "../DoubleLineFormikForm";
import { CardHeader, Row, Col } from "reactstrap";
import { SideBarBgContext } from "../../app";
const GenericFormikForm = (props) => {
	const {
		heading,
		data,
		initialValues,
		getValues,
		schema,
		formFields,
		update,
		formFields1,
		formFields2,
		formFields3,
		headingFormFields1,
		headingFormFields2,
		headingFormFields3,
		networks,
		categories,
		setselectedFiles,
		tostify,
		setTostify,
		setSelectedNetwork,
		getCollectionByNetwork,
		selectedNetwork,
		TableMiniHeadings,
		fetchData,
		checkNetwork,
		handleMint,
		upload,
		supply,
		changeNetworkConnectMeta,
		GetWalletButton,
		GetPublishButton,
		GetDeployButton,
		handlePublish,
		handleConnect,
		handleDeploy,
		pubPhase,
		walletPhase,
		deployPhase,
		GenericForms,
		setNetworkName,
		networkName,
		role,
		setFolderHash,
		btnDisable,
		remainingNft,
		getCollectionByUser,
		usersAll
	} = props;
	const [color, setColor] = useContext(SideBarBgContext);

	const SubmitForm = useCallback(
		(data, actions) => {
			if (heading === "Live Collections") {
				handleMint(data, actions);
			}
			if (heading === "Collections") {
				pubPhase && handlePublish(data, actions);
				walletPhase && handleConnect(data, actions);
				deployPhase && handleDeploy();
			} else {
				update(data, actions);
			}
		},
		[update]
	);

	return (
		<>

			<Row>
				<Col md="12">
					<div className={`${role === "user" ? 'card-user card role-user' : 'card-user card'}`}>
						<div className={`card-body ${heading === 'Password' ? 'form-center' : ''}`}>
							<p className="card-text"></p>
							{role === "user" ? '' :
								<div className="author">
									<CardHeader>
										<h3
											className={'content-heading text-primary d-flex'}
										>
											{" "}
											{heading ? heading : "HEADING HERE"}
										</h3>
									</CardHeader>
								</div>}
							<Formik
								enableReinitialize={false}
								initialValues={
									heading === "Banner" ||
										heading === "About Us" ||
										heading === "Services" ||
										heading === "How It Works" ||
										heading === "Featured Projects" ||
										heading === "Contact Us" ||
										heading === "FAQ" ||
										heading === "Edit Plans" ||
										heading === "Site Settings" ||
										heading === "Profile" ||
										heading === "Pricing Plans" ||
										heading === "Collections"
										? getValues(data)
										: initialValues
								}
								onSubmit={(values, actions) => {
									SubmitForm(values, actions);
								}}
								validationSchema={schema}
							>
								{(props) => {
									return (
										<>
											<FormikFieldDouble
												color={color}
												checkNetwork={checkNetwork}
												fetchData={fetchData}
												TableMiniHeadings={TableMiniHeadings}
												selectedNetwork={selectedNetwork}
												setSelectedNetwork={setSelectedNetwork}
												getCollectionByNetwork={getCollectionByNetwork}
												getCollectionByUser={getCollectionByUser}
												usersAll={usersAll}
												networks={networks}
												categories={categories}
												setselectedFiles={setselectedFiles}
												formFields={formFields}
												formFields1={formFields1}
												headingFormFields1={headingFormFields1}
												headingFormFields2={headingFormFields2}
												formFields2={formFields2}
												headingFormFields3={headingFormFields3}
												formFields3={formFields3}
												{...props}
												heading={heading}
												tostify={tostify}
												setTostify={setTostify}
												upload={upload}
												supply={supply}
												changeNetworkConnectMeta={changeNetworkConnectMeta}
												GetWalletButton={GetWalletButton}
												GetPublishButton={GetPublishButton}
												GetDeployButton={GetDeployButton}
												handlePublish={handlePublish}
												handleConnect={handleConnect}
												handleDeploy={handleDeploy}
												pubPhase={pubPhase}
												walletPhase={walletPhase}
												deployPhase={deployPhase}
												GenericForms={GenericForms}
												setNetworkName={setNetworkName}
												networkName={networkName}
												role={role}
												setFolderHash={setFolderHash}
												userImagePath={data}
												btnDisable={btnDisable}
												remainingNft={remainingNft}
											/>
										</>
									);
								}}
							</Formik>
						</div>
					</div>
				</Col>
			</Row>
		</>
	);
};
export default GenericFormikForm;
