import * as Yup from "yup";
export const getInitialValues = data => {
    return {
        mainHead: data.mainHead,
        description: data.description,
        type: data.type,
    }
};

export const FeaturesProjectsFormFields = [
    {
        field: "Main Heading",
        fieldName: "mainHead",
        place: "",
        type: "text",
        required: true,
    },
    {
        field: "Description",
        fieldName: "description",
        place: "",
        type: "description",
        required: true,
    }
];

export const FeaturesProjectsSchema = Yup.object().shape({
    mainHead: Yup.string().trim('Required').required('Required'),
    description: Yup.string().trim('Required').required('Required'),
});
