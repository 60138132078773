import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";
import { Nav } from "reactstrap";
import { Collapse } from "@material-ui/core";

const MainMenuItems = ({
  item,
  activeRoute,
  rtlActive,
  open,
  setOpen,
  openCMS,
  setOpenCMS,
}) => {

  return (
    <>
      <li
        className={activeRoute(item.path) + (item.pro ? " active-pro" : "")}
        key={Math.random().toString(36).substring(7)}
      >
        {item.name === "PROFILE" || item.name === "CMS" ? (
          <>
            <span
              onClick={
                item.name === "PROFILE"
                  ? () => {
                    setOpen(!open);
                    setOpenCMS(false)
                  }
                  : item.name === "CMS"
                    ? () => {
                      setOpenCMS(!openCMS)
                      setOpen(false)
                    }
                    : null
              }
              className={"nav-link MainMenuItems"}
              aria-haspopup="true"
              data-toggle="collapse"
              aria-expanded={!openCMS ? "false" : "true"}
            >
              <i className={item.icon} />
              <p>
                {rtlActive ? item.rtlName : item.name} <b className="caret"></b>
              </p>
            </span>
          </>
        ) : (
          <NavLink
            activeClassName="active"
            className="nav-link"
            onClick={item.toggleSidebar}
            aria-haspopup="true"
            aria-expanded="false"
            to={item.path}
          >
            <i className={item.icon} />
            <p>{rtlActive ? item.rtlName : item.name}</p>
          </NavLink>
        )}
      </li>
    </>
  );
};
const SubMenuItems = ({ open, options, activeRoute, rtlActive }) => {
  return (
    <>
      {options.map((item, index) => {
        return (
          <Collapse
            key={
              "item.title" +
              Math.random().toString(36).substring(7) +
              "collpase"
            }
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <li
              className={
                activeRoute(item.path) + (item.pro ? " active-pro" : "")
              }
              key={Math.random().toString(36).substring(7)}
            >
              <NavLink
                activeClassName="active"
                className="nav-link"
                onClick={item.toggleSidebar}
                aria-haspopup="true"
                aria-expanded="false"
                to={item.path}
              >
                <i className={item.icon} />
                <p>{rtlActive ? item.rtlName : item.name}</p>
              </NavLink>
            </li>
          </Collapse>
        );
      })}
    </>
  );
};
const CMSSubMenu = ({ activeRoute, openDropdown, SubMenu }) => {
  return SubMenu.map((item, index) => {
    return (
      <Collapse
        key={"collapse" + item.title + Math.random().toString(36).substring(7)}
        in={openDropdown}
        timeout="auto"
        unmountOnExit
      >
        <li
          className={activeRoute(item.path) + (item.pro ? " active-pro" : "")}
          key={Math.random().toString(36).substring(7)}
        >
          <NavLink
            activeClassName="active"
            className="nav-link"
            onClick={item.toggleSidebar}
            aria-haspopup="true"
            aria-expanded="false"
            to={item?.path}
          >
            <i className={item.icon} />
            <p>{item.name}</p>
          </NavLink>
        </li>
      </Collapse>
    );
  });
};

const CMSSubMenuItems = ({
  activeRoute,
  rtlActive,
  openCMS,
  AdminSideBarCMSSubMenu,
  AdminSideBarCMSPricingSubMenu,
  AdminSideBarCMSFaqsSubMenu,
  openPricingPlan,
  setOpenPricingPlan,
  openFaqs,
  setOpenFaqs,
}) => {
  return (
    <>
      {AdminSideBarCMSSubMenu.map((item, index) => {
        return (
          <Collapse
            key={
              "collapse" + item.title + Math.random().toString(36).substring(7)
            }
            in={openCMS}
            timeout="auto"
            unmountOnExit
          >
            {item.name === "PRICING PLANS" ? (
              <>
                <span
                  onClick={
                    item.name === "PRICING PLANS"
                      ? () => {
                        setOpenPricingPlan(!openPricingPlan);
                        setOpenFaqs(false);
                      }
                      : ""
                  }
                  className={"nav-link CMSSubMenuItems"}
                  aria-haspopup="true"
                  data-toggle="collapse"
                  aria-expanded={!openCMS ? "false" : "true"}
                >
                  <i className={item.icon} />

                  <p>
                    {rtlActive ? item.rtlName : item.name} {" "}
                    <b className="caret"></b>
                  </p>
                </span>
                <CMSSubMenu
                  activeRoute={activeRoute}
                  openDropdown={openPricingPlan}
                  SubMenu={AdminSideBarCMSPricingSubMenu}
                />
              </>
            ) : item.name === "FAQ" ? (
              <>
                <span
                  onClick={
                    item.name === "FAQ"
                      ? () => {
                        setOpenFaqs(!openFaqs);
                        setOpenPricingPlan(false);
                      }
                      : ""
                  }
                  className={"nav-link CMSSubMenuItems"}
                  aria-haspopup="true"
                  data-toggle="collapse"
                  aria-expanded={!openCMS ? "false" : "true"}
                >
                  <i className={item.icon} />

                  <p>
                    {rtlActive ? item.rtlName : item.name}{" "}
                    <b className="caret"></b>
                  </p>
                </span>
                <CMSSubMenu
                  activeRoute={activeRoute}
                  openDropdown={openFaqs}
                  SubMenu={AdminSideBarCMSFaqsSubMenu}
                />
              </>
            ) : (
              <li
                className={
                  activeRoute(item.path) + (item.pro ? " active-pro" : "")
                }
                key={Math.random().toString(36).substring(7)}
              >
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  onClick={item.toggleSidebar}
                  aria-haspopup="true"
                  aria-expanded="false"
                  to={item.path}
                >
                  <i className={item.icon} />

                  <p>{rtlActive ? item.rtlName : item.name}</p>
                </NavLink>
              </li>
            )}
          </Collapse>
        );
      })}
    </>
  );
};

const SidebarComp = (props) => {
  const {
    color,
    routes,
    rtlActive,
    AdminSideBarCMSSubMenu,
    AdminSideBarCMSPricingSubMenu,
    AdminSideBarProfileSubMenu,
    AdminSideBarCMSFaqsSubMenu,
  } = props;

  const [openCMS, setOpenCMS] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [openPricingPlan, setOpenPricingPlan] = React.useState(false);
  const [openFaqs, setOpenFaqs] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === '/profile' || location.pathname === '/password') {
      setOpen(true)
    }
    if (location.pathname === '/banner' || location.pathname === '/aboutmulti' || location.pathname === "/services" || location.pathname === '/howItWorks' || location.pathname ===  "/featuredProjects") {
      setOpenCMS(true)
    }
    if (location.pathname === '/plans' || location.pathname === '/plansListing') {
      setOpenPricingPlan(true)
      setOpenCMS(true)
    }
    if (location.pathname === '/faqs' || location.pathname === '/faqsList') {
      setOpenFaqs(true)
      setOpenCMS(true)
    }
  }, [location.pathname])

  const activeRoute = (routeName) => {
    return location.pathname === routeName ? "active" : "";
  };

  return (
    <div className="sidebar" data={color}>
      <div className="sidebar-wrapper">
        <Nav>
          {routes?.map((item, key) => {
            return (
              <>
                {item ? (
                  <MainMenuItems
                    key={Math.random().toString(36).substring(7)}
                    item={item}
                    activeRoute={activeRoute}
                    rtlActive={rtlActive}
                    open={open}
                    setOpen={setOpen}
                    openCMS={openCMS}
                    setOpenCMS={setOpenCMS}
                  />
                ) : null}
                {item.name === "CMS" && (
                  <CMSSubMenuItems
                    activeRoute={activeRoute}
                    rtlActive={rtlActive}
                    AdminSideBarCMSSubMenu={AdminSideBarCMSSubMenu}
                    AdminSideBarCMSPricingSubMenu={
                      AdminSideBarCMSPricingSubMenu
                    }
                    AdminSideBarCMSFaqsSubMenu={AdminSideBarCMSFaqsSubMenu}
                    key={item.title + Math.random().toString(36).substring(7)}
                    openCMS={openCMS}
                    openPricingPlan={openPricingPlan}
                    setOpenPricingPlan={setOpenPricingPlan}
                    openFaqs={openFaqs}
                    setOpenFaqs={setOpenFaqs}
                  />
                )}
                {item.name === "PROFILE" && (
                  <>
                    <SubMenuItems
                      activeRoute={activeRoute}
                      rtlActive={rtlActive}
                      key={item.title + Math.random().toString(36).substring(7)}
                      open={open}
                      options={AdminSideBarProfileSubMenu}
                    />
                  </>
                )}
              </>
            );
          })}
        </Nav>
      </div>
    </div>
  );
};
SidebarComp.defaultProps = {
  rtlActive: false,
  routes: [{}],
};
SidebarComp.propTypes = {
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    text: PropTypes.node,
    imgSrc: PropTypes.string,
  }),
};
export default SidebarComp;
