import React from "react";
import { Row, Col } from "react-bootstrap";
import NftDetail from "./NftDetail";
import NftPreview from "./NftPreview";


const ManageNfts = (props) => {
    return (
        <div className="content">
            <Row>
                <Col lg={12} xl={8}>
                    <NftDetail />
                </Col>
                <Col lg={12} xl={4}>
                    <NftPreview />
                </Col>
            </Row>
        </div>
    )

}

export default ManageNfts;