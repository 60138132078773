/* eslint-disable */
import { useState, useEffect } from "react";
import { CardBody, Table, Row, Col } from "reactstrap";
import { TableContainer } from "@material-ui/core";
import Header from "./Header";
import TableRow from "./TableRow";
const GenericTableMini = (props) => {
  const { TableMiniHeadings, values, fieldName, role } = props;
  const [rowData, setRowData] = useState([]);
  const [contractData, setContractData] = useState([]);
  useEffect(() => {
    setRowData(values[fieldName]);
  }, [values[fieldName]]);
  useEffect(() => {
    setContractData(values["contractData"]);
  }, [values["contractData"]]);
  return (
    <>
      <div className="content w-100">
        <Row>
          <Col md="12">
            <div className={`${role === "user" ? 'card-user card role-user' : 'card-user card'}`}>
              <div className="card-body">
                {role === 'user' ? '' : <div className="author">
                </div>}
                <CardBody>
                  <TableContainer>
                    <h3>Selected Collection</h3>
                    <Table className="tablesorter table">
                      <Header Columns={TableMiniHeadings} />
                      <tbody>
                        {rowData.length > 0 && contractData.length > 0 ? (
                          <TableRow
                            row={rowData[0]}
                            contractData={contractData}
                          />
                        ) : null}
                      </tbody>
                    </Table>
                  </TableContainer>
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default GenericTableMini;
