
export const ContactUsTableHeadings = [
    {
        id: "name",
        label: "Name",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "email",
        label: "Email",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "subject",
        label: "Subject",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "message",
        label: "Message",
        sort: true,
        class: "bg-body-light",
    },
];

export const ContactUsInitialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
};

export const createRows = (rowData) => {
    const rows = rowData.map((i) => {
        return {
            id: i._id,
            name: i.name,
            email: i.email,
            subject: i.subject,
            message: i.message,
        };
    });
    return rows;
};
