import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import './full-page-loader.css';

function FullPageLoader(props) {
	const [componentInitialCall, setInitialCall] = useState(true)
	useEffect(() => {
		if (componentInitialCall) {
			AOS.init();
			setInitialCall(false);
		}
	}, [componentInitialCall]);

	return (
		<div className='full-loader'>
			<div class="outer">
				<div class="loader">
					<span></span>
					<p>Loading</p>
				</div>
			</div>
		</div>
	);
};

export default FullPageLoader;