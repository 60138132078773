import React, { useEffect } from 'react';
import Header from "components/Homepage/Header";
import Footer from 'components/Homepage/Footer';

const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Header />
            <div className="wrapper">
                <div className="login-main all-content-page">
                    <div className="container-width">
                        <div className="privacy-list">
                            <div className='mb-5 text-center'>
                                <h2>Terms and Conditions</h2>
                                <p className="mb-4">
                                    MutoLab is a distributed application that runs on multiple networks, using specially-developed smart contracts (each, a “Smart Contract”) to enable users to create, deploy and mint NFTs, which can then be visualized on a website that the user can interact with (the “Site”). The Smart Contracts and the Site are collectively referred to in these Terms as the “Service.” Using the Service, users can view their collections.
                                </p>
                                <p className='mb-4'>Nothing But MutoLab is making the Service available to you. </p>
                                <p>
                                    Before you use the Service, the Smart Contracts, or the Site, you must agree to these Terms of Use and any terms and conditions incorporated herein by reference (collectively, these "Terms"). Please read these terms carefully before using the service, the smart contracts, or the site. These terms govern your use of the service, the smart contracts, and the site unless we have executed a separate written agreement with you for that purpose. We are only willing to make the service, the smart contracts, and the site available to you if you accept all of these terms. By using the service, the smart contracts, the site, or any part of them, you are confirming that you understand and agree to be bound by all of these terms. For example, suppose you accept these terms on behalf of a company or legal entity. In that case, you represent that you have the legal authority to accept these terms on that entity’s behalf, in which case “you” will mean that entity. If you do not have such authority or do not accept all of these terms, then we are unwilling to make the service, smart contracts, or site available to you. If you do not agree to these terms, you may not access or use the service, the smart contracts, or the site.
                                </p>
                            </div>
                            <div className="mb-4">
                                <h3><b>1. </b>The Service</h3>
                                <div className='mb-4'>
                                    <p><b>A - </b>Each MutoLab NFT is a non-fungible token (an “NFT”) on the blockchain. When you create a MutoLab NFT, you own the underlying NFT completely. This means that you have the right to deploy your NFT, mint it, and publish it. Ownership of the NFT is mediated entirely by the Smart Contract and the multiple networks: at no point will we seize, freeze, or otherwise modify the ownership of any MutoLab NFT.</p>
                                    <p><b>B - </b>Transactions on the Service are managed and confirmed via the blockchain. Therefore, you understand that your public address will be made publicly visible whenever you engage in a transaction on the Service.
                                    </p>
                                    <p><b>C - </b>We neither own nor control MetaMask, Coinbase, Google Chrome, multiple networks, or any third-party site, product, or service that you might access, visit, or use to enable you to use the various features of the Service. Accordingly, we will not be liable for the acts or omissions of any such third parties, nor will we be responsible for any damage you may suffer due to your transactions or any other interaction with any such third parties.</p>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <h3><b>2. </b>Ownership, License, Restrictions</h3>
                                <h4><b>A.</b> Ownership</h4>
                            </div>
                            <div className='mb-4'>
                                <h5><b>i.</b> You create the NFT.</h5>
                                <p>Each MutoLab NFT is a non-fungible token (an “NFT”) on the blockchain. When you create a MutoLab NFT, you own the underlying NFT completely. This means that you have the right to deploy your NFT, mint it, and publish it. Ownership of the NFT is mediated entirely by the Smart Contract and the multiple networks: at no point will we seize, freeze, or otherwise modify the ownership of any MutoLab NFT.</p>
                            </div>
                            <div className='mb-4'>
                                <h5><b>ii.</b> MutoLab Owns the Service.</h5>
                                <p className='mb-4'>You acknowledge and agree that MutoLab (or, as applicable, our licensors) owns all legal rights, titles, and interests in and to all other elements of the Service, and all intellectual property rights therein (including, without limitation, all Art, designs, systems, methods, information, computer code, software, services, “look and feel”, organization, a compilation of the content, code, data, and all other elements of the Service (collectively, the “MutoLab Materials”)). You acknowledge that the MutoLab Materials are protected by copyright, trade dress, patent, and trademark laws, international conventions, other relevant intellectual property and proprietary rights, and applicable laws. All MutoLab Materials are the copyrighted property of MutoLab or its licensors, and all trademarks, service marks, and trade names associated with the Service or otherwise contained in the MutoLab Materials are proprietary to MutoLab or its licensors. Except as expressly set forth herein, your use of the Service does not grant you ownership of or any other rights with respect to any content, code, data, or other MutoLab Materials that you may access on or through the Service. We reserve all rights in and to the MutoLab Materials that are not expressly granted to you in these Terms.</p>
                                <p className='mb-4'><b>For the sake of clarity, you understand and agree:</b> </p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>(a)</b> That your creation, deploy, mint or publishing of a MutoLab NFT, whether via the Service or otherwise, does not give you any rights or licenses in or to the MutoLab Materials (including, without limitation, our copyright in and to the associated Art) other than those expressly contained in these Terms;</p>
                                <p className='mb-4'><b>(b)</b> that you do not have the right, except as otherwise outlined in these Terms, to reproduce, distribute, or otherwise commercialize any elements of the MutoLab Materials (including, without limitation, any Art) without our prior written consent in each case which consent we may withhold in our sole and absolute discretion; and</p>
                                <p className='mb-4'><b>(c)</b> that you will not apply for, register, or otherwise use or attempt to use any MutoLab trademarks or service marks, or any confusingly similar marks, anywhere in the world without our prior written consent in each case, which consent we may withhold at our sole and absolute discretion.</p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>B.</b> License to NFTs</h4>
                            </div>
                            <div className='mb-4'>
                                <h5><b>i. General Use</b></h5>
                                <p>Subject to your continued compliance with these Terms, MutoLab grants you a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display your minted MutoLab NFTs solely for the following purposes: </p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>(i)</b> for your own personal, non-commercial use;</p>
                                <p className='mb-4'><b>(ii)</b> as part of a marketplace that permits the purchase and sale of your minted MutoLab NFT, provided that the market cryptographically verifies each NFT owner’s rights to display the Art for their minted MutoLab NFT to ensure that only the actual owner can show the Art; or</p>
                                <p className='mb-4'><b>(iii)</b> as part of a third-party website or application that permits the inclusion, involvement, or participation of your minted MutoLab NFT, provided that the website/application cryptographically verifies each NFT owner’s rights to display the Art for their minted MutoLab NFT to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the minted MutoLab NFT leaves the website/application.</p>
                            </div>
                            <div className='mb-4'>
                                <h5><b>ii. Commercial Use</b></h5>
                                <p>Subject to your continued compliance with these Terms, MutoLab grants you a limited, worldwide, non-exclusive, non-transferable license to use, copy, and display the Art for your minted MutoLab NFT to commercialize your own merchandise that includes, contains, or consists of the Art for your minted MutoLab NFT (“Commercial Use”), provided that such Commercial Use does not result in you earning more than One Hundred Thousand Dollars ($100,000) in gross revenue each year. For the sake of clarity, nothing in this Section will be deemed to restrict you from  </p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>(i)</b> owning or operating a marketplace that permits the use and sale of MutoLab NFTs generally, provided that the marketplace cryptographically verifies each owner’s rights to display the Art for their NFT to ensure that only the actual owner can display the Art; </p>
                                <p className='mb-4'><b>(ii)</b> owning or operating a third-party website or application that permits the inclusion, involvement, or participation of MutoLab NFTs generally, provided that the third-party website or application cryptographically verifies each owner’s rights to display the Art for their minted MutoLab NFT to ensure that only the actual owner can show the Art, and provided that the Art is no longer visible once the owner of the minted MutoLab NFT leaves the website/application; or </p>
                                <p className='mb-4'><b>(iii)</b> earning revenue from any of the foregoing, even where such payment is in excess of $100,000 per year.</p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>C.</b> Restrictions</h4>
                            </div>
                            <div className='mb-4'>
                                <p>You agree that you may not, nor permit any third party to do or attempt to do any of the foregoing without MutoLab’s express prior written consent in each case: </p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>(i)</b> modify the Art for your minted MutoLab NFT in any way, including, without limitation, the shapes, designs, drawings, attributes, color schemes, or any color correction or modification tools (your use of Extensions will not constitute a prohibited modification hereunder); </p>
                                <p className='mb-4'><b>(ii)</b> use the Art for your minted MutoLab NFT to advertise, market, or sell any third-party product or service;</p>
                                <p className='mb-4'><b>(iii)</b>use the Art for your minted MutoLab NFT in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others;</p>
                                <p className='mb-4'><b>(iv)</b> use the Art for your minted MutoLab NFT in movies, videos, or any other forms of media, except to the limited extent that such use is expressly permitted in these Terms or solely for your own personal, non-commercial use; </p>
                                <p className='mb-4'><b>(v)</b> sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art for your minted MutoLab NFT, except as expressly permitted in these Terms; </p>
                                <p className='mb-4'><b>(vi)</b>attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for your minted MutoLab NFT; or</p>
                                <p className='mb-4'><b>(vii)</b> otherwise utilize the Art for your minted MutoLab NFT for your or any third party’s commercial benefit. To the extent that Art associated with your minted MutoLab NFT contains Third Party IP (e.g., licensed intellectual property from a celebrity, athlete, or other public figures), you understand and agree as follows: </p>
                                <p className='mb-4'><b>(viii)</b> that you will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein;</p>
                                <p className='mb-4'><b>(ix)</b>that the Commercial Use license in Section 3.C(ii) above will not apply; </p>
                                <p className='mb-4'><b>(x)</b>  that, depending on the nature of the license granted from the owner of the Third Party IP, MutoLab may need to pass through additional restrictions on your ability to use the Art; and  </p>
                                <p className='mb-4'><b>(xi)</b> to the extent that MutoLab informs you of such additional restrictions in writing (email is permissible), you will be responsible for complying with all such restrictions from the date that you receive the notice, and that failure to do so will be deemed a breach of this license. The restrictions in this Section will survive the expiration or termination of these Terms.</p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>D.</b> Other Terms of License</h4>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'>The license granted applies only to the extent that you continue to Own the applicable minted MutoLab NFT. If at any time you sell, trade, donate, give away, transfer, or otherwise dispose of your minted MutoLab NFT for any reason, the license granted will immediately expire with respect to that NFT without the requirement of notice, and you will have no further rights in or to the Art for that NFT. If you exceed the $100,000 limitation on annual gross revenue outlined you will be in breach of these Terms and must send an email to MutoLab at <a href="mailto:muto@metamuto.com">muto@metamuto.com</a> within forty-five (45) days, with the phrase “MutoLab NFT License - Commercial Use” in the subject line, requesting a discussion with MutoLab regarding entering into a broader license agreement or obtaining an exemption (which may be granted or withheld in MutoLab’s sole and absolute discretion). If you exceed the scope of the license grant without entering into a broader license agreement with or obtaining an exemption from MutoLab, you acknowledge and agree that: (i) you are in breach of these Terms; (ii) in addition to any remedies that may be available to MutoLab at law or in equity, MutoLab may immediately terminate the license that was granted to you without the requirement of notice; and (iii) you will be responsible to reimbursing MutoLab for any costs and expenses incurred by MutoLab during the course of enforcing these Terms against you.</p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>E.</b> Your Queries and Feedback</h4>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'>You may choose to submit comments, bug reports, ideas, or other feedback about the Service, including without limitation about how to improve the Service by submitting your query in the “still have questions” section on the home page. By submitting any queries/feedback, you agree that we are free to use such queries/feedback at our discretion and without additional compensation to you and to disclose such queries/feedback to third parties (whether on a non-confidential basis or otherwise). You now grant us a perpetual, irrevocable, nonexclusive, worldwide license under all rights necessary for us to incorporate and use your queries/feedback for any purpose.</p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>F.</b> Your Obligations</h4>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'>You agree that you are responsible for your own conduct while accessing or using the Service, and for any consequences thereof. You agree to use the Service solely for purposes that are legal, proper and following these Terms and applicable laws or regulations. By way of example, and not as a limitation, you may not, and may not allow any third party to </p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>(i)</b> send, upload, distribute or disseminate any unlawful, defamatory, harassing, abusive, fraudulent, obscene, or otherwise objectionable content; </p>
                                <p className='mb-4'><b>(ii)</b> distribute viruses, worms, defects, Trojan horses, corrupted files, hoaxes, or any other items of a destructive or deceptive nature; </p>
                                <p className='mb-4'><b>(iii)</b> impersonate another person (via the use of an email address or otherwise); </p>
                                <p className='mb-4'><b>(iv)</b> upload, post, transmit, or otherwise make available through the Service any content that infringes the proprietary intellectual rights of any party; </p>
                                <p className='mb-4'><b>(v)</b> use the Service to violate the legal rights (such as rights of privacy and publicity) of others;</p>
                                <p className='mb-4'><b>(vi)</b>engage in, promote, or encourage illegal activity (including, without limitation, money laundering); </p>
                                <p className='mb-4'><b>(vii)</b> interfere with other user's enjoyment of the Service;  </p>
                                <p className='mb-4'><b>(viii)</b> exploit the Service for any unauthorized commercial purpose; </p>
                                <p className='mb-4'><b>(ix)</b>modify, adapt, translate, or reverse engineer any portion of the Service;</p>
                                <p className='mb-4'><b>(x)</b>  remove any copyright, trademark, or other proprietary rights notices contained in or on the Service or any part of it;  </p>
                                <p className='mb-4'><b>(xi)</b> reformat or frame any portion of the Service; </p>
                                <p className='mb-4'><b>(xii)</b> display any content on the Service that contains any hate-related or violent content or contains any other material, products, or services that violate or encourage conduct that would violate any criminal laws, any other applicable laws, or any third party rights;  </p>
                                <p className='mb-4'><b>(xiii)</b> use any robot, spider, site search/retrieval application, or other devices to retrieve or index any portion of the Service or the content posted on the Service or to collect information about its users for any unauthorized purpose;  </p>
                                <p className='mb-4'><b>(xiv)</b> create user accounts by automated means or under false or fraudulent pretenses, or  </p>
                                <p className='mb-4'><b>(xv)</b> access or use the Service to create a competitive product or service with any of our products or services. If you engage in any of the activities prohibited by this Section, we may, at our sole and absolute discretion, without notice to you and without limiting any of our other rights or remedies at law or in equity, immediately suspend or terminate your user account and/or delete your MutoLab NFTs’ images and descriptions from the Service and the Site. If we delete your MutoLab NFTs’ pictures and reports from the Service and/or the Site, such deletion will not affect your ownership rights in any NFTs you already Own.</p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>3.</b> Fees and Payment</h4>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>A.</b> If you elect to create, deploy, mint, or publish MutoLab NFTs on the Service, any financial transactions that you engage in will be conducted solely through the multiple networks. It must be noted that we have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. With that in mind, we will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage in via the Service or using the Smart Contracts, or any other transactions that you conduct via the multiple networks. </p>
                                <p className='mb-4'><b>B.</b> Wallets require the payment of a transaction fee for every transaction that occurs on multiple networks. The transaction Fee funds the network that runs the decentralized multiple networks. This means that you must pay the transaction fee for each transaction that occurs via the Service. </p>
                                <p className='mb-4'><b>C.</b>  As between us, you will be solely responsible for paying any and all utilizations, value-added and other taxes, duties, and assessments (except taxes on our net income) now or hereafter claimed or imposed by any governmental authority (collectively, “Taxes”) associated with your use of the Service (including, without limitation, any Taxes that may become payable as the result of your deployed, minted, or published MutoLab NFTs). Except for income taxes levied on MutoLab, you: (i) will pay or reimburse us for all national, federal, state, local, or other taxes and assessments of any jurisdiction, including value-added taxes and taxes as required by international tax treaties, customs or other import or export taxes, and amounts levied in lieu thereof based on charges set, services performed or payments made hereunder, as are now or hereafter may be imposed under the authority of any national, state, local or any other taxing jurisdiction; and (ii) shall not be entitled to deduct the amount of any such taxes, duties or assessments from payments made to us pursuant to these Terms.</p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>4.</b> Termination</h4>
                                <p className='mb-4'>You may terminate these Terms at any time by canceling your account on the Service and discontinuing your access to and use of the Service. Note that you will not receive any refunds if you cancel your account or otherwise terminate these Terms laid out.</p>
                                <p className='mb-4'>You agree that we, in our sole discretion and for any or no reason, may terminate these Terms and suspend and/or terminate your account(s) for the Service. You agree that any suspension or termination of your access to the Service may be without prior notice and that we will not be liable to you or to any third party for any such suspension or termination. Suppose we terminate these Terms or suspend or terminate your access to or use of the Service due to your breach of these Terms or any suspected fraudulent, abusive, or illegal activity. In that case, termination of these Terms will be in addition to any other remedies we may have at law or in equity.</p>
                                <p className='mb-4'>Upon any termination or expiration of these Terms, whether by you or us, you may no longer have access to information that you have posted on the Service or that is related to your account, and you acknowledge that we will have no obligation to maintain any such information in our databases or to forward any such information to you or to any third party. </p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>5.</b> Disclaimers</h4>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>A.</b> You expressly understand and agree that your access to and use of the service is at your sole risk and that the service is provided "As is" and "As available" without warranties, whether express or implied. To the fullest extent permissible according to applicable law, we, our subsidiaries, affiliates, and licensors make no express warranties. They, with this, disclaim all implied warranties regarding the service and any part of it (including, without limitation, the site, any smart contract, or any external websites), including the implied warranties of merchantability, fitness for a particular purpose, non-infringement, correctness, accuracy, or reliability. Without limiting the generality of the preceding, we, our subsidiaries, affiliates, and licensors do not represent or warrant to you that:  </p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>(i)</b> your access to or use of the service will meet your requirements, </p>
                                <p className='mb-4'><b>(ii)</b> your access to or use of the service will be uninterrupted, timely, secure, or free from error,  </p>
                                <p className='mb-4'><b>(iii)</b> usage data provided through the service will be accurate,  </p>
                                <p className='mb-4'><b>(iv)</b> the service or any content, services, or features made available on or through the service is free of viruses or other harmful components, or  </p>
                                <p className='mb-4'><b>(v)</b>Any data you disclose when you use the service will be secure. Some jurisdictions do not allow the exclusion of implied warranties in contracts with consumers, so some or all of the above exclusions may not apply to you.</p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>B.</b> You accept the inherent security risks of providing information and dealing online over the internet and agree that we have no liability or responsibility for any security breach unless it is due to our gross negligence.</p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>C.</b> We will not be responsible or liable to you for any losses you incur as the result of your use of the multiple networks or the metamask electronic wallet, including but not limited to any losses, damages, or claims arising from  </p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>(i)</b> user error, such as forgotten passwords or incorrectly construed smart contracts or other transactions;  </p>
                                <p className='mb-4'><b>(ii)</b> server failure or data loss; </p>
                                <p className='mb-4'><b>(iii)</b> corrupted wallet files; or </p>
                                <p className='mb-4'><b>(iv)</b> unauthorized access or activities by third parties, including but not limited to the use of viruses, phishing, brute-forcing, or other means of attack against the service, multiple networks, or the metamask electronic wallet. </p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>D.</b> MutoLab NFTs are intangible digital assets that exist only by virtue of the ownership record maintained in the multiple networks. All smart contracts are conducted and occur on the decentralized ledger within the ethereum platform. We have no control over and make no guarantees or promises concerning smart contracts.</p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>E.</b> MutoLab is not responsible for losses due to blockchains or any other features of the multiple networks or the metamask electronic wallet, including but not limited to late report by developers or representatives (or no report at all) of any issues with the blockchain supporting the multiple networks, including forks, technical node issues, or any other issues having fund losses as a result.</p>
                            </div>
                            <div className='mb-4'>
                                <h4><b>6.</b> Limitation of Liability</h4>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>A.</b> You understand and agree that we, our subsidiaries, affiliates, and licensors will not be liable to you or to any third party for any indirect, incidental, special, consequential, or exemplary damages which you may incur, howsoever caused and under any theory of liability, including, without limitation, any loss of profits (whether incurred directly or indirectly), loss of goodwill or business reputation, loss of data, cost of procurement of substitute goods or services, or any other intangible loss, even if we have been advised of the possibility of such damages.</p>
                                <p className='mb-4'><b>B.</b> You agree that our total, aggregate liability to you for any claims arising from or relating to these terms or your access to or use of (or your inability to access or use) any part of the Service, whether in contract, tort, strict liability, or any other legal theory, is limited to the greater of (a) the amounts you have paid us under these terms in the 12 month period preceding the date the claim arose, or (b) $100.</p>
                                <p className='mb-4'><b>C.</b> You understand and agree that we have made the service available to you and entered into these terms in reliance upon the warranty disclaimers and limitations of liability set forth herein, which reflect a reasonable and fair allocation of risk between the parties and form an essential basis of the bargain between us. We would only be able to provide the service to you if we included these limitations.</p>
                                <p className='mb-4'><b>D.</b> Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, and some jurisdictions also limit disclaimers or liability for personal injury from consumer products, so the above limitations may not apply to personal injury claims.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>7.</b> Assumption of risk</h4>
                                <p>You accept and acknowledge each of the following:</p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>A.</b> You are solely responsible for determining what, if any, taxes apply to your MutoLab NFTs-related transactions. MutoLab is not responsible for determining the taxes that may apply to your transactions on the Service, the Site, or the Smart Contracts.</p>
                                <p className='mb-4'><b>B.</b> The Service does not store, send, or receive MutoLab NFTs. This is because MutoLab NFTs exist only by virtue of the ownership record maintained on the Service’s supporting blockchain in the multiple networks. Transfers of MutoLab NFTs occur within the supporting blockchain in the multiple networks and not on the Service.</p>
                                <p className='mb-4'><b>C.</b> There are risks associated with using an Internet-based currency, including, but not limited to, the risk of software, hardware, and Internet connections, the risk of malicious software introduction by yourself or anyone authorized to access your account, and the risk that third parties may obtain unauthorized access to information stored within your wallet. You accept and acknowledge that MutoLab will not be responsible for any communication failures, disruptions, errors, distortions, or delays you might experience while using the multiple networks, however, caused.</p>
                                <p className='mb-4'><b>D.</b> A lack of use or public interest in the creation and development of distributed ecosystems may also negatively impact the development of the MutoLab NFTs ecosystem and, therefore, the potential utility or value of MutoLab NFTs.</p>
                                <p className='mb-4'><b>E.</b> The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain. Therefore, new regulations or policies can materially adversely affect the development or offerings of the MutoLab NFTs ecosystem and, therefore, the potential utility or value of MutoLab NFTs.</p>
                                <p className='mb-4'><b>F.</b> A hard fork in the Ethereum platform, upgrades by Ethereum to the Ethereum platform, or a change in how transactions are confirmed on the Ethereum platform may have unintended, adverse effects on all blockchains using the ERC-21 & ERC-1155 standard, including the MutoLab NFTs ecosystem.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>8.</b> Indemnification</h4>
                                <p>You agree to hold harmless and indemnify MutoLab and its parent, subsidiaries, officers, affiliates, agents, employees, advertisers, licensors, suppliers, or partners from and against any claim made, liability, loss, damage (actual and consequential) of any kind or nature, judgment, suit, litigation cost, and attorneys' fees arising out of or in any way related to</p>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-4'><b>(i)</b> your breach of these Terms, </p>
                                <p className='mb-4'><b>(ii)</b>  your misuse of the Services, or </p>
                                <p className='mb-4'><b>(iii)</b> your violation of applicable laws, rules, or regulations in connection with your access to or use of the Service. You agree that MutoLab will have control of the defense or settlement of any such claims.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>9.</b> User Content</h4>
                                <p className='mb-4'>User Content must be in its entirety, comply with all applicable federal, state, local and international laws and regulations. Without limiting the foregoing, by using the Site and the features thereon, you agree that you will not post any User Content that is unlawful, harmful, tortious, defamatory, obscene, libelous, invasive the privacy, threatening, harassing, pornographic, infringing, or violent; and that you will not post any content that infringes or violates the rights of any third party; that you will not impersonate any person or organization, or misrepresent an affiliation with another person or organization; and you will not post any content that contains viruses, corrupted files, or any other similar software or programs that may adversely affect the operation or features of the Site.</p>
                                <p>User Content that you may post to the Site will be considered non-confidential and non-proprietary. By displaying, transmitting, publishing, or otherwise posting any User Content on or through the Site, you hereby grant to the Company, its service providers, and each of our and their respective licensees, successors, and assigns a non-exclusive, sub-licensable, worldwide, fully-paid, royalty-free right to use, modify, publicly perform, publicly display, reproduce, distribute and otherwise disclose such User Content in any and all media now known or from now on developed. You represent and warrant that you own and control the User Content submitted, displayed, published, transmitted, or otherwise posted by you on the Site and maintain the right to grant the license set forth herein. All of your User Content must comply at all times with these Terms. MutoLab is not responsible or liable to any third party, for the content or accuracy of any User Content posted by you or any other user of the Site.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>10.</b> External Sites</h4>
                                <p className='mb-4'>The Service may include hyperlinks or references to other websites or resources (collectively, “External Sites”), which are provided solely as a convenience to our users. We have no control over any External Sites referenced. Accordingly, you acknowledge and agree that we are not responsible for the availability of any External Sites and that we do not endorse any advertising, products, or other materials on or made available from any External Sites. Furthermore, you acknowledge and agree that we are not liable for any loss or damage which may be incurred as a result of the availability or unavailability of the External Sites, or as a result of any reliance placed by you upon the accuracy, completeness, or existence of any advertising, products or other materials on, or made available from, any External Sites.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>11.</b> Changes to the Terms</h4>
                                <p className='mb-4'>We may make changes to these Terms from time to time or when updating the features and services offered. When we make changes, we will make the updated Terms available on the Service and update the “Last Updated” date text at the beginning of these Terms accordingly. Therefore, please check these Terms regularly for changes. Any changes to the Terms will apply on the date at which they are made, and your continued access to or use of the Service after the Terms have been updated will constitute your binding acceptance of the updates presented. You may not access or use the Service if you do not agree to any revised Terms.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>12.</b> Changes to the Service</h4>
                                <p className='mb-4'>We are constantly updating the Service to provide the best possible experience for our users, this includes new features, updates, and changes to the Site. You acknowledge and agree that the form and nature of the Service, and any part of it, may change from time to time without prior notice to you and that we may add new features and change any part of the Service at any time without notice.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>13.</b> Children</h4>
                                <p className='mb-4'>You affirm that you are over the age of 13, as the Service is not intended for children under 13. If you are 13 or older but under the age of 18, or the legal age of majority where you reside if that jurisdiction has an older age of majority, you agree to review these terms with your parent or guardian to ensure that both you and your parent or guardian understand and agree to these terms. You agree to have your parent or guardian review and accept these terms on your behalf. If you are a parent or guardian agreeing to the terms for the benefit of a child over 13, then you agree to and accept full responsibility for that child's use of the service, including all financial charges and legal liability that he or she may incur during their use.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>14.</b> Privacy Policy</h4>
                                <p className='mb-4'>Our Privacy Policy describes the ways we collect, use, store, and disclose your personal information, and is hereby incorporated by this reference into these Terms. You agree to the collection, use, storage, and disclosure of your data in accordance with our Privacy Policy.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>15.</b> Dispute Resolution; Arbitration</h4>
                                <p className='mb-4'>Please read this section carefully. It requires you to arbitrate disputes with MutoLab and limits the manner in which you may seek relief from us.</p>
                                <p className='mb-4'>All disputes arising out of or in connection with these Terms, including without limitation your use of or access to the Service, the Site, or the Smart Contracts, or to any products sold or distributed through the Service, the Site, or the Smart Contracts, will be referred to and finally resolved by arbitration under the rules of the London Court of International Arbitration. The appointing authority will be the London Court of International Arbitration. The case will be adjudicated by a single arbitrator and will be administered by the London Court of International Arbitration in accordance with its applicable rules. Each party will cover its own fees and costs associated with the arbitration proceedings; however, if the arbitrator discovers that you cannot afford to pay the fees and costs reasonably associated with the arbitration proceedings, MutoLab will pay them for you. The place of arbitration will be London, United Kingdom. You may choose to have the arbitration conducted by telephone, based on written submissions. The language of the arbitration will be English. The award of the arbitrator will be final and binding, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Notwithstanding the foregoing, MutoLab may seek and obtain injunctive relief in any jurisdiction in any court of competent jurisdiction, and you agree that these Terms are specifically enforceable by MutoLab through injunctive relief and other equitable remedies without proof of monetary damages.</p>
                                <p className='mb-4'>WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE SERVICE, THE SITE, THE SMART CONTRACTS, OR ANY PRODUCTS SOLD OR DISTRIBUTED THROUGH THE SERVICE, THE SITE, OR THE SMART CONTRACTS: (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND (II) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.</p>
                            </div>
                            <div className='mb-4'>
                                <h4 className='mb-4'><b>16.</b> General</h4>
                                <p className='mb-4'>These Terms constitute the entire legal agreement between you and MutoLab, govern your access to and use of the Service, and completely replace any prior or contemporaneous agreements between the parties related to your access to or use of the Service, whether oral or written. There are no third-party beneficiaries to these Terms. The parties are independent contractors, and no section in these Terms suggests the creation of any agency, partnership, or joint venture.</p>
                                <p>The language in these Terms will be interpreted as to its fair meaning, and not strictly for or against any such party. You may not assign any of your rights or obligations under these Terms, whether by operation of law or otherwise, without our prior written consent. We may assign our rights and obligations under these Terms in our sole discretion to an affiliate, or in connection with an acquisition, sale, or merger. Should any part of these Terms be held invalid or unenforceable, that portion shall be construed consistent with applicable law and the remaining portions will remain in full force and effect. Our failure to enforce any provision of these Terms will not be deemed a waiver of such provision, nor of the right to enforce such provision. These Terms will be governed by and construed in accordance with the laws of the province of London, United Kingdom, and the laws of the United Kingdom applicable therein, excluding its conflicts of law rules and principles. Subject to Section 15, any legal action or proceeding arising under these Terms will be brought exclusively in the federal or provincial courts located in London, United Kingdom, and the parties irrevocably consent to the personal jurisdiction and venue there. We will not be liable for any failure or delayed performance of our obligations that result from any condition beyond our reasonable control, including, but not limited to, governmental action, acts of terrorism, earthquake, fire, flood, acts of God, labor conditions, power failures, Internet disturbances, or acts or omissions of third parties. You agree that we may provide you with notices (including, without limitation those regarding changes to these Terms) by email, regular mail, or postings on the Service. By providing us with your email address, you consent to our using the email address to send you any notices required by law in lieu of communication by postal mail.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default TermsAndConditions;
