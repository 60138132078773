import instance from "../../../config/axios";
import { ParseError, getAuthHeader } from "../../../config/utils";

export const completeCheckout = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = '' // await getAuthHeader();
    return instance.post('admin/plans/completeCheckOut', data, headers)
        .then((res) => {
            if (res && res.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: res.data.message,
                    data: res.data.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(res.data),
                };
            }
        })
}

export const CheckoutPlan = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader();
    return instance.post('admin/plans/checkOut/', data, headers)
        .then((res) => {
            if (res && res.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: res.data.message,
                    data: res.data.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(res.data),
                };
            }
        })

}

export const GetPlans = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = '' // await getAuthHeader();
    return instance
        .get("admin/plans/", headers)
        .then((res) => {
        
            if (res && res.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: res.data.message,
                    data: res.data.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(res.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};

export const AddPlan = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = '' // await getAuthHeader();
    return instance
        .post("admin/plans/", data, headers)
        .then((res) => {
            if (res.data && res.data.code === 200) {
                
               
                return {
                    ...responseData,
                    status: 200,
                    message: res.data.message,
                    data: res.data.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(res.data),
                };
            }
        })
}

export const UpdatePlan = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader();
    return instance
        .put("/admin/plans/", data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: response.data.message,
                    data: response.data.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};

export const UpdatePlanStatus = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader();
    return instance
        .put("/admin/plans/status", data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: response.data.message,
                    data: response.data.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};

export const DeletePlan = async (data) => {

    const responseData = {
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader();
    return instance
        .put("/admin/plans/" + data,headers)
        .then((res) => {
            
            if (res.data && res.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    data: res.data.data,
                    message: res.data.message,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(res.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};