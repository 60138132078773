const PrimaryCard = (props) => {
  const {
    values,
    fieldName,
  } = props;
  return (
    <>
      {values[fieldName] !== "" ? (
        <div class="d-flex justify-content-between">
          <h4 class="card-title">Possible Combinations :</h4>
          <h4 class="card-title ml-2">{values[fieldName]}</h4>
        </div>
      ) : null}
    </>
  );
};
export default PrimaryCard;
