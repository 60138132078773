import { useEffect } from "react";
import { ConnectedFocusError } from "focus-formik-error";
import TextFieldGeneric from "ReuseableComps/TextFieldGeneric";
import { Tab, Form } from "react-bootstrap";
import FormikFormFooter from "ReuseableComps/FormFooter";
import SelectDropdown from "ReuseableComps/SelectField";
import UploadMultiple from "../../ReuseableComps/UploadMultiple";
import PrimaryCard from "ReuseableComps/PrimaryCard";
import MultiSelectField from "ReuseableComps/MultiSelectField";
import GenericTableMini from "ReuseableComps/TableMiniFormik";
import Ckeditor from "ReuseableComps/Ckeditor";
import SelectNetwork from "ReuseableComps/SelectNetwork";
import { Card, Col, CardImg } from "reactstrap";
const FormikFieldDouble = (props) => {
  const {
    touched,
    errors,
    isSubmitting,
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    heading,
    formFields,
    formFields1,
    formFields2,
    formFields3,
    headingFormFields1,
    headingFormFields2,
    headingFormFields3,
    networks,
    categories,
    setselectedFiles,
    tostify,
    setTostify,
    setSelectedNetwork,
    getCollectionByNetwork,
    getCollectionByUser,
    usersAll,
    selectedNetwork,
    TableMiniHeadings,
    fetchData,
    checkNetwork,
    upload,
    color,
    supply,
    changeNetworkConnectMeta,
    handlePublish,
    handleConnect,
    handleDeploy,
    pubPhase,
    walletPhase,
    deployPhase,
    role,
    setNetworkName,
    networkName,
    setFolderHash,
    userImagePath,
    actionState,
    btnDisable,
    remainingNft,
    setLoadingN
  } = props;

  useEffect(() => {
    if (heading === "Profile") {
      setFieldValue("profileImage", upload);
    }
    //eslint-disable-next-line
  }, [upload, setFieldValue]);


  const packageType = [
    { _id: "month", category: "month" },
    { _id: "year", category: "year" }
  ];
  const markStatus = [
    { _id: "false", category: "False" },
    { _id: "true", category: "True" }
  ];



  return (
    <>

      <Tab.Container id="left-tabs-example" defaultActiveKey="third">
        <Tab.Content className={heading === "Sign Up" ? "" : "m-5 user-box-wrapper"}>
          <Tab.Pane eventKey="third">
            <Form className="login-form">
              <ConnectedFocusError />
              {formFields.map((item, index) => {


                return (
                  <>
                    <div className="d-flex flex-lg-row flex-md-row flex-column justify-content-between">
                      {item.fieldName === "selectusers" ? (

                        <>
                          <SelectDropdown
                            options={
                              heading === "Collection" ||
                                heading === "Live Collections"
                                ? usersAll
                                : (heading === "Plans List" && item.fieldName === 'type')
                                  ? packageType
                                  : (heading === "Plans List" && item.fieldName === 'waterMarkStatus') ? markStatus :  categories
                            }
                            checkNetwork={checkNetwork}
                            fetchData={fetchData}
                            selectedNetwork={selectedNetwork}
                            setSelectedNetwork={setSelectedNetwork}
                            getCollectionByNetwork={getCollectionByNetwork}
                            getCollectionByUser={getCollectionByUser}
                            heading={heading}
                            setFieldError={setFieldError}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            fieldName={item.fieldName}
                            field={item.field}
                            type={item.type}
                            required={item.required}
                            setNetworkName={setNetworkName}
                            networkName={networkName}
                            actionState={actionState}
                            widthClass={
                              heading === "NFT Details" ? "w73" : "w-45"
                            }
                          />
                        </>


                      ) : item.type === "select" ? (
                        <>
                          <SelectDropdown
                            options={
                              heading === "Collection" ||
                                heading === "Live Collections"
                                ? networks
                                : (heading === "Plans List" && item.fieldName === 'type')
                                ? packageType
                                : (heading === "Plans List" && item.fieldName === 'waterMarkStatus') ? markStatus :  categories
                            }
                            checkNetwork={checkNetwork}
                            fetchData={fetchData}
                            selectedNetwork={selectedNetwork}
                            setSelectedNetwork={setSelectedNetwork}
                            getCollectionByNetwork={getCollectionByNetwork}
                            getCollectionByUser={getCollectionByUser}
                            heading={heading}
                            setFieldError={setFieldError}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            fieldName={item.fieldName}
                            field={item.field}
                            type={item.type}
                            required={item.required}
                            setNetworkName={setNetworkName}
                            networkName={networkName}
                            actionState={actionState}
                            widthClass={
                              heading === "NFT Details" ? "w73" : "w-45"
                            }
                          />
                        </>
                      ) : item.type === "selectNetwork" ? (
                        <SelectNetwork
                          changeNetworkConnectMeta={changeNetworkConnectMeta}
                          options={heading === "Collections" ? networks : []}
                          checkNetwork={checkNetwork}
                          fetchData={fetchData}
                          selectedNetwork={selectedNetwork}
                          setSelectedNetwork={setSelectedNetwork}
                          getCollectionByNetwork={getCollectionByNetwork}
                          heading={heading}
                          setFieldError={setFieldError}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          fieldName={item.fieldName}
                          field={item.field}
                          type={item.type}
                          required={item.required}
                          widthClass={
                            heading === "NFT Details" ? "w73" : "w-45"
                          }
                          pubPhase={pubPhase}
                          walletPhase={walletPhase}
                          deployPhase={deployPhase}
                        />
                      ) : item.type === "file" ? (
                        <UploadMultiple
                          heading={heading}
                          setFieldError={setFieldError}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          fieldName={item.fieldName}
                          place={item.place}
                          field={item.field}
                          type={item.type}
                          required={item.required}
                          widthClass={
                            heading === "NFT Details" ? "w73" : "w-45"
                          }
                          setselectedFiles={setselectedFiles}
                          tostify={tostify}
                          setTostify={setTostify}
                          setFolderHash={setFolderHash}
                        />
                      ) : item.type === "combinations" ? (
                        <PrimaryCard
                          touched={touched}
                          values={values}
                          setFieldError={setFieldError}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          handleChange={handleChange}
                          fieldName={item.fieldName}
                          place={item.place}
                          field={item.field}
                          type={item.type}
                          required={item.required}
                          widthClass={
                            heading === "NFT Details" ? "w73" : "w-45"
                          }
                        />
                      ) : item.type === "multiSelect" ? (
                        <MultiSelectField
                          setFieldError={setFieldError}
                          setFieldValue={setFieldValue}
                          touched={touched}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          fieldName={item.fieldName}
                          place={item.place}
                          field={item.field}
                          type={item.type}
                          required={item.required}
                          widthClass={
                            heading === "NFT Details" ? "w73" : "w-45"
                          }
                          role={role}
                        />
                      ) : item.type === "tableRow" ? (
                        values && values["collections"].length > 0 ? (
                          <GenericTableMini
                            TableMiniHeadings={TableMiniHeadings}
                            values={values}
                            fieldName={item.fieldName}
                            type={item.type}
                            role={role}
                          />
                        ) : null
                      ) : item.fieldName === "mintAmount" ? (
                        values && values["selectedCollection"] ? (
                          values && values["collections"].length > 0 && (
                            <TextFieldGeneric
                              touched={touched}
                              values={values}
                              errors={errors}
                              handleChange={handleChange}
                              fieldName={item.fieldName}
                              place={item.place}
                              field={item.field}
                              type={item.type}
                              required={item.required}
                              heading={heading}
                              remainingNft={remainingNft}
                              widthClass={
                                heading === "NFT Details" ? "w73" : "w-45"
                              }
                              actionState={actionState}
                              pubPhase={pubPhase}
                              walletPhase={walletPhase}
                              deployPhase={deployPhase}
                            />
                          )
                        ) : null
                      ) : item.type === "description" ? (
                        <Ckeditor
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          fieldName={item.fieldName}
                          place={item.place}
                          field={item.field}
                          type={item.type}
                          required={item.required}
                          widthClass={"w-100"}
                          role={role}
                          heading={heading}
                          pubPhase={pubPhase}
                          walletPhase={walletPhase}
                          deployPhase={deployPhase}
                        />
                      ) : (
                        <TextFieldGeneric
                          touched={touched}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          fieldName={item.fieldName}
                          setFieldValue={setFieldValue}
                          place={item.place}
                          field={item.field}
                          type={item.type}
                          heading={heading}
                          supply={supply}
                          required={item.required}
                          widthClass={
                            heading === "NFT Details" ? "w73" : "w-45"
                          }
                          remainingNft={remainingNft}
                          actionState={actionState}
                          pubPhase={pubPhase}
                          walletPhase={walletPhase}
                          deployPhase={deployPhase}
                        />
                      )}
                      {item && item.fieldName1 && item.type1 === "select" ? (
                        values["collections"].length > 0 ? (
                          <>
                            <SelectDropdown
                              options={
                                heading === "Collection" ||
                                  (heading === "Live Collections" &&
                                    values["collections"].length > 0)
                                  ? values["collections"]
                                  : heading === "Live Collections"
                                    ? networks
                                    : categories
                              }
                              fetchData={fetchData}
                              selectedNetwork={selectedNetwork}
                              setSelectedNetwork={setSelectedNetwork}
                              getCollectionByNetwork={getCollectionByNetwork}
                              getCollectionByUser={getCollectionByUser}
                              heading={heading}
                              setFieldError={setFieldError}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              values={values}
                              errors={errors}
                              handleChange={handleChange}
                              fieldName={item.fieldName1}
                              field={item.field1}
                              type={item.type1}
                              required={item.required1}
                              widthClass={
                                heading === "NFT Details" ? "w73" : "w-45"
                              }
                            />
                          </>
                        ) : values["collections"] === "" ? (
                          <span className="p-3 mt-4 text-neutral w-45">
                            No Collection Found
                          </span>
                        ) : null
                      ) : item &&
                        item.fieldName1 &&
                        item.type1 === "select" &&
                        values["collections"] === "" ? null : item &&
                          item.fieldName1 ? (
                        <TextFieldGeneric
                          touched={touched}
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          fieldName={item.fieldName1}
                          field={item.field1}
                          place={item.place1}
                          type={item.type1}
                          heading={heading}
                          required={item.required1}
                          widthClass={"w-45"}
                          remainingNft={remainingNft}
                          actionState={actionState}
                          pubPhase={pubPhase}
                          walletPhase={walletPhase}
                          deployPhase={deployPhase}
                        />
                      ) : null}
                    </div>
                  </>
                );
              })}
              {formFields1 ? (
                <div
                  className="block-content block-content-full"
                >
                  <div className="items-push">
                    <h4 className="content-heading text-primary text-center">
                      {headingFormFields1}
                    </h4>
                    <div className="row items-push"></div>
                    {formFields1 &&
                      formFields1.map((item, index) => {
                        return (
                          <div className="d-flex flex-lg-row flex-md-row flex-column justify-content-between">
                            {item.type === "description" ? (
                              <Ckeditor
                                touched={touched}
                                setFieldValue={setFieldValue}
                                values={values}
                                errors={errors}
                                handleChange={handleChange}
                                fieldName={item.fieldName}
                                place={item.place}
                                field={item.field}
                                type={item.type}
                                required={item.required}
                                widthClass={"w-100"}
                                heading={heading}
                                pubPhase={pubPhase}
                                walletPhase={walletPhase}
                                deployPhase={deployPhase}
                              />
                            ) : (
                              <TextFieldGeneric
                                touched={touched}
                                errors={errors}
                                values={values}
                                handleChange={handleChange}
                                fieldName={item.fieldName}
                                field={item.field}
                                place={item.place}
                                heading={heading}
                                type={item.type}
                                required={item.required}
                                widthClass={"w-45"}
                                actionState={actionState}
                                pubPhase={pubPhase}
                                walletPhase={walletPhase}
                                deployPhase={deployPhase}
                              />
                            )}
                            {item && item.fieldName1 ? (
                              item.type === "description" ? (
                                <Ckeditor
                                  touched={touched}
                                  setFieldValue={setFieldValue}
                                  values={values}
                                  errors={errors}
                                  handleChange={handleChange}
                                  fieldName={item.fieldName}
                                  place={item.place}
                                  field={item.field}
                                  type={item.type}
                                  required={item.required}
                                  widthClass={"w-100"}
                                  pubPhase={pubPhase}
                                  walletPhase={walletPhase}
                                  deployPhase={deployPhase}
                                  heading={heading}
                                />
                              ) : (
                                <TextFieldGeneric
                                  touched={touched}
                                  errors={errors}
                                  values={values}
                                  handleChange={handleChange}
                                  fieldName={item.fieldName1}
                                  field={item.field1}
                                  heading={heading}
                                  place={item.place1}
                                  type={item.type1}
                                  required={item.required1}
                                  widthClass={"w-45"}
                                  actionState={actionState}
                                  pubPhase={pubPhase}
                                  walletPhase={walletPhase}
                                  deployPhase={deployPhase}
                                />
                              )
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null}
              {formFields2 ? (
                <div
                  className="block-content block-content-full"
                >
                  <div className="items-push">
                    <h4 className="content-heading text-primary text-center">
                      {headingFormFields2}
                    </h4>
                    <div className="row items-push"></div>
                    {formFields2 &&
                      formFields2.map((item, index) => {
                        return (
                          <div className="d-flex flex-lg-row flex-md-row flex-column justify-content-between">
                            <TextFieldGeneric
                              touched={touched}
                              errors={errors}
                              values={values}
                              handleChange={handleChange}
                              fieldName={item.fieldName}
                              field={item.field}
                              type={item.type}
                              heading={heading}
                              required={item.required}
                              widthClass={"w-45"}
                              actionState={actionState}
                              pubPhase={pubPhase}
                              walletPhase={walletPhase}
                              deployPhase={deployPhase}
                            />
                            {item && item.fieldName1 ? (
                              <TextFieldGeneric
                                touched={touched}
                                errors={errors}
                                values={values}
                                heading={heading}
                                handleChange={handleChange}
                                fieldName={item.fieldName1}
                                field={item.field1}
                                type={item.type1}
                                required={item.required1}
                                widthClass={"w-45"}
                                actionState={actionState}
                                pubPhase={pubPhase}
                                walletPhase={walletPhase}
                                deployPhase={deployPhase}
                              />
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null}
              {formFields3 ? (
                <div
                  className="block-content block-content-full"
                >
                  <div className="items-push">
                    <h4 className="content-heading text-primary text-center">
                      {headingFormFields3}
                    </h4>
                    <div className="row items-push"></div>
                    {formFields3 &&
                      formFields3.map((item, index) => {
                        return (
                          <div className="d-flex flex-lg-row flex-md-row flex-column justify-content-between">
                            <TextFieldGeneric
                              touched={touched}
                              errors={errors}
                              values={values}
                              handleChange={handleChange}
                              fieldName={item.fieldName}
                              field={item.field}
                              type={item.type}
                              heading={heading}
                              required={item.required}
                              widthClass={"w-45"}
                              actionState={actionState}
                              pubPhase={pubPhase}
                              walletPhase={walletPhase}
                              deployPhase={deployPhase}
                            />
                            {item && item.fieldName1 ? (
                              <TextFieldGeneric
                                touched={touched}
                                errors={errors}
                                values={values}
                                heading={heading}
                                handleChange={handleChange}
                                fieldName={item.fieldName1}
                                field={item.field1}
                                type={item.type1}
                                required={item.required1}
                                widthClass={"w-45"}
                                actionState={actionState}
                                pubPhase={pubPhase}
                                walletPhase={walletPhase}
                                deployPhase={deployPhase}
                              />
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null}
              <FormikFormFooter
                color={color}
                isSubmitting={isSubmitting}
                heading={heading}
                handleSubmit={handleSubmit}
                values={values}
                handlePublish={handlePublish}
                handleConnect={handleConnect}
                handleDeploy={handleDeploy}
                pubPhase={pubPhase}
                walletPhase={walletPhase}
                deployPhase={deployPhase}
                btnDisable={btnDisable}
                role={role}
                setLoadingN={setLoadingN}
              />
              <div className="d-flex flex-wrap">
                {heading === "Collections" &&
                  values &&
                  values["totalSupply"] &&
                  values["totalSupply"] > 0 &&
                  [...Array(values["totalSupply"])].map((num, index) => (
                    <Col lg="2" md="3" sm="4" key={index}>
                      <Card style={{border:'1px solid #fc407c', borderRadius: '10px'}}>
                        <CardImg style={{borderRadius: '10px'}}
                          src={`${process.env.REACT_APP_IMAGE_SERVER + (index + 1) + '/' + userImagePath.userId + '/' + userImagePath.folderHash + '?t=' +(Math.ceil(Math.random() * 10000000000000))
                            }`}
                        ></CardImg>
                      </Card>
                    </Col>
                  ))}
              </div>
            </Form>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
export default FormikFieldDouble;
