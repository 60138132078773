import TextFieldLabel from "../TextfieldLabel";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { useEffect } from "react";
const TextFieldGeneric = (props) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    fieldName,
    field,
    type,
    place,
    required,
    widthClass,
    heading,
    supply,
    setFieldValue,
    remainingNft,
    actionState,
    walletPhase,
    deployPhase
  } = props;

  useEffect(
    () => {
      if (heading === "Collections" && fieldName === "totalSupply") {
        setFieldValue("totalSupply", supply);
      }
    },
    // eslint-disable-next-line
    [supply]
  );



  return (
    <>

      <Form.Group
        size="lg"
        className={
          touched && touched[fieldName] && errors && errors[fieldName]
            ? "has-danger has-label form-group " + widthClass
            : touched && touched[fieldName] && errors && !errors[fieldName]
              ? "has-success has-label form-group " + widthClass
              : "has-label form-group " + widthClass
        }
        controlId={"formik-form-element-" + fieldName}
      >
        <Form.Label>
          <TextFieldLabel
            widthclass=""
            field={fieldName === "amountToGenerate" ? `${field} (Remaining NFTs to Create: ${remainingNft})` : field}
            required={required}
            heading={fieldName === "pricePerCollection" ? heading : ""}
            values={values}
          />
        </Form.Label>
        <Form.Control
          disabled={
            (heading === "Collections" && fieldName === "collectionName") || 
            (heading === "Collections" && fieldName === "collectionSymbol") || 
            (heading === "Profile" && fieldName === "email") || 
            (heading === "Plans List" && fieldName === "price" && actionState === 'edit') || 
            (heading === "Plans List" && fieldName === "maxGenerateNfts" && actionState === 'edit') || 
            (heading === "Plans List" && fieldName === "maxGenerateCollection" && actionState === 'edit') || 
            (heading === "Collections" && fieldName === "totalSupply") ||
            (heading === "Collections" && fieldName === "collectionSymbol" && (walletPhase || deployPhase)) ||
            (heading === "Collections" && fieldName === "pricePerCollection" && (walletPhase || deployPhase)) ||
            (heading === "Collections" && fieldName === "description" && (walletPhase || deployPhase))
          }
          onChange={handleChange}
          name={fieldName}
          type={type}
          placeholder={place}
          className="form-control"
          value={values[fieldName]}
        />
        <ErrorMessage
          name={fieldName}
          component="div"
          className={"text-warning"}
        />
      </Form.Group>
    </>
  );
};
export default TextFieldGeneric;
