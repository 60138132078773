import { useEffect, useState, useContext } from "react";
import {
  getInitialValues,
  HowItWorksFormFields,
  HowItWorksSchema,
  HowItWorksCardFormFields,
} from "./helpers";
import GenericFormikForm from "ReuseableComps/GenericForms";
import {
  GetCMSDataByType,
  UpdateCMSDataByType,
} from "../../../../services/admin/CMS";
import { ToasterContext } from "../../../../app";
import FullPageLoader from "ReuseableComps/FullPageLoader/full-page-loader";
const HowItWorks = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line
  const [reset, setReset] = useState(false);
  const [tostify, setTostify] = useContext(ToasterContext);
  const GetCMSDataService = () => {
    setLoading(true);
    GetCMSDataByType("howItWorks").then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setLoading(false);
        setReset(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const UpdateCMSDataService = async (data, actions) => {
    UpdateCMSDataByType(data, "howItWorks").then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setReset(true);
      }
      actions.setSubmitting(false);
      setTostify({
        ...tostify,
        visible: true,
        key: Math.random().toString(36).substring(7),
        type: res && res.status === 200 ? "success" : "error",
        message: res.message,
      });
    });
  };
  useEffect(() => {
    GetCMSDataService();
    // eslint-disable-next-line
  }, []);
  return (
    <div className="content">
      {loading ? <FullPageLoader /> : (
        <GenericFormikForm
          key={"How It Works" + Math.random().toString(36).substring(7)}
          heading={"How It Works"}
          data={data}
          getValues={getInitialValues}
          schema={HowItWorksSchema}
          formFields={HowItWorksFormFields}
          headingFormFields1={"Four Steps"}
          formFields1={HowItWorksCardFormFields}
          update={UpdateCMSDataService}
        />
      )}
    </div>
  );
};
export default HowItWorks;
