import Actions from "ReuseableComps/Actions";

const TableRow = (props) => {
    const { row, index } = props;

    return (
        <>
            <tr key={row.id + index} id={"monthly-tr-" + index}>
                <td className="text-left ">
                    <span>{row.name}</span>
                </td>
                <td className="text-left ">
                    <span>{row.email}</span>
                </td>
                <td className="text-left ">
                    <span>{row.subject}</span>
                </td>
                <td className="text-left ">
                    <span>{row.message}</span>
                </td>
                <td className="text-left ">
                    <Actions
                        row={row}
                    />
                </td>
            </tr>
        </>
    );
};
export default TableRow;
