import { toast } from 'react-toastify';
import instance from '../../config/axios';
import { ParseError, getAuthHeader } from '../../config/utils';

export const GetUser = async (email) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    const headers = await getAuthHeader();
    return instance
        .get(`user/${email}`, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    data: response.data.data,
                    message: response.data.message
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data)
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}

export const GetUserPlan = async (_id) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    return instance
        .get(`admin/plans/subscription/${_id}`)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data.data;
                return {
                    ...responseData,
                    status: 200,
                    data: response
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data)
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}

export const freePlan = async (_id) => {
    const responseData = {
        loading: false,
        status: 210,
        message: 'Something went wrong, Please try again.'
    };
    const headers = await getAuthHeader();
    return instance
        .post(`admin/plans/free-plan/`,{id: _id}, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data.data;
                return {
                    ...responseData,
                    status: 200,
                    data: response
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data)
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                )
            };
        });
}

export const UpdateUserProfile = async (formData) => {

    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader();
    const data = new FormData();
    if (formData.profileImage) {
        data.append("id", formData.id);
        data.append("img", formData.profileImage);
    }else{
        data.append("firstName", formData.firstName);
        data.append("lastName", formData.lastName);
        data.append("id", formData.id);
    }
    return instance
        .put("user/", data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};

export const UpdatePassword = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader();
    return instance
        .put("user/password", data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};

export const enable2Fa = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader();
    return instance
        .post("user/2fa", data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};
export const verifyTwoFa = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader(data.token);

    return instance
        .post("user/2fa/verify", data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                return {
                    ...responseData,
                    status: 200,
                    message: response.data.message,
                    data: response.data.data,
                };
            } else {
                toast(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    type: response && response.data.code === 200 ? "success" : "error",
                })
                return {
                    ...responseData,
                    status: response.data.code,
                    message: response.data.message,
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};
export const disbaleTwoFa = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader(data.token);

    return instance
        .post("user/2fa/disbale", data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};
export const addContact = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };

    return instance
        .post("/contact/add", data)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};
export const getContacts = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };

    return instance
        .get("/contact/all", data)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};

export const addPinata = async (data) => {
    const responseData = {
        loading: false,
        status: 210,
        message: "Something went wrong, Please try again.",
    };
    const headers = await getAuthHeader();
    return instance
        .post("user/pinata/add", data, headers)
        .then((response) => {
            if (response.data && response.data.code === 200) {
                response = response.data;
                return {
                    ...responseData,
                    status: 200,
                    message: response.message,
                    data: response.data,
                };
            } else {
                return {
                    ...responseData,
                    message: ParseError(response.data),
                };
            }
        })
        .catch((err) => {
            return {
                ...responseData,
                message: ParseError(
                    err.response && err.response.data ? err.response.data : err.message
                ),
            };
        });
};