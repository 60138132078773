//eslint-disable-next-line
import * as Yup from "yup";

export const UserListingTableHeadings = [
    {
        id: "firstName",
        label: "First Name",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "lastName",
        label: "Last Name",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "email",
        label: "Email",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "Status",
        label: "Status",
        sort: true,
        class: "bg-body-light",
    },
    {
        id: "actions",
        label: "VIEW",
        sort: true,
        class: "bg-body-light",
    },
];

export const UserListingInitialValues = {
    firstName: "",
    lastName: "",
    email: "",
    Status: "",
};

export const createRows = (rowData) => {
    const rows = rowData.map((i) => {
        return {
            id: i._id,
            firstName: i.firstName,
            lastName: i.lastName,
            email: i.email,
            status: i.suspended,
        };
    });
    return rows;
};
