/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import SideBarComp from "../../components/LayoutComponents/sidebar";
import HeaderComp from "../../components/LayoutComponents/header";
import {
  routes,
  AdminSideBarCMSSubMenu,
  AdminSideBarCMSPricingSubMenu,
  AdminSideBarProfileSubMenu,
  AdminSideBarCMSFaqsSubMenu,
} from "routes.js";
import logo from "../../assets/images/logo.jpg";
import { GetSiteSettings } from "services/admin/SiteSettings";
let ps = "";
const Presentationlayout = (props) => {
  const { color } = props;
  const location = useLocation();
  const [siteInfo, setSiteInfo] = useState('')
  const mainPanelRef = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanelRef.current, {
        suppressScrollX: true,
      });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.classList.add("perfect-scrollbar-off");
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanelRef.current) {
      mainPanelRef.current.scrollTop = 0;
    }
  }, [location]);
  const { children } = props;

  useEffect(() => {
    const getSiteData = () => {
        GetSiteSettings().then(res => {
            if (res.status === 200) {
                setSiteInfo(res.data)
            }
        })
    }
    getSiteData()
}, [])

useEffect(() => {
  if (window.matchMedia('(max-width: 991px)').matches) {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  }
}, [location.pathname])
useEffect(() => {
  if (window.matchMedia('(max-width: 991px)').matches) {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(false);
  }
}, [])

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "BRAND";
  };
  return (
    <>
      <React.Fragment>
        <div className="wrapper admin">
          <SideBarComp
            routes={routes}
            AdminSideBarCMSSubMenu={AdminSideBarCMSSubMenu}
            AdminSideBarProfileSubMenu={AdminSideBarProfileSubMenu}
            AdminSideBarCMSPricingSubMenu={AdminSideBarCMSPricingSubMenu}
            AdminSideBarCMSFaqsSubMenu={AdminSideBarCMSFaqsSubMenu}
            logo={{
              outterLink: "/",
              text: "Mutolab",
              imgSrc: logo,
            }}
            toggleSidebar={toggleSidebar}
            color={color}
          />
          <div className="main-panel" ref={mainPanelRef} data={""}>
            <HeaderComp
              brandText={siteInfo ? siteInfo.name : 'MutoLab'}
              toggleSidebar={toggleSidebar}
              sidebarOpened={sidebarOpened}
            />
            {children}
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default Presentationlayout;
