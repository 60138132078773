/* eslint-disable */
import { useEffect, useState, useContext } from "react";
import { useDropzone } from "react-dropzone";
import TextFieldLabel from "../TextfieldLabel";
import { uploadFolderService } from "../../services/admin/Collections";
import { Button, FormGroup, CustomInput } from "reactstrap";
import { userPlanContext } from "contexts/UserPlanContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { Modal, Image } from "react-bootstrap";
import helpGif from 'assets/images/help.gif'
import ClipLoader from "../ClipLoader";
import { toast } from "react-toastify";
import zip from '../../assets/sample.zip'

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
};
const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
};
const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
};
const img = {
    display: "block",
    width: "auto",
    height: "100%",
};

const UploadMultiple = (props) => {

    const {
        values,
        setFieldValue,
        setTostify,
        tostify,
        setFolderHash
    } = props;
    const [files, setFiles] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [],
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const { userCurrentPlan } = useContext(userPlanContext);

    var countFolder = [];

    const folderInfo = (e) => {
        e.preventDefault();
        setFieldValue("uploadFolder", '');
        setFieldValue("parent", '');
        setFieldValue("folderHash", '');
        setFieldValue("layers", '');
        setFieldValue("selectedFiles", "");
        setFieldValue("uploading", false);
        setFieldValue("possibleCombinations",'');
        const folder = e.target.files;
        if (folder.length > 0) {
            for (let i = 0; i < folder.length; i++) {
                let checkFolderLength = folder[i].webkitRelativePath.split('/')
                if (checkFolderLength[1].length && checkFolderLength[1] !== folder[i].name) {
                    if (countFolder.indexOf(checkFolderLength[1]) === -1) {
                        countFolder.push(checkFolderLength[1])
                    }
                }
            }

            if (userCurrentPlan.length > 0) {
                if (countFolder.length > userCurrentPlan[0].maxUploadFile) {

                    setTostify({
                        ...tostify,
                        visible: true,
                        key: Math.random().toString(36).substring(7),
                        type: "error",
                        message: "Test message herer",
                    });
                    return false;
                }
            }
        }
        else {
            return false;
        }

        setFieldValue("uploadFolder", folder);
        let parent = folder[0].webkitRelativePath;
        const index = parent.indexOf("/");
        parent = parent.substr(0, index);

        setFieldValue("parent", parent);
        setFieldValue("selectedFiles", e.target.files);
    };


    const uploadFiles = async (event) => {
        try {
            if (values.selectedFiles.length > 0) {

                setLoading(true)
                event.preventDefault();
                setFieldValue("uploading", true);
                const formData = new FormData();
                for (let index = 0; index < values.selectedFiles.length; index++) {
                    if (values.selectedFiles[index].type === "image/png") {
                        formData.append("files", values.selectedFiles[index]);
                    }
                }

                formData.append("parent", values.category);
                formData.append("uploadFolderHash", setFolderHash);
    
                uploadFolderService(formData).then((res) => {
                    if (res.status === 200) {
                        setFieldValue(
                            "possibleCombinations",
                            res.data.numberOfPossibleCombinations
                        );
    
                        setFieldValue("folderHash", setFolderHash);
                        setFieldValue("layers", res.data.layers);
                        setFieldValue("selectedFiles", "");
                        setFieldValue("uploading", false);
                        setLoading(false)
                    } else {
                        setTostify({
                            ...tostify,
                            visible: true,
                            key: Math.random().toString(36).substring(7),
                            type: res && res.status === 200 ? "success" : "error",
                            message: res.message,
                        });
                        setLoading(false)
                    }
                });
            } else {
                if (values.possibleCombinations > 0) {
                    toast.warning('You already have uploaded layers!', {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                    });
                }else{
                    toast.warning('Please upload a layers folder', {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                    });
                }
            }
        } catch (err) {
            setLoading(false)
            setFieldValue("uploading", false);
        }
    };
    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt='img'
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                />
            </div>
        </div>
    ));
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
        }, 1000);
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);





    return (
        <>
            <FormGroup
                className={"has-label form-group w-100 overflow-hidden"}
            >
                <label className="w-input">
                    <div className="help-content d-flex align-items-center justify-content-between w-100">
                        <TextFieldLabel
                            widthclass=""
                            field={"Upload Folder (Supports PNG file format)"}
                            required={true}
                        />
                        <FontAwesomeIcon className="help-icon" onClick={handleShow} icon={faQuestion} />
                    </div>
                </label>
                <div className="d-flex align-items-lg-center align-items-md-center align-items-start flex-lg-row flex-md-row flex-column jusify-content-between">
                    <CustomInput
                        id="uploadInput"
                        onChange={(e) => folderInfo(e)}
                        type="file"
                        multiple
                        webkitdirectory=""
                        mozdirectory=""
                        directory=""
                        className="w73 upload-multi-label"
                    />

                    <Button
                        className="btn-fill ml-xl-5 ml-lg-4 ml-md-4 ml-0 mt-0 btn-theme"
                        color="primary"
                        type="button"
                        disabled={values.uploading}
                        onClick={(e) => uploadFiles(e)}
                    >
                        {loading ?
                            <div className="d-flex align-items-center">
                                <ClipLoader color={"white"} /> <span className="ml-2">processing....</span>
                            </div>
                            : 'Upload Layers'}
                    </Button>
                </div>
            </FormGroup>
            <Modal className="modal-dark modal-help y-hidden" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3 className="custom-h3">Layers Folder Structure</h3>
                    <ul>
                        <li>Images should be in PNG format only.</li>
                        <li>In NFT Layers folders like: background, body, mouth, arms, legs, horns etc</li>
                        <li> Each Layer folder have PNG format images with their layer folder name  like: background1.png, background2.png, background3.png and same for all layer folders images with their folder name.</li>
                    </ul>
                    <h4><a href={zip} download="sample.zip"> Download Sample NFT </a></h4>
                    
                    <Image src={helpGif} alt="help" />
                </Modal.Body>
                <Modal.Footer className="nft-modal-footer d-flex justify-content-end py-3">
                    <hr></hr>
                    <Button className="btn-theme" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default UploadMultiple;
