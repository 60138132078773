/* eslint-disable */
import { useEffect } from "react";
import UploadIcon from "./uploadIcon";
import { serverUrl } from "config/config";
import FormikFieldDoubleUser from "ReuseableCompsUser/DoubleFormFIeld";
import defaultProfile from '../.././assets/images/defaultProfile.jpg'

const ProfileImageBox = (props) => {
  const { type, UpdateProfileImage, heading, isSubmitting, handleSubmit, data, upload, setUpload, initialValues, getValues, schema, values, formFields, update, formFields1, formFields2, headingFormFields1, headingFormFields2, networks, categories, setselectedFiles, tostify, setTostify, setSelectedNetwork, getCollectionByNetwork, selectedNetwork, TableMiniHeadings, fetchData, checkNetwork, handleMint, setFieldValu, setFieldValue } = props;

  useEffect(() => {
    setFieldValue("profileImage", upload)
  }, [upload])
  return (
    <>
      <div className="manage-nfts-main-section card-body" id="profile">
        <div className="row justify-content-between align-items-center pb-4">
          <div className="col-sm-auto col-lg-8 d-flex justify-content-lg-start justify-content-md-start justify-content-center align-items-center flex-lg-row flex-md-row flex-sm-row flex-sx-column">
            <div className="avatar avatar-xl dashboard-profile mb-lg-0 mb-md-0 mb-sm-3 mb-xs-2 position-relative ">
                <img
                  alt="..."
                  src={upload?.preview ? upload.preview : upload?.includes('public') ? serverUrl + upload : defaultProfile}
                />
            </div>

            <div className="profile-heading">
              <div className="">
                <h3 className="mb-1 font-weight-bolder">{values.firstName} {values.lastName}</h3>
              </div>
            </div>
          </div>

          <div className="col-sm-auto col-lg-4 ms-sm-auto mt-sm-0 mt-3 d-flex justify-content-lg-end justify-content-md-end justify-content-center">
            <UploadIcon upload={upload} setUpload={setUpload} UpdateProfileImage={UpdateProfileImage}/>
          </div>
        </div>
      </div>      
      <div className="manage-nfts-main-section mt-4">
        <div className="manage-nfts-main-section-content">
          <h3>Basic Info</h3>
        </div>
        <div className="card-body pt-0 ">
          <FormikFieldDoubleUser
            formFields={formFields}
            {...props}
            heading={heading}
            tostify={tostify}
            setTostify={setTostify}
            upload={upload}
            initialValues={initialValues}
            schema={schema}
            getValues={getValues}
          /> 
        </div>
      </div>
    </>
  );
};

export default ProfileImageBox;
