import { CardHeader, CardTitle, Button } from "reactstrap";

const TableCardHeader = (props) => {
  const { heading, handleOpen} = props;
  return (
    <CardHeader className="d-flex flex-lg-row flex-md-row flex-sm-row flex-column  justify-content-between">
      <CardTitle tag="h4">{heading}</CardTitle>
      {handleOpen ? (
        <Button
          className={'btn-fill btn-theme'}
          type="button"
          size="sm"
          onClick={handleOpen}
        >
          {heading === 'NFT Listing' ? 'Generate' : 'ADD'}
        </Button>
      ) : null}
    </CardHeader>
  );
};

export default TableCardHeader;
