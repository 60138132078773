import React from "react";
import { Image, Col } from "react-bootstrap";


const MyNftsContent = (props) => {
    let { artObj} = props
    return (
        <>
            <Col md={4}>
                <div className="created-nft-tabs-description">
                    <div className="created-nft-header-subscription-img d-flex align-items-center justify-content-center">
                        
                        <Image src={`${process.env.REACT_APP_IMAGE_SERVER + 1 + '/'+artObj.userId + '/' + artObj.folderHash
                          }`} fluid />
                    </div>
                    <h3 className="text-center">{artObj?.collection.name}</h3>
                </div>
            </Col>

        </>
    )

}

export default MyNftsContent;