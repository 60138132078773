import React from "react";
import { Image } from "react-bootstrap";
import '../../../assets/js/script';
import { NavLink } from "react-router-dom";
import logo from "assets/images/mutolab.png";

const UserDashboardSide = (props) => {
    const { routes } = props;
    return (

        <div className="dashboard-area">
            <NavLink className="site-logo" to="/home">
                <div className="logo">
                    <Image src={logo} fluid />
                </div>
            </NavLink>
            <ul className="list dashboard-list p-0">
                {routes?.map((item, index) => (
                    <li key={index} className="list-item">
                        <NavLink activeClassName="active" to={item.path} className='d-flex align-items-center'>
                            {item.svg}
                            {item.name}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>

    )
}

export default UserDashboardSide;
