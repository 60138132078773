import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
const Columns = [
  {
    id: "name",
    label: "Category",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "name",
    label: "Category",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "name",
    label: "Category",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "name",
    label: "Category",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "name",
    label: "Category",
    sort: true,
    class: "bg-body-light",
  },
];
const TableHeader = () => {
  return (
    <thead
      colSpan="5"
      className="text-primary text-capitalize font-size-sm font-weight-bold border-top">
      <tr>
        {Columns.map((headCell) => (
          <th key={headCell.label} className={headCell.class}>
            <Skeleton
              animation="wave"
              height={66}
              width="100%"
              style={{ marginTop: "-7px" }}
            />
          </th>
        ))}
      </tr>
    </thead>
  );
};
export default TableHeader;
