/*eslint-disable*/
import { useState, useEffect, useContext, useCallback } from "react";
import {
	ProfileFormFields,
	ProfileInitialValues,
	ProfileSchema,
	ProfileGetInitialValues,
} from "./profileHelpers";
import {
	PasswordInitialValues,
	PasswordFormFields,
	PasswordSchema
} from './passwordHelpers'
import { Modal, Button, Image } from "react-bootstrap";
import GenericFormikUserForm from "ReuseableCompsUser/GenericForms";
import { GetUser, UpdateUserProfile, UpdatePassword } from "services/user";
import { ToasterContext } from "app";
import { Row, Col } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { getAuthHeader } from "../../../config/utils";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { ImageContext } from "../../../app";
import { serverUrl } from "../../../config/config";

const Security = (props) => {

	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [upload, setUpload] = useState("");
	const [show, setShow] = useState(false);
	const [showtwo, setShowTwo] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [tostify, setTostify] = useContext(ToasterContext);
	const handleCloseTwo = () => setShowTwo(false);
	const handleShowTwo = () => setShowTwo(true)
	const [user, setUser] = useState('')
	const [temp, setTemp] = useState('')
	const [userWithPlan, setUserWithPlan] = useState('')
	const [active, setActive] = useState('profile')
	const [isCancel, setIsCancel] = useState(false)
	const [profileImage, setProfileImage] = useContext(ImageContext);

	const history = useHistory()

	const getUserData = (email) => {
		setLoading(true);
		GetUser(email).then((res) => {
			if (res.status === 200) {
				setData(res?.data);
				setUpload(res.data.profileImage);
				setLoading(false);
			} else {
				setLoading(false);
				setTostify({
					...tostify,
					visible: true,
					key: Math.random().toString(36).substring(7),
					type: res && res.status === 200 ? "success" : "error",
					message: res.message,
				});
			}
		});
	};

	const UpdateProfile = useCallback((data, actions) => {
		delete data.profileImage
		UpdateUserProfile(data).then((res) => {
			if (res.status === 200) {
				setData(res.data);
				localStorage.setItem("user", JSON.stringify(res.data));
				localStorage.setItem("role", res.data.role);
				setUpload(res.data.profileImage);
				actions.setSubmitting(false);
			}
			toast(res.message, {
				position: "top-right",
				autoClose: 5000,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				type: res && res.status === 200 ? "success" : "error",
			})
		});
	}, []);

	const UpdateProfileImage = () => {
		if(upload === ''){
			toast('Please Upload Profile Picture', {
				position: "top-right",
				autoClose: 5000,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				type: "warning",
			})
		}else{
			let userId = user.id ? user.id : user._id
			UpdateUserProfile({id: userId, 'profileImage': upload}).then((res) => {
				if (res.status === 200) {
					setUpload(res.data.profileImage);
					setProfileImage(serverUrl + res.data.profileImage);
				}
				toast(res.message, {
					position: "top-right",
					autoClose: 5000,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					type: res && res.status === 200 ? "success" : "error",
				})
			});
		}
	}

	const UpdatePasswordService = (data, actions) => {
		tostify.dismissAll();
		var userId = JSON.parse(localStorage.getItem("user"));
		let neww = { ...data, id: userId };
		UpdatePassword(neww).then((res) => {
			if (res && res.status === 200) {
				actions.resetForm();
			}
			actions.setSubmitting(false);
			toast(res.message, {
				position: "top-right",
				autoClose: 5000,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				type: res && res.status === 200 ? "success" : "error",
			})
		});
	};

	const param = useParams()
	useEffect(() => {
		var userr = JSON.parse(localStorage.getItem("user"));
		if (userr) {
			getUserData(userr.email);
			setUser(userr)
			let tempp = userr?.email.replace(
				userr.email.substr(3, userr.email.length - 6),
				userr.email.substr(1, userr.email.length - 6).replace(/./g, "*")
			);
			setTemp(tempp)
		}

		const userWithPlan = async () => {

			const headers = await getAuthHeader();
			await axios.post(process.env.REACT_APP_BASE_URL + "/admin/plans/user-paln", { userId: param.userId }, headers)
				.then((response) => {

					if (response.data && response.data.code === 200) {
						setUserWithPlan(response?.data?.data[0])
					}
				})
				.catch((err) => {
					console.log(err)
				});
		}
		userWithPlan()
	}, []);


	const dateConvert = (date) => {
		var month = [];
		month[0] = "Jan";
		month[1] = "Feb";
		month[2] = "Mar";
		month[3] = "Apr";
		month[4] = "May";
		month[5] = "Jun";
		month[6] = "Jul";
		month[7] = "Aug";
		month[8] = "Sep";
		month[9] = "Oct";
		month[10] = "Nov";
		month[11] = "Dec";
		var ts = new Date(date * 1000)
		return ts.getDate() + " " + month[ts.getMonth()] + " " + ts.getFullYear()
	}

	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: "btn btn-simple text-success",
			cancelButton: "btn btn-simple text-danger",
		},
		buttonsStyling: false,
	});

	const confirmBox = async () => {

		swalWithBootstrapButtons.fire({
			title: "",
			text: "Are you sure you want to cancel subscription?",
			icon: "warning",
			background: "#27293d",
			confirmButtonText: 'Yes',
			showCancelButton: true,
		}).then(async (value) => {

			if (value.isConfirmed === true) {

				let header = await getAuthHeader();
				await axios.post(
					`${process.env.REACT_APP_BASE_URL}/admin/plans/cancel-subscription`,
					{
					},
					header
				)
					.then(function (res) {
						if (res.data.code === 200) {
							setData(res.data.user)
							setIsCancel(true)
							toast(res.data.message, {
								position: "top-right",
								autoClose: 5000,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								type: "success",
							})
						} else {
							toast('Something went wrong', {
								position: "top-right",
								autoClose: 5000,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								type: "error",
							})
						}
					});
			}
		})
	}

	return (
		<>
			<div className="content">
				<div className="container-fluid my-3 py-3">
					<div className="row mb-5">
						<div className="col-xl-4 col-lg-12 profile-user">
							<div className="manage-nfts-main-section position-sticky top-1 tabs-list">
								<ul className="list dashboard-list p-0 nav flex-column border-radius-lg ">
									<li className="list-item">
										<a
											className={active === 'profile' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('profile')}
										>
											<span className="text-sm">Profile</span>
										</a>
									</li>
									<li className="list-item d-flex align-items-center">
										<a
											className={active === 'plan-info' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('plan-info')}
										>
											<div className="icon me-2"></div>
											<span className="text-sm">Plan Info</span>
										</a>
									</li>
									<li className="list-item d-flex align-items-center">
										<a
											className={active === 'password' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('password')}
										>
											<div className="icon me-2"></div>
											<span className="text-sm">
												Change Password
											</span>
										</a>
									</li>
									<li className="list-item d-flex align-items-center">
										<a
											className={active === '2fa' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('2fa')}
										>
											<div className="icon me-2"></div>
											<span className="text-sm">2FA</span>
										</a>
									</li>
									<li className="list-item d-flex align-items-center">
										<a
											className={active === 'sessions' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('sessions')}
										>
											<div className="icon me-2"></div>
											<span className="text-sm">Sessions</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="responsive-profile-user mb-4 mx-auto">
							<div className="manage-nfts-main-section position-sticky top-1 tabs-list">
								<ul className="list dashboard-list p-0 nav border-radius-lg ">
									<li className="list-item">
										<a
											className={active === 'profile' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('profile')}
										>
											<span className="text-sm">Profile</span>
										</a>
									</li>
									<li className="list-item d-flex align-items-center">
										<a
											className={active === 'plan-info' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('plan-info')}
										>
											<div className="icon me-2"></div>
											<span className="text-sm">Plan Info</span>
										</a>
									</li>
									<li className="list-item d-flex align-items-center">
										<a
											className={active === 'password' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('password')}
										>
											<div className="icon me-2"></div>
											<span className="text-sm">
												Change Password
											</span>
										</a>
									</li>
									<li className="list-item d-flex align-items-center">
										<a
											className={active === '2fa' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('2fa')}
										>
											<div className="icon me-2"></div>
											<span className="text-sm">2FA</span>
										</a>
									</li>
									<li className="list-item d-flex align-items-center">
										<a
											className={active === 'sessions' ? "nav-link text-body d-flex align-items-center active " : "nav-link text-body d-flex align-items-center"}
											data-scroll=""
											href={() => false}
											onClick={() => setActive('sessions')}
										>
											<div className="icon me-2"></div>
											<span className="text-sm">Sessions</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-8 col-lg-12 mt-lg-0 mt-4">
							{active === 'profile' &&
								<GenericFormikUserForm
									key={"User-profile-form" + Math.random().toString(36).substring(7)}
									heading={"Profile"}
									data={data}
									upload={upload}
									setUpload={setUpload}
									type={"profile"}
									initialValues={ProfileInitialValues}
									getValues={ProfileGetInitialValues}
									schema={ProfileSchema}
									formFields={ProfileFormFields}
									update={UpdateProfile}
									classProfile={'userProfile-class'}
									UpdateProfileImage={UpdateProfileImage}
								/>}
							{
								active === 'plan-info' && userWithPlan &&
									Object.keys(userWithPlan).length > 0 ?
									<Row>
										<Col md="12">
											<div className="cb-wrapper pt-0">
												<Row>
													<Col lg='12' xl='6' className="mb-xl-0 mb-4">
														<div className="current-plan design" id='plan-info'>
															<div className="manage-nfts-main-section-content">
																<h3 className="main-heading">Current Plan</h3>
															</div>
															<div className="current-plan-box box">
																<div className="heading text-center">
																	<h4>{userWithPlan?.subscriptionObj[0]?.packageTitle}</h4>
																</div>
																<ul className="list p-0 mb-3 text-center">
																	<li className="list-item">NFT Limit: {userWithPlan?.subscriptionObj[0]?.maxGenerateNfts}</li>
																	<li className="list-item">Collection Limit: {userWithPlan?.subscriptionObj[0]?.maxGenerateCollection}</li>
																</ul>
																<div className="text-center">
																	<Button className="btn-theme" onClick={() => history.push('/home#pricing')}>Change Plan</Button>
																</div>
																{data?.isCancel === true || isCancel === true || userWithPlan?.subscriptionObj[0]?.packageTitle === 'free' || userWithPlan?.subscriptionObj[0]?.packageTitle === 'Free' ?
																	<div className="text-center">
																		<Button className="btn-theme aaaa" disabled>Cancel Subscription</Button>
																	</div>
																	:
																	<div className="text-center">
																		<Button className="btn-theme" onClick={confirmBox}>Cancel Subscription</Button>
																	</div>
																}
															</div>
														</div>
													</Col>
													<Col lg='12' xl='6'>
														<div className="billing current-plan design">
															<div className="manage-nfts-main-section-content">
																<h3 className="main-heading">Billing and Payment</h3>
															</div>
															<div className="billing-content box">
																<div className="billing-content-wrapper d-flex align-items-center mb-4">
																	<div className="billing-content-wrapper-image mr-3">
																		<Image src="images/Icon payment.svg"></Image>
																	</div>
																</div>
																<div className="billing-content-annualplan">
																	<h5>${(Math.round(userWithPlan?.subscriptionObj[0]?.price) / 100).toFixed(2)}/{userWithPlan?.packageObj[0]?.type}</h5>
																	<p className="ms-0 mb-4">
																		{userWithPlan?.subscriptionObj[0]?.packageTitle === 'free' || userWithPlan?.subscriptionObj[0]?.packageTitle === 'Free' ? 
																		`It will expire on ${dateConvert(userWithPlan?.subscriptionObj[0]?.endDate)}`: 
																		`It will auto-renew on 
																		${
																			dateConvert(userWithPlan?.subscriptionObj[0]?.endDate)
																		} if you didn't cancel subscription!`
																	} </p>
																</div>
																<div className="view-billing d-flex align-items-center">
																	<Link to="/payments">View billing history</Link>
																</div>

															</div>
														</div>
													</Col>
												</Row>

											</div>

											<div className="cb-wrapper">
												<Row>
													<Col xl='12'>
														<div className="billing current-plan design">
															<div className="current-plan-box pt-5 box">
																<div className="billing-list-info ">
																	<div className="billing-list-info-content mb-2 d-flex justify-content-between">
																		<h4>Total NFT limit:</h4>
																		<p>{userWithPlan?.subscriptionObj[0]?.maxGenerateNfts}</p>
																	</div>
																	<div className="billing-list-info-content mb-2 d-flex justify-content-between">
																		<h4>Total Collection limit:</h4>
																		<p>{userWithPlan?.subscriptionObj[0]?.maxGenerateCollection}</p>
																	</div>
																</div>
																<div className="billing-list-info ">
																	<div className="billing-list-info-content mb-2 d-flex justify-content-between">
																		<h4>Total NFT used:</h4>
																		<p>{userWithPlan?.subscriptionObj[0]?.maxGenerateNfts - userWithPlan?.maxNft}</p>
																	</div>
																	<div className="billing-list-info-content mb-2 d-flex justify-content-between">
																		<h4>Total Collection used:</h4>
																		<p>{userWithPlan?.subscriptionObj[0]?.maxGenerateCollection - userWithPlan?.maxCollection}</p>
																	</div>
																</div>
																<div className="billing-list-info pb-0">
																	<div className="billing-list-info-content mb-2 d-flex justify-content-between">
																		<h4>Remaining NFT:</h4>
																		<p>{userWithPlan?.maxNft}</p>
																	</div>
																	<div className="billing-list-info-content mb-2 d-flex justify-content-between">
																		<h4>Remaining Collection:</h4>
																		<p>{userWithPlan?.maxCollection}</p>
																	</div>
																</div>
															</div>
														</div>
													</Col>
												</Row>
											</div>
										</Col>
									</Row>
									: ''
							}


							{active === 'password' &&
								<GenericFormikUserForm
									key={"User-password-form" + Math.random().toString(36).substring(7)}
									heading={"password"}
									data={[]}
									type={"password"}
									initialValues={PasswordInitialValues}
									schema={PasswordSchema}
									formFields={PasswordFormFields}
									update={UpdatePasswordService}
									classProfile={'userProfile-class'}
								/>}
							{active === '2fa' &&
								<GenericFormikUserForm
									key={"User-profile-form" + Math.random().toString(36).substring(7)}
									heading={"2fa"}
									data={[]}
									type={"2fa"}
									initialValues={ProfileInitialValues}
									schema={ProfileSchema}
									formFields={ProfileFormFields}
								/>}
							{active === 'sessions' &&
								<GenericFormikUserForm
									key={"User-profile-form" + Math.random().toString(36).substring(7)}
									heading={"sessions"}
									data={data}
									type={"sessions"}
									initialValues={ProfileInitialValues}
									schema={ProfileSchema}
									formFields={ProfileFormFields}
									active={active}
								/>}
						</div>
					</div>
				</div>
			</div>

			<Modal show={show} onHide={handleClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header>
				<Modal.Body>asmfbadjhgfyugaewufgdbvuygyuyj</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
			<Modal show={showtwo} onHide={handleCloseTwo} centered>
				<Modal.Header closeButton>
					<Modal.Title>Modal heading</Modal.Title>
				</Modal.Header>
				<Modal.Body></Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal>
		</>
	);
};

export default Security;
