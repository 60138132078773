/* eslint-disable */
import { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import AlertToaster from "../../ReuseableComps/Toast";
import PresentationLayout from "../../layouts/PresentationLayout";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/scss/black-dashboard-react.scss";
import "../../assets/demo/demo.css";
import "../../assets/css/nucleo-icons.css";
import FullPageLoader from "ReuseableComps/FullPageLoader/full-page-loader";
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
  integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
  crossorigin="anonymous"
/>;
const HomePage = lazy(() => import("../../pages/auth/homepage"));
const Dashboard = lazy(() => import("../../pages/admin/dashboard"));
const Collections = lazy(() => import("../../pages/admin/collection"));
const Gallery = lazy(() => import("../../pages/admin/gallery"));
const Categories = lazy(() => import("../../pages/admin/categories"));
const UserProfile = lazy(() => import("../../pages/admin/userProfile"));
const Networks = lazy(() => import("../../pages/admin/networks"));
const GenerateNFT = lazy(() => import("../../pages/admin/generateNFT"));
const SiteSettings = lazy(() => import("../../pages/admin/siteSettings"));
const Banner = lazy(() => import("../../pages/admin/cms/banner"));
const About = lazy(() => import("../../pages/admin/cms/about"));
const PricingPlans = lazy(() =>
  import("../../pages/admin/cms/pricingPlans/pricingPlan")
);
const Monthly = lazy(() =>
  import("../../pages/admin/cms/pricingPlans/monthly")
);
const Services = lazy(() => import("../../pages/admin/cms/services"));
const HowItWorks = lazy(() => import("../../pages/admin/cms/howItWorks"));
const Faqs = lazy(() => import("../../pages/admin/cms/faqs/faq"));
const FaqsList = lazy(() => import("../../pages/admin/cms/faqs/faqsList"));
const ContactUs = lazy(() => import("../../pages/admin/cms/contactUs"));
const FeaturedProjects = lazy(() =>
  import("../../pages/admin/cms/featuredProjects")
);
const Password = lazy(() => import("../../pages/admin/Password"));
const Payments = lazy(() => import("../../pages/admin/payments"));
const NftCollection = lazy(() => import("../../pages/admin/userNftCollection"));
const ContactUsQueries = lazy(() => import("../../pages/admin/contactUs"));
const PrivacyPolicy = lazy(() => import('../../components/GeneralPage/PrivacyPolicy'));
const TermCondition = lazy(() => import("../../components/GeneralPage/TermsAndConditions"));

const AdminRoutes = (props) => {
  const { color, setTostify, tostify } = props;
  const location = useLocation();
  useEffect(() => {
    tostify.dismissAll();
    setTostify({ ...tostify, visible: false, key: "", type: "", message: "" });
  }, [location.pathname]);
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3,
  };
  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <FullPageLoader />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  const TostifyWait = () => {
    const [showTostify, setShowTostify] = useState(false);
    useEffect(() => {
      let timeout = "";
      if (location.pathname === "/dashboard") {
        let timeout = setTimeout(() => setShowTostify(true), 300);
      } else {
        let timeout = setTimeout(() => setShowTostify(true), 300);
      }
      return () => {
        clearTimeout(timeout);
      };
    }, []);
    return (
      <>
        {showTostify && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AlertToaster setTostify={setTostify} {...tostify} />
          </motion.div>
        )}
      </>
    );
  };

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        <TostifyWait />
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Route path={["/home", "/privacy-policy", "/term-and-condition"]}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                style={{ height: "100%" }}
              >
                <Route path="/home" component={HomePage} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/term-and-condition" component={TermCondition} />
              </motion.div>
            </Switch>
          </Route>
          <Route
            path={[
              "/dashboard",
              "/gallery",
              "/categories",
              "/profile",
              "/networks",
              "/generateNFT",
              "/collections",
              "/site",
              "/banner",
              "/aboutmulti",
              "/plans",
              "/plansListing",
              "/services",
              "/howItWorks",
              "/faqs",
              "/faqsList",
              "/contactus",
              "/featuredProjects",
              "/password",
              "/payments",
              "/collection/:userId",
              "/query",
            ]}
          >
            <PresentationLayout color={color}>
              <Switch location={location} key={location.pathname}>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/gallery" component={Gallery} />
                <Route path="/categories" component={Categories} />
                <Route path="/profile" component={UserProfile} />
                <Route path="/networks" component={Networks} />
                <Route path="/generateNFT" component={GenerateNFT} />
                <Route path="/collections" component={Collections} />
                <Route path="/site" component={SiteSettings} />
                <Route path="/banner" component={Banner} />
                <Route path="/aboutmulti" component={About} />
                <Route path="/plans" component={PricingPlans} />
                <Route path="/plansListing" component={Monthly} />
                <Route path="/services" component={Services} />
                <Route path="/howItWorks" component={HowItWorks} />
                <Route path="/faqs" component={Faqs} />
                <Route path="/faqsList" component={FaqsList} />
                <Route path="/contactus" component={ContactUs} />
                <Route path="/featuredProjects" component={FeaturedProjects} />
                <Route path="/password" component={Password} />
                <Route path="/payments/:userId" component={Payments} />
                <Route path="/collection/:userId" component={NftCollection} />
                <Route path="/query" component={ContactUsQueries} />
              </Switch>
            </PresentationLayout>
          </Route>

          <Route render={() => <Redirect to="/dashboard" />} />
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};
export default AdminRoutes;
