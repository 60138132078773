/* eslint-disable */
import TextFieldLabel from "../TextfieldLabel";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { useEffect } from "react";
const FieldSelect = (props) => {
  const {
    values,
    touched,
    errors,
    fieldName,
    field,
    required,
    widthClass,
    setFieldValue,
    selectedNetwork,
    setSelectedNetwork,
    options,
    walletPhase,
    deployPhase,
    heading
  } = props;

  useEffect(() => {
    let collectionFormData = localStorage.getItem('collectionFormData') && JSON.parse(localStorage.getItem('collectionFormData'))

    setFieldValue('selectNetwork', collectionFormData?.selectNetwork ?  collectionFormData?.selectNetwork : selectedNetwork.name);
    setFieldValue("collectionSymbol", collectionFormData ? collectionFormData.collectionSymbol : selectedNetwork.nativeCurrency.symbol);
    setFieldValue("pricePerCollection", collectionFormData ? collectionFormData.pricePerCollection :'');
    setFieldValue("description", collectionFormData ? collectionFormData.description :'');
    setFieldValue("chainId", selectedNetwork.chainId);
    setFieldValue("chainName", selectedNetwork.name);
    setFieldValue("nativeCurrency", selectedNetwork.nativeCurrency);
    setFieldValue("rpcUrls", selectedNetwork.rpc);
    setFieldValue(
      "blockExplorerUrls",
      selectedNetwork.explorers &&
        selectedNetwork.explorers.length > 0 &&
        selectedNetwork.explorers.url
        ? selectedNetwork.explorers.url
        : selectedNetwork.infoURL
    );
  }, [selectedNetwork]);
  const handleChangeManual = async (e) => {
    let filterSelect = await options.filter((i) => i.name === e.target.value);
     setFieldValue("selectNetwork", e.target.value);
     setSelectedNetwork(filterSelect[0]);
     setFieldValue("collectionSymbol", filterSelect[0].nativeCurrency.symbol);
  };
  return (
    <>
      <Form.Group
        size="lg"
        className={
          touched && touched[fieldName] && errors && errors[fieldName]
            ? "has-danger has-label form-group " + widthClass
            : touched && touched[fieldName] && errors && !errors[fieldName]
            ? "has-success has-label form-group " + widthClass
            : "has-label form-group " + widthClass
        }
        controlId={"formik-form-element-" + fieldName}
      >
        <Form.Label>
          <TextFieldLabel widthclass="" field={field} required={required} />
        </Form.Label>
        {}
        <Form.Select
          size="lg"
          name={fieldName}
          onChange={handleChangeManual}
          className="form-control"
          value={values[fieldName]}
          aria-label="Default select example"
          disabled={heading === "Collections" && fieldName === "selectNetwork" && (walletPhase || deployPhase)}
        >
          {options.map((o,index) => {
            return (
                <option key={index}>{o.name}</option>
            );
          })}
        </Form.Select>
        <ErrorMessage
          name={fieldName}
          component="div"
          className={"text-warning"}
        />
      </Form.Group>
    </>
  );
};
export default FieldSelect;
