import moment from "moment";

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};
export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
//Sorting Handler
export const handleRequestSort = (
  property,
  order,
  orderBy,
  setOrder,
  setOrderBy
) => {
  const isAsc = orderBy === property && order === "asc";
  setOrder(isAsc ? "desc" : "asc");
  setOrderBy(property);
};

export const getCount = (count, limit) => {
  return count && limit && Math.ceil(count / limit) > 1
    ? Math.ceil(count / limit)
    : 1;
};
export const paginate = (page, rowsPerPage) => {
  let start = page === 1 ? 0 : (page - 1) * rowsPerPage;
  let end = page * rowsPerPage;
  return { start, end };
};

//search binding
export const searchBinding = (setSearchBar, setRowData, data, place) => {
  setSearchBar({
    placeholder: place,
    onSearch: (searchedVal) => OnSearch(searchedVal, setRowData, data),
  });
};
//Search Handler
export const OnSearch = (searchedVal, setRowData, data) => {
  if (searchedVal.length > 0) {
    const filteredRows = data.filter((row) => {
      let match = false;
      // match name
      if (!match && row.name) {
        match = row.name.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match userName
      if (!match && row.username) {
        match = row.username.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match firstname
      if (!match && row.firstname) {
        match = row.firstname.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match lastname
      if (!match && row.lastname) {
        match = row.lastname.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match useremail
      if (!match && row.useremail) {
        match = row.useremail.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match useremail
      if (!match && row.email) {
        match = row.email.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match designation
      if (!match && row.designation) {
        match = row.designation
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      // match slider heading
      if (!match && row.head1) {
        match = row.head1.toLowerCase().includes(searchedVal.toLowerCase());
      }
      //   // match emailStatus
      //   if (!match && row.emailStatus) {
      //     match = row.emailStatus.toLowerCase().includes(searchedVal.toLowerCase());
      //   }
      // match kycstatus
      if (!match && row.kycstatus) {
        match = row.kycstatus.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match paymentMethod
      if (!match && row.paymentMethod) {
        match = row.paymentMethod
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      // match title
      if (!match && row.title) {
        match = row.title.toLowerCase().includes(searchedVal.toLowerCase());
      }
      // match title
      if (!match && row.status.toString().length > 0) {
        match = row.status
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      // match dealine year
      if (!match && row.deadline) {
        match = moment(row.deadline)
          .format("MMMM DD YYYY")
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      // match dealine month
      if (!match && row.deadline) {
        match = moment(row.deadline)
          .format("MMMM")
          .toString()
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      }
      //match wallet address
      //   if (!match && row.payableToken) {
      //     match = row.payableToken
      //       .toLowerCase()
      //       .includes(searchedVal.toLowerCase());
      //   }
      return match;
    });
    setRowData(filteredRows);
  } else {
    setRowData(data);
  }
};
