import * as Yup from "yup";
export const MonthlyTableHeadings = [
  {
    id: "title",
    label: "Title",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "subHead",
    label: "Sub Heading",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "maxGenerateCollection",
    label: "Collections Generation Limit",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "maxGenerateNfts",
    label: "NFTs Generation Limit",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "price",
    label: "Price",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "type",
    label: "Type",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "status",
    label: "Status",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light",
  },
];

export const createRows = (rowData) => {

  const rows = rowData.map((i) => {

    return {
      id: i._id,
      title: i.title,
      stripeProductId: i.stripeProductId,
      type: i.type,
      subHead: i.subHead,
      maxGenerateCollection: i.maxGenerateCollection,
      waterMarkStatus: i.waterMarkStatus,
      watermark: i.watermark,
      maxGenerateNfts: i.maxGenerateNfts,
      price: i.price === "" ? 0 : i.price,
      status: i.status,
      description: i.description,
    };
  });
  return rows;
};

export const setInitialValues = (updatedRow) => {

  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    stripeProductId: updatedRow.stripeProductId ? updatedRow.stripeProductId : '',
    title: updatedRow.title ? updatedRow.title : "",
    type: updatedRow.type ? updatedRow.type : "",
    subHead: updatedRow.subHead ? updatedRow.subHead : "",
    price: updatedRow.price ? updatedRow.price / 100 : 0,
    status: updatedRow.status ? updatedRow.status : "",
    description: updatedRow.description ? updatedRow.description : "",
    waterMarkStatus: updatedRow.waterMarkStatus ? updatedRow.waterMarkStatus : "",
    watermark: updatedRow.watermark ? updatedRow.watermark : "",
    maxGenerateCollection: updatedRow.maxGenerateCollection ? updatedRow.maxGenerateCollection : "",
    maxGenerateNfts: updatedRow.maxGenerateNfts ? updatedRow.maxGenerateNfts : ""

  };
  return Values;
};

export const MonthlyInitialValues = {
  title: "",
  type: "month", //default
  subHead: "",
  price: "",
  waterMarkStatus: "False",
  watermark: "",
  status: true,
  description: "",
  //maxUploadFile: "",
  maxGenerateCollection: "",
  maxGenerateNfts: ""
};

export const MonthlySchema = Yup.object().shape({
  title: Yup.string().trim('Required').required("Title is required!"),
  type: Yup.string().trim('Required').required("Type is required!"),
  subHead: Yup.string().trim('Required').required("Sub Heading is required!"),
  price: Yup.number().min(0,'Price must be greater than or equal to 0.').required("Price is required!"),
  description: Yup.string().trim('Required').required("Description is required!"),
  maxGenerateCollection: Yup.number().positive('Price must be a positive number').integer('Collections Limit must be a positive integer').min(1,'Collections limit must be greater than or equal to 1.').required("Collections Generation Limit is required!"),
  maxGenerateNfts: Yup.number().positive('Price must be a positive number').integer('NFTs Limit must be a positive integer').min(1,'NFTs Limit must be greater than or equal to 1.').required("NFTs Generation Limit is required!"),

});

export const MonthlyFormFields = [
  {
    field: "Title",
    fieldName: "title",
    place: "Enter Title",
    type: "text",
    required: true,
    field1: "Sub Heading",
    fieldName1: "subHead",
    place1: "Enter Sub Heading",
    type1: "text",
    required1: true,
  },
  {
    field: "Package Type",
    fieldName: "type",
    place: "Enter Package Type",
    type: "select",
    required: true,
    field1: "Price",
    fieldName1: "price",
    place1: "Enter price",
    type1: "number",
    required1: true,
  },
  {
    field: "Watermark Status",
    fieldName: "waterMarkStatus",
    place: "Select Watermark Status",
    type: "select",
    field1: "Watermark Text",
    fieldName1: "watermark",
    place1: "Enter Watermark Text",
    type1: "text",
  },
  {
    field: "NFTs Generation Limit",
    fieldName: "maxGenerateNfts",
    place: "Enter Amount to generate",
    type: "number",
    required: true,
    field1: "Collections Generation Limit",
    fieldName1: "maxGenerateCollection",
    place1: "Enter Amount to generate",
    type1: "number",
    required1: true,

  },
  {
    field: "Description",
    fieldName: "description",
    place: "Enter Description",
    type: "description",
    required: true,
  },
];
