import React, { useContext, useEffect } from "react";
import classNames from "classnames";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal,
  NavbarToggler,
  ModalHeader,
} from "reactstrap";
import { ImageContext } from "../../../app";
import { serverUrl } from "config/config";
import { useHistory } from "react-router-dom";
import { GetAdminInfo } from "../../../services/admin/Admin";
import defaultProfile from '../../../assets/images/defaultProfile.jpg'
import { Image } from "react-bootstrap";
import logo from "assets/images/mutolab.png";

const HeaderComp = (props) => {
  const [collapseOpen, setcollapseOpen] = React.useState(false);
  const [modalSearch, setmodalSearch] = React.useState(false);
  const [color, setcolor] = React.useState("navbar-transparent");
  const [profileImage, setProfileImage] = useContext(ImageContext);

  const user = JSON.parse(localStorage.getItem("user"));
  const getAdminData = (email) => {
    GetAdminInfo(email).then((res) => {
      if (res.status === 200) {
        if (res.data.profileImage) {
          setProfileImage(serverUrl + res.data.profileImage);
        } else {
          setProfileImage(defaultProfile)
        }
      }
    });
  };
  useEffect(() => {
    getAdminData(user.email);
    // eslint-disable-next-line
  }, []);


  let history = useHistory();
  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", updateColor);
    };
  });
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setcolor("bg-white");
    } else {
      setcolor("navbar-transparent");
    }
  };
  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setcolor("navbar-transparent");
    } else {
      setcolor("bg-white");
    }
    setcollapseOpen(!collapseOpen);
  };
  // this function is to open the Search modal
  const toggleModalSearch = () => {
    setmodalSearch(!modalSearch);
  };

  const Logout = async () => {
    await localStorage.clear();
    history.push("/home");
  };
  return (
    <>
      <Navbar className={classNames("navbar-absolute", color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: props.sidebarOpened,
              })}
            >
              <NavbarToggler onClick={props.toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            </div>
            <NavbarBrand href={() => false} onClick={(e) => history.push('/home')} className="d-flex align-items-center" style={{cursor:'pointer'}}>
              <div className="logo-admin-header mr-3">
                <Image src={logo} fluid />
              </div>
              <span className='brand-text'>{props.brandText}</span>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={toggleCollapse}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto admin-dashboard-profile-dd" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." src={profileImage} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">Log out</p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => history.push('/profile')}>Profile</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem className="nav-item" onClick={() => history.push('/site')}>Settings</DropdownItem>
                  </NavLink>
                  {/* <DropdownItem divider tag="li" /> */}
                  <NavLink tag="li">
                    <DropdownItem onClick={Logout} className="nav-item">
                      Log out
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={toggleModalSearch}
      >
        <ModalHeader>
          <Input placeholder="SEARCH" type="text" />
          <button
            aria-label="Close"
            className="close"
            onClick={toggleModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>
      </Modal>
    </>
  );
};
export default HeaderComp;
