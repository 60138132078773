import * as Yup from "yup";
export const CategoriesTableHeadings = [
  {
    id: "name",
    label: "Category",
    sort: true,
    class: "bg-body-light",
  },

  {
    id: "actions",
    label: "Actions",
    sort: true,
    class: "bg-body-light",
  },
];

export const createRows = (rowData) => {
  const rows = rowData.map((i) => {
    return {
      id: i._id,
      category: i.category,
    };
  });
  return rows;
};

export const setInitialValues = (updatedRow) => {
  const Values = {
    id: updatedRow.id ? updatedRow.id : "",
    category: updatedRow.category ? updatedRow.category : "",
  };
  return Values;
};

export const CategoryInitialValues = {
  category: "",
};
export const CategorySchema = Yup.object().shape({
  category: Yup.string().max(50, 'Must be 50 characters or less').trim('Category cannot include leading and trailing spaces').required("Category is required!"),
});

export const CategoryFormFields = [
  {
    field: "Category",
    fieldName: "category",
    place: "Enter Category",
    type: "text",
    required: true,
  },
];
