import React, { useState, useContext, useEffect } from 'react'
import GenericTable from "ReuseableComps/GenericTables";
import { ContactUsTableHeadings, ContactUsInitialValues, createRows } from "./helpers";
import { ToasterContext } from "app";
import TableRows from "./TableRows";
import SkeltonTable from "ReuseableComps/GenericTablesSkelton"
import { getContacts } from 'services/user';

const ContactUs = () => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(true);
    const [tostify, setTostify] = useContext(ToasterContext);

    const GetContactUsQueries = async () => {
        setLoading(true)
        getContacts().then(res => {
            if (res.status === 200) {
                setData(res.data);
                setLoading(false);
            } else {
                setLoading(false);
                setTostify({
                    ...tostify,
                    visible: true,
                    key: Math.random().toString(36).substring(7),
                    type: res && res.status === 200 ? "success" : "error",
                    message: res.message,
                });
            }
        })
    }

    useEffect(() => {
        GetContactUsQueries()
        //eslint-disable-next-line
    }, [])
    return (
        <div className="content">
            {loading ?
                <SkeltonTable header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} /> :
                <GenericTable
                    heading={"Contact Us Queries"}
                    Columns={ContactUsTableHeadings}
                    tostify={tostify}
                    createRows={createRows}
                    initialValues={ContactUsInitialValues}
                    data={data}
                    TableRow={TableRows}
                />
            }
        </div>
    )
}

export default ContactUs