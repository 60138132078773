import { useEffect, useState, useContext } from "react";
import GenericTable from "ReuseableComps/GenericTables";
import Swal from "sweetalert2";
import {
  NetworksTableHeadings,
  createRows,
  setInitialValues,
} from "./helpers";
import {
  GetNetworks,
  GetAllNetworks,
  AddNetwork,
  DeleteNetwork,
} from "../../../services/admin/Networks";
import TableRows from "./TableRows";
import SkeltonTable from "ReuseableComps/GenericTablesSkelton";
import { ToasterContext } from "../../../app";
import { toast } from "react-toastify";

function Networks() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tostify, setTostify] = useContext(ToasterContext);
  const [actionState, setActionState] = useState("new");
  const [open, setOpen] = useState(false);
  const [networkBtnLoading, setNetworkBtnLoading] = useState(false);
  const [allNetworks, setAllNetworks] = useState([]);
  const [newNetwork, setNewNetwork] = useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setActionState("new");
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-simple text-success",
      cancelButton: "btn btn-simple text-danger",
    },
    buttonsStyling: false,
  });
  const GetNetworksService = () => {
    setLoading(true);
    GetNetworks().then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const GetAllNetworksService = () => {
    setLoading(true);
    GetAllNetworks().then((res) => {
      if (res.status === 200) {
        setAllNetworks(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      }
    });
  };
  const AddNetworkService = () => {
    setNetworkBtnLoading(true)
    AddNetwork(newNetwork[0]).then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setOpen(false);
        setNetworkBtnLoading(false)
        setTostify({
          ...tostify,
          visible: true,
          key: Math.random().toString(36).substring(7),
          type: res && res.status === 200 ? "success" : "error",
          message: res.message,
        });
      } else {
        setNetworkBtnLoading(false)
        toast(res.message, {
          position: "top-right",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          type: res && res.status === 200 ? "success" : "error",
        })
      }
    });
  };
  
  const DeleteNetworkService = async (data, actions) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        background: "#27293d",
      })
      .then((result) => {
        if (result.isConfirmed) {
          DeleteNetwork(data).then((res) => {
            if (res.status === 200) {
              swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: res.message,
                icon: "success",
                background: "#27293d",
                timer: 1000,
              });
              setData(res.data);
            } else {
              swalWithBootstrapButtons.fire({
                title: "",
                text: res.message,
                icon: "warning",
                background: "#27293d",
                timer: 1000,
              });
            }
          });
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "",
            icon: "error",
            background: "#27293d",
            timer: 1000,
          });
        }
      });
  };
  useEffect(
    () => {
      GetNetworksService();
      GetAllNetworksService();
    },
    // eslint-disable-next-line
    []
  );
  useEffect(() => {
    setNewNetwork([allNetworks[0]])
  }, [allNetworks])

  return (
    <>
      {loading ? (
        <SkeltonTable header={[0, 1, 2, 3, 4]} rows={[0, 1, 2]} />
      ) : (
        <GenericTable
          isModal={true}
          modalHeading={"Network"}
          heading={"Network Listing"}
          actionState={actionState}
          setActionState={setActionState}
          open={open}
          //Handlers
          networkBtnLoading={networkBtnLoading}
          handleOpen={handleOpen}
          handleClose={handleClose}
          Columns={NetworksTableHeadings}
          searchPlaceHolder={"Seacrh Networks"}
          data={data}
          TableRow={TableRows}
          tostify={tostify}
          //Helpers States &  Function
          createRows={createRows}
          setInitialValues={setInitialValues}
          //SERVICES
          add={AddNetworkService}
          deleteRow={DeleteNetworkService}
          allNetworks={allNetworks}
          setNewNetwork={setNewNetwork}
        />
      )}
    </>
  );
}
export default Networks;
