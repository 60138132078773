import React from "react";
import { Pagination } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const Footer = (props) => {
  const { getCount, rowData, rowsPerPage, page, handleChangePage, color, } = props;

  const useStyles = makeStyles(() => ({
    ul: {
      "& .MuiPaginationItem-root": {
        color:
          color === "primary"
            ? "#d050dd" : "#fff",
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <div className="text-primary text-capitalize font-size-sm font-weight-bold border-top" />
      <div className=" px-4 mt-2 d-flex justify-content-center">
        <Pagination
          count={getCount(rowData.length || 0, rowsPerPage)}
          classes={{ ul: classes.ul }}
          page={page}
          onChange={handleChangePage}
          showFirstButton
          showLastButton
        />
      </div>
    </>
  );
};

export default Footer;
