/* eslint-disable */
import TextFieldLabel from "../TextfieldLabel";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { useEffect } from "react";

const FieldSelect = (props) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        fieldName,
        field,
        required,
        widthClass,
        setFieldValue,
        setFieldError,
        options,
        heading,
        getCollectionByNetwork,
        getCollectionByUser,
        fetchData,
        checkNetwork,
        actionState
    } = props;


    useEffect(() => {
        if (heading === "Yearly Plan" || heading === "Plans List") {
        } else {
            setFieldValue(
                fieldName,
                heading === "Collections"
                    ? options[0]?.name
                    : heading === "Live Collections"
                        ? ''
                        : heading === "NFT Details" ? options[0]?._id : options[0]?.category
            );
        }
    }, [options]);

    useEffect(() => {
        if (
            values["network"] &&
            heading === "Live Collections" &&
            fieldName === "network"
        ) {
            checkNetwork(values["network"], setFieldValue, values);
        }
        if((values["network"] === 'Select option')){
            setFieldValue('collections','')
            setFieldValue("contractData", '');
          }
    }, [values["network"], values['collection']]);
    
    useEffect(async () => {
        if (heading === "Live Collections" && fieldName !== "collection" && fieldName !== "selectusers") {
            const result = await getCollectionByNetwork(
                values[fieldName],
                setFieldValue,
                values['selectusers']
            )
            if (result && result.status === 200) {
                if (result?.data?.data?.length > 0) {
                    setFieldValue("collections", result.data.data);
                } else {
                    setFieldError("network", "No Collections Found on This Network");
                    setFieldValue("collections", "");
                    setFieldValue("collection", "");
                }
            }
        }

        if (heading === "Live Collections" && fieldName !== "collection" && fieldName === "selectusers") {
            const result = await getCollectionByNetwork(
                values["network"],
                setFieldValue,
                values[fieldName],
            )
            if (result && result.status === 200) {
                if (result?.data?.data?.length > 0) {
                    setFieldValue("collections", result.data.data);
                    setFieldValue("collection", result.data.data[0].name);
                } else {
                    setFieldError("network", "No Collections Found on This Network");
                    setFieldValue("collections", "");
                    setFieldValue("collection", "");
                }
            }
        }

        if (fieldName === "collection") {
            setFieldValue(
                "selectedCollection",
                options.filter((i) => i.name === values[fieldName])
            );
        }
    }, [values[fieldName]]);

    useEffect(() => {
        if (fieldName === "collection" && values["selectedCollection"].length > 0) {
            fetchData(values["selectedCollection"][0].contractAddress, setFieldValue);
        }
    }, [values["selectedCollection"]]);

    const handleChangeManual = async (e) => {
        let index = e.nativeEvent.target.selectedIndex;
        let setNetworlVal
        if (e.nativeEvent.target[index].text === 'Select option') {
            setNetworlVal = 'null'
        } else {
            setNetworlVal = values["network"]
        }
        const result = await checkNetwork(
            e.target.value,
            setFieldValue,
            setNetworlVal
        );
        if (result === null) {
            setFieldValue("network", e.target.value);
        }
        if (result === undefined) {
            setFieldValue("network", e.target.value);
        }
    };
    return (
        <>
            <Form.Group
                size="lg"
                className={
                    touched && touched[fieldName] && errors && errors[fieldName]
                        ? "has-danger has-label form-group " + widthClass
                        : touched && touched[fieldName] && errors && !errors[fieldName]
                            ? "has-success has-label form-group position-relative " + widthClass
                            : "has-label form-group " + widthClass
                }
                controlId={"formik-form-element-" + fieldName}
            >
                <Form.Label>
                    <TextFieldLabel widthclass="" field={field} required={required} />
                </Form.Label>

                <Form.Select
                    as="select"
                    size="lg"
                    name={fieldName}
                    onChange={
                        heading === "Live Collections" && fieldName !== "collection" && fieldName !== "selectusers" && fieldName !== "network"
                            ? handleChangeManual : handleChange
                    }
                    className="form-control"
                    aria-label="Default select example"
                    defaultValue={heading === "NFT Details" ? options[0]._id : values[fieldName]}
                    disabled={(actionState === 'edit' && (values[fieldName] === 'month' || values[fieldName] === 'year')) || values.possibleCombinations > 0 ? true : false}
                >
                    {(heading === "NFT Details" || heading === "Plans List") ? "" : <option selected={heading === "Live Collections" && fieldName === "collection" && values['selectedCollection']?.length === 0}>Select option</option>}
                    {options?.map((o) => {

                        return (
                            <>
                                {
                                    heading === "NFT Details" ?
                                        <>

                                            <option value={o._id} >
                                                {o.category?.toUpperCase()}
                                            </option>
                                        </>

                                        :
                                        heading === "Live Collections" && fieldName === "selectusers" ?

                                            <option value={o._id} >
                                                {o.email}
                                            </option>

                                            :
                                            <option>
                                                {
                                                    heading === "Collections"
                                                        ? o.name
                                                        : heading === "Live Collections" && fieldName === "selectusers" ?
                                                            o.email : heading === "Live Collections" && fieldName !== "selectusers"
                                                                ? o.name
                                                                : o.category
                                                }
                                            </option>
                                }
                            </>
                        );
                    })}
                </Form.Select>
                <ErrorMessage
                    name={fieldName}
                    component="div"
                    className={"text-warning"}
                />
            </Form.Group>
        </>
    );
};
export default FieldSelect;