
const TableRow = (props) => {
    const {
        row,
        index,
    } = props;
    
    return (
        <>
            <tr key={row.id + index} id={"monthly-tr-" + index}>
                <td className="text-left ">
                    <span>{row.txn_id ? row.txn_id : '992323632452'}</span>
                </td>
                <td className="text-left ">
                    <span>{row.package}</span>
                </td>
                <td className="text-left ">
                    <span>${row.amount}</span>
                </td>
                <td className="text-left ">
                    <span>${row.amount}</span>
                </td>
                <td className="text-left ">
                    <span>Stripe</span>
                </td>
                <td className="text-left ">
                    <span>{row.type}</span>
                </td>
                <td className="text-left ">
                    <span>{row.paymentdate}</span>
                </td>
                <td className="text-left ">
                    <span>Paid</span>
                </td>
            </tr>
        </>
    );
};
export default TableRow;
