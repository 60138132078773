import * as Yup from "yup";
export const GalleryInitialValues = {
  network: "",
  networkChangeStatus: "",
  collection: "",
  selectedCollection: "",
  collections: "",
  contractData: "",
  selectusers:""
};

export const GalleryFormFields = [

  {
    field: "Select User",
    fieldName: "selectusers",
    place: "",
    type: "select",
    required: true
  },


  {
    field: "Select Network",
    fieldName: "network",
    place: "",
    type: "select",
    required: true,
    field1: "Select Collection",
    fieldName1: "collection",
    place1: "",
    type1: "select",
    required1: true,
  },


  {
    field: "Selected Collection",
    fieldName: "selectedCollection",
    place: "",
    type: "tableRow",
    required: true,
  },
];

export const GallerySchema = Yup.object().shape({
  network: Yup.string().required("Network is required!"),
});

export const CollectionTableMiniHeadings = [
  {
    id: "name",
    label: "Name",
    sort: true,
    class: "bg-body-light",
  },

  {
    id: "symbol",
    label: "Symbol",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "network",
    label: "Network",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "price",
    label: "Price",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "minted",
    label: "Minted",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "supply",
    label: "Supply",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "revealed",
    label: "Revealed",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "balance",
    label: "balance",
    sort: true,
    class: "bg-body-light",
  },
  {
    id: "address",
    label: "address",
    sort: true,
    class: "bg-body-light",
  },
];
