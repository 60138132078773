/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { getComparator, stableSort, getCount, paginate } from "./helpers";
import { CardBody, Table, Row, Col, Button, FormGroup, Input, Label, CardHeader } from "reactstrap";

import { TableContainer } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import TableCardHeader from "./TableCardHeader";
import Header from "./Header";
import { searchBinding } from "./helpers";
import Footer from "./Footer";
import GenericModalBody from "../../ReuseableComps/AddEditFormModal";
import { SideBarBgContext } from "../../app";

import useStyles from "../../assets/CustomeMaterialStyleSheet";
import { ClipLoader } from "react-spinners";

const GenericTable = (props) => {
  const [color, setColor] = useContext(SideBarBgContext);
  const {
    searchPlaceHolder,
    Columns,
    data,
    createRows,
    TableRow,
    // loading,
    updatedRow,
    setUpdatedRow,
    actionState,
    setActionState,
    open,
    handleOpen,
    handleClose,
    isModal,
    modalHeading,
    heading,
    initialValues,
    schema,
    setInitialValues,
    formFields,
    add,
    updateStatus,
    deleteRow,
    update,
    collectionBtn,
    subscriptionBtn,
    paymentBtn,
    setNewNetwork,
    allNetworks,
    role,
    redirectLink,
    downloadNft,
    networkBtnLoading
  } = props;
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const pagination = paginate(page, rowsPerPage);
  const [searchBar, setSearchBar] = useState({
    placeholder: "",
    onSearch: () => { },
  });
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("userId");
  const classes = useStyles();
  useEffect(() => {
    setRowData(data);

  }, [data]);
  useEffect(() => {
    searchBinding(setSearchBar, setRowData, data, searchPlaceHolder);
  }, [setSearchBar]);
  const rows = createRows(rowData?.slice(pagination.start, pagination.end));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if(rows.length === 0 && page > 1){
      setPage(page-1)
    }
  }, [rows])
  

  const handleAdd = (data) => {
    add(data);
  };

  return (
    <>
      <div className={"content all-list"}>
        <Row>
          <Col md="12">
            <div className={`${role === "user" ? 'card-user card role-user user-icons' : 'card-user card'}`}>
              <div className="card-body">
                {heading === 'Users List' ? '' : role === 'user' ? '' :
                  <div className="author">
                  </div>}
                <TableCardHeader
                  color={color}
                  heading={heading}
                  handleOpen={handleOpen}
                />
                <Modal
                  open={open ? open : false}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div>
                    {" "}
                    {heading === "Network Listing" && modalHeading === "Network" ?
                      <div className='all-modal'>
                        <div className="card-user card">
                          <div className="card-body">
                            <div className="author">
                              <CardHeader className="d-flex justify-content-between p-2">
                                <h4 className="content-heading text-white ">
                                  {actionState === "edit" && isModal
                                    ? "Edit " + heading
                                    : actionState === "edit"
                                      ? heading
                                      : null}
                                  {actionState !== "edit" && isModal
                                    ? "Add " + heading
                                    : actionState !== "edit"
                                      ? heading
                                      : null}
                                </h4>
                                <Button
                                  className={
                                    color === "btn-fill btn-primary btn-theme"}
                                  type="link"
                                  size="sm"
                                  onClick={() => {
                                    handleClose();
                                    setUpdatedRow({});
                                  }}
                                >
                                  <i className={"tim-icons icon-simple-remove"} />
                                </Button>
                              </CardHeader>
                            </div>
                            <div className="mt-3">
                              <FormGroup className="mt-2">
                                <div>
                                  <Label for="exampleSelect1">Select Network</Label>
                                  <Input
                                    type="select"
                                    name="select"
                                    id="exampleSelect1"
                                    className="mt-2"
                                    onChange={(e) =>
                                      setNewNetwork(
                                        allNetworks.filter((i) => i.name === e.target.value)
                                      )
                                    }
                                  >
                                    {allNetworks.length > 0 &&
                                      allNetworks.map((network, index) => {
                                        return <option key={index}>{network.name}</option>;
                                      })}
                                  </Input>
                                </div>
                                <Button
                                  className={"btn-fill btn-primary mt-4 btn-theme"}
                                  color="primary"
                                  type="submit"
                                  size="sm"
                                  disabled={networkBtnLoading}
                                  onClick={() => {
                                    handleAdd();
                                  }}
                                >
                                  {networkBtnLoading ?
                                    <div className="d-flex align-items-center">
                                      <ClipLoader color={"white"} /> <span className="ml-2">processing....</span>
                                    </div>
                                    : 'Add Network'
                                  }
                                </Button>
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      <GenericModalBody
                        color={color}
                        isModal={isModal}
                        heading={modalHeading}
                        setUpdatedRow={setUpdatedRow}
                        updatedRow={updatedRow}
                        actionState={actionState}
                        handleClose={handleClose}
                        initialValues={initialValues}
                        setInitialValues={setInitialValues}
                        schema={schema}
                        formFields={formFields}
                        add={add}
                        update={update}
                      />}
                  </div>
                </Modal>
                <CardBody>
                  <TableContainer>
                    <Table className="tablesorter table">
                      <Header Columns={Columns} />

                      <tbody>
                        {rows.length === 0 ? (
                          <td className="text-center" colSpan={20}>No record found!</td>
                        ) : (
                          stableSort(rows, getComparator(order, orderBy)).map(
                            (row, index) => {
                              return (
                                <>
                                  <TableRow
                                    key={
                                      Math.random().toString(36).substring(7) +
                                      "tablerow" +
                                      index +
                                      Math.random().toString(36).substring(7)
                                    }
                                    row={row}
                                    index={index}
                                    updateStatus={updateStatus}
                                    handleOpen={handleOpen}
                                    setUpdatedRow={setUpdatedRow}
                                    setActionState={setActionState}
                                    deleteRow={deleteRow}
                                    update={update}
                                    collectionBtn={collectionBtn}
                                    subscriptionBtn={subscriptionBtn}
                                    paymentBtn={paymentBtn}
                                    redirectLink={redirectLink}
                                    heading={heading}
                                    downloadNft={downloadNft}
                                  />
                                </>
                              );
                            }
                          )
                        )}
                      </tbody>
                    </Table>
                  </TableContainer>
                  <Footer
                    color={color}
                    modalHeading={modalHeading}
                    getCount={getCount}
                    rowData={rowData}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    allNetworks={allNetworks}
                    setNewNetwork={setNewNetwork}
                    add={add}
                    deleteRow={deleteRow}
                  />
                </CardBody>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GenericTable;
