import React, { useEffect } from 'react'
import UserDashboardSide from 'components/UserlayoutComponents/sidebar';
import UserDasboardHead from 'components/UserlayoutComponents/header';
import logo from "assets/images/mutolab.png";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {userRoutes} from "routes.js";
import { useLocation } from "react-router-dom";
import $ from 'jquery'

const UserLayout = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    if (window.matchMedia('(max-width: 991px)').matches) {
      $('.dashboard-area').addClass('hide');
    }
  }, [])
  useEffect(() => {
    if (window.matchMedia('(max-width: 991px)').matches) {
      $('.dashboard-area').addClass("hide");
      $('.dashboard-area').css('left', '-75%');
      $('.dashboard-area').css('z-index', '0');
      $('.toggler-bars').css('z-index', '100');
      $('.toggler-bars').css('left', '0%');
    }
    if (window.matchMedia('(max-width: 574px)').matches) {
      $('.dashboard-area').addClass("hide");
      $('.dashboard-area').css('left', '-115%');
      $('.dashboard-area').css('z-index', '0');
      $('.toggler-bars').css('z-index', '100');
      $('.toggler-bars').css('left', '0%');

    }
  }, [location.pathname])


  const toggleSideBar = () => {
    if ($('.dashboard-area').hasClass('hide')) {
      if (window.matchMedia('(max-width: 991px)').matches) {
        $('.dashboard-area').css('position', 'absolute');
        $('.toggler-bars').css('left', '340px');
        $('.toggler-bars').css('z-index', '100');
        $('.dashboard-area').removeClass("hide");
        $('.dashboard-area').css('left', '0');
        $('.dashboard-area').css('opacity', '1');
        $('.dashboard-area').css('z-index', '1000');
      }
      if (window.matchMedia('(min-width: 992px)').matches) {
        $('.dashboard-area').css('left', '0');
        $('.dashboard-area').removeClass("hide");
        $('.dashboard-area').css('opacity', '1');
        $('.toggler-bars').css('z-index', '100');
        $('.dashboard-area').css('z-index', '1000');
        $('.toggler-bars').css('left', '0%');
      }
      if (window.matchMedia('(max-width: 574px)').matches) {
        $('.toggler-bars').css('left', '280px');
      }
    }
    else {
      if (window.matchMedia('(min-width: 992px)').matches) {
        $('.toggler-bars').css('left', '-300px');
        $('.dashboard-area').css('left', '-15.5%');
        $('.dashboard-area').css('opacity', '0');
        $('.toggler-bars').css('z-index', '100');
        $('.dashboard-area').css('z-index', '0');
        $('.dashboard-area').addClass("hide");
      }
      if (window.matchMedia('(max-width: 991px)').matches) {
        $('.dashboard-area').addClass("hide");
        $('.dashboard-area').css('left', '-75%');
        $('.dashboard-area').css('z-index', '0');
        $('.toggler-bars').css('z-index', '100');
        $('.toggler-bars').css('left', '0%');

      }
      if (window.matchMedia('(max-width: 574px)').matches) {
        $('.dashboard-area').addClass("hide");
        $('.dashboard-area').css('left', '-115%');
        $('.dashboard-area').css('z-index', '0');
        $('.toggler-bars').css('z-index', '100');
        $('.toggler-bars').css('left', '0%');

      }
    }
  }
    return (
      <div className="wrapper custom">
        <div className="created-nft-wrapper-area d-flex">
          <UserDashboardSide routes={userRoutes}
            logo={{
              outterLink: "/",
              text: "Mutolab",
              imgSrc: logo,
            }} />
          <div className="created-nft-right-area">
            <FontAwesomeIcon icon={faBars} onClick={toggleSideBar} className="toggler-bars" />
            <UserDasboardHead />
            {children}
          </div>

        </div>
      </div>
    )
  }

  export default UserLayout